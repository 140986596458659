import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Link,
  Navbar,
  PageHeader,
  DefaultPageWrapper,
  SiteMap,
  Button,
} from "GlobalComponents";
import UpArrowIcon from "@material-ui/icons/ExpandLessOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import {
  Bio,
  NameHeader,
  SubHeader,
  Divider,
  StyledPaper,
  StyledAvatar,
  SocialButtonWrapper,
} from "./elements";
import { teamData } from "./TeamData";
import { UseDarkMode } from "Hooks";

const SocialButton: React.FC = () => (
  <SocialButtonWrapper data-testid="social-button">
    <a
      data-testid="social-button-anchor"
      style={{ textDecoration: "none" }}
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.facebook.com/lyrickeeper"
    >
      <Button kind="light">
        Like us on FaceBook{" "}
        <FacebookIcon
          style={{
            fontSize: "1.7rem",
            color: "blue",
            marginBottom: "-2px",
            marginLeft: "5px",
          }}
        />
      </Button>
    </a>
  </SocialButtonWrapper>
);

export const AboutPage: React.FC = () => {
  const { darkModeIsEnabled } = UseDarkMode();
  const [showEntireDescription, setShowEntireDescription] = useState(false);

  return (
    <>
      <Navbar />
      <DefaultPageWrapper darkMode={darkModeIsEnabled} data-testid="about-page">
        <SocialButton />
        <PageHeader>About the Lyric Keeper Project</PageHeader>
        <Container maxWidth="md">
          <Bio darkMode={darkModeIsEnabled} data-testid="about-page-bio">
            Lyric Keeper started as a project I built during my time at Hack
            Reactor. Since 2017, I had begun adding large amounts of songs to my
            gigging repertoire. I used to store the lyrics for these songs in a
            binder and would have to lug the binder to each gig. This was
            ineffective and was growing to be both impractical and impossible (I
            even considered starting a second binder).{" "}
            <div
              onClick={() => setShowEntireDescription(true)}
              style={{
                color: "blue",
                cursor: "pointer",
                display: showEntireDescription ? "none" : "inline",
              }}
            >
              (Read more)
            </div>
            <div style={{ display: showEntireDescription ? "" : "none" }}>
              <br />
              However, in early 2020, I remembered a project I had started
              during my time at Hack Reactor. The Lyric Keeper code was
              partially there; it just needed some refreshing and a few
              upgrades. So, just before March 2020, I began rewriting the Lyric
              Keeper app from the ground up using the latest web development
              technologies. In May 2020, I decided to open Lyric Keeper up to
              the public.
              <br />
              <br />
              After a few months, I was approached by several fellow Hack
              Reactor graduates who asked if they could be brought in on the
              Lyric Keeper project. To date, we have 5 engineers and 1 UX writer
              working on this project (and we're hoping to expand)! All of us
              are volunteers and have day jobs as software engineers at various
              companies. I personally work for an a fantastic company called
              Yoga International (look them up if you've got some time!)
              <br />
              <br />
              All of our engineers are dedicated to helping provide you with a
              speedy and accessible experience. Please let us know if you have
              any issues using our app. (
              <Link to="/contact-us" data-testid="about-page-contact-us-link">
                Contact Us
              </Link>
              )
              <br />
              <div
                style={{ color: "blue", marginTop: "20px", cursor: "pointer" }}
                onClick={() => {
                  window.scrollTo({ top: 1 });
                  setShowEntireDescription(false);
                }}
              >
                (Collapse{" "}
                <UpArrowIcon
                  style={{ marginLeft: "-8px", marginBottom: "-6px" }}
                />
                )
              </div>
            </div>
          </Bio>
        </Container>
        <PageHeader>The Lyric Keeper Team</PageHeader>
        <Container maxWidth="md" data-testid="about-page-team-data-container">
          {teamData.map(({ name, position, photo: { src }, bio }) => (
            <StyledPaper darkMode={darkModeIsEnabled} key={name}>
              <Grid container style={{ margin: "auto" }}>
                <Grid item xs={12} md={6}>
                  <StyledAvatar variant="square" src={src} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NameHeader darkMode={darkModeIsEnabled}>{name}</NameHeader>
                  <SubHeader darkMode={darkModeIsEnabled}>{position}</SubHeader>
                  <Divider />
                  <Bio darkMode={darkModeIsEnabled}>{bio}</Bio>
                </Grid>
              </Grid>
            </StyledPaper>
          ))}
        </Container>
        <div style={{ paddingBottom: "40px" }} />
        <SiteMap />
      </DefaultPageWrapper>
    </>
  );
};
