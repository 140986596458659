import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ListIcon from "@material-ui/icons/ListAltOutlined";
import QueueMusicIcon from "@material-ui/icons/QueueMusicOutlined";
import { StyledMenu, StyledMenuItem } from "./elements";
import { truncate } from "utilities";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import { Link } from "GlobalComponents";
import { LighterPurple, SignOutRed } from "ColorVars";
import { UseCurrentUserReturnShape, UseResponsiveCheck } from "Hooks";

interface Props {
  currentUser: UseCurrentUserReturnShape["currentUser"];
  logout(): void;
}

export const UserMenu: React.FC<Props> = ({ logout, currentUser }) => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const userMenuOpen = Boolean(userMenuAnchorEl);

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  if (!currentUser) return null;

  const { isMobile } = UseResponsiveCheck();

  return (
    <>
      <IconButton
        onClick={handleUserMenu}
        color="inherit"
        size="medium"
        style={{ textAlign: "right", marginLeft: isMobile ? "auto" : "" }}
        data-testid="navbar-account-button"
      >
        <AccountCircle style={{ fontSize: "2.5rem" }} />
      </IconButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={userMenuOpen}
        onClose={handleUserMenuClose}
        style={{ padding: "10px" }}
      >
        <Typography>
          {truncate({ string: currentUser?.email, limit: 20 })}
        </Typography>
        <Link data-testid="navbar-my-lyrics-link" to="/my-lyrics">
          <StyledMenuItem
            style={{ color: LighterPurple, textAlign: "center" }}
            onClick={handleUserMenuClose}
          >
            <ListIcon style={{ marginRight: "10px" }} /> My Lyrics
          </StyledMenuItem>
        </Link>
        <Link data-testid="navbar-my-setlists-link" to="/my-setlists">
          <StyledMenuItem
            style={{ color: LighterPurple, textAlign: "center" }}
            onClick={handleUserMenuClose}
          >
            <QueueMusicIcon style={{ marginRight: "10px" }} />
            My Setlists
          </StyledMenuItem>
        </Link>
        <StyledMenuItem
          data-testid="navbar-sign-out-link"
          style={{ color: SignOutRed }}
          onClick={logout}
        >
          <LogoutIcon style={{ marginRight: "10px" }} />
          Sign out
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};
