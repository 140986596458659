import { Get_Current_User_getCurrentUser, Lyric } from "Types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TsTempType = any; // Use to replace "any" and show that this type should eventually be replaced by a real type

export const truncate = ({
  string,
  limit,
}: {
  string: string;
  limit: number;
}): string => `${string.slice(0, limit)}${string.length > limit ? "..." : ""}`;

export const checkArrayEquality = (
  arr1: TsTempType[],
  arr2: TsTempType[]
): boolean => {
  let isEqual = true;
  if (arr1.length === arr2.length) {
    arr1.forEach((item, index) => {
      if (item !== arr2[index]) isEqual = false;
    });
  } else {
    isEqual = false;
  }
  return isEqual;
};

export const findNonPrivateLyrics = <I>(lyrics: I[]): I[] | undefined => {
  if (lyrics)
    return lyrics.filter(
      (item: TsTempType) =>
        item?.isPrivate === null || item?.isPrivate === false
    );
  return undefined;
};

interface OrderLyricsArgShape<I> {
  fullLyricData: I[];
  lyricIdList: { lyricId: string }[];
}

// Using generics to allow for possible type expansion in the future
export const orderLyricsBasedOnIdList = <I extends Lyric>({
  fullLyricData,
  lyricIdList,
}: OrderLyricsArgShape<I>): I[] => {
  const index: { [key: string]: I } = {};
  const returnArr: I[] = [];

  fullLyricData.forEach(({ ...lyricData }) => {
    index[lyricData.id] = { ...lyricData };
  });

  lyricIdList.forEach(({ lyricId }) => {
    if (index[lyricId]) returnArr.push({ ...index[lyricId] });
  });

  return returnArr;
};

interface UserOwnsLyricArgsList {
  lyricId: string;
  currentUserLyricsList: Get_Current_User_getCurrentUser["lyrics"];
}

type UserOwnsLyricFuncShape = ({
  lyricId,
  currentUserLyricsList,
}: UserOwnsLyricArgsList) => boolean;

export const userOwnsLyric: UserOwnsLyricFuncShape = ({
  lyricId,
  currentUserLyricsList,
}) => {
  let doesUserOwnLyric = false;

  currentUserLyricsList.forEach(userLyric => {
    if (userLyric?.lyricId === lyricId) doesUserOwnLyric = true;
  });

  return doesUserOwnLyric;
};
