import React from "react";
import {
  StyledLoginAnchorTag,
  StyledAnchorWrapper,
  LoginText,
} from "LoginComponents";
import { modalKinds } from "GlobalComponents/LoginCreateAccountModal";

interface Props {
  setModalType: React.Dispatch<modalKinds>;
}

export const SmallLoginModal: React.FC<Props> = ({ setModalType }) => {
  return (
    <>
      <LoginText>
        Have an account?
        <StyledAnchorWrapper style={{ paddingLeft: "10px" }}>
          <StyledLoginAnchorTag
            data-testid="login-modal-switch-login-button"
            style={{ fontWeight: 500 }}
            onClick={() => {
              setModalType("login");
            }}
          >
            Log in
          </StyledLoginAnchorTag>
        </StyledAnchorWrapper>
      </LoginText>
    </>
  );
};
