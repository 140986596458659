import styled from "styled-components";
import {
  PrimaryBlue,
  MainGreen,
  SecondaryLightGrey,
  DarkModeSecondaryLightGrey,
} from "ColorVars";
import { Link } from "react-router-dom";

export const CardWrapper = styled(Link)<{
  darkMode: boolean;
  canDelete?: boolean;
}>`
  min-height: 40px;
  margin: auto;
  padding: ${({ canDelete }) => (canDelete ? "28px" : "8px")};
  margin-top: 15px;
  border-radius: 6px;
  div {
    text-decoration: none !important;
  }
  background-color: ${({ darkMode }) =>
    darkMode ? DarkModeSecondaryLightGrey : SecondaryLightGrey};
  display: flex;
  align-items: flex-end;
  text-decoration: none;
`;

export const CardTextWrapper = styled.div<{ canDelete?: boolean | undefined }>`
  text-decoration: none;
  padding-top: 8px;
  ${({ canDelete = false }) => `
    text-align: ${canDelete ? "left" : "center"};
    ${!canDelete ? "margin: auto;" : ""}
  `};
`;

export const CardTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${MainGreen};
  /* display: block; */
  margin-bottom: 10px;
`;

export const CardAuthor = styled.div`
  font-size: 1.6rem;
  vertical-align: super;
  font-weight: 700;
  color: ${PrimaryBlue};
  /* display: inline; */
`;

export const DeleteButtonWrapper = styled.div<{ canDelete: boolean }>`
  display: ${({ canDelete }) => (canDelete ? "inline" : "none")};
  position: relative;
  top: -24px;
  left: -2%;
  margin-left: auto;
`;
