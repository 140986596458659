import { ReactourStep } from "reactour";

export const HomePageTourConfig: ReactourStep[] = [
  {
    selector: '[data-tut="first-step"]',
    content:
      "Welcome to Lyric Keeper! We've made a short tour to get you started.",
  },
  {
    selector: '[data-tut="New Lyric"]',
    content: "Create a New Lyric here! These can be public or private.",
  },
  {
    selector: '[data-tut="Library"]',
    content:
      "Here you can view lyrics made by others. Check it out if you need inspiration!",
  },
  {
    selector: '[data-tut="My Lyrics"]',
    content:
      "The name says it all. ‘My Lyrics’ has all of your created lyrics!",
  },
  {
    selector: '[data-tut="My Setlists"]',
    content:
      "‘My Setlists' is a customizable setlist of lyrics that you can make!",
  },
  {
    selector: '[data-tut="Help Page"]',
    content:
      'The Help Page has more information to help you out. Let’s head there now. Click the "Help Page" button to continue ',
  },
];
