import Typography from "@material-ui/core/Typography";
import { DarkModeLighterPurple, MainGreen, SecondaryColor } from "ColorVars";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const StyledCheckoutForm = styled.form`
  width: 30vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  margin-top: 40px;
`;

export const StyledSubmitButton = styled.button`
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 10px;
  &&:hover {
    filter: contrast(115%);
  }
  &&:disabled {
    cursor: default;
    background: #b3b3f6;
  }
`;

export const ErrorMessage = styled.div`
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
`;

export const ResultMessage = styled.p`
  line-height: 22px;
  font-size: 16px;
`;

export const CardElementWrapper = styled.div`
  && {
    &&.Input {
      border-radius: 6px;
      margin-bottom: 6px;
      padding: 12px;
      border: 1px solid rgba(50, 50, 93, 0.1);
      max-height: 44px;
      font-size: 16px;
      width: 100%;
      background: white;
      box-sizing: border-box;
    }
  }
`;

export const DescriptionText = styled(Typography)<{
  darkModeIsEnabled: boolean;
}>`
  && {
    margin: auto;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 300px;
    color: ${({ darkModeIsEnabled }) =>
      darkModeIsEnabled ? DarkModeLighterPurple : SecondaryColor};
  }
`;

export const TotalDonationCount = styled(DescriptionText)`
  && {
    font-size: 2rem;
    color: ${MainGreen};
    max-width: 350px;
  }
`;

export const StyledDonationButton = styled(Button)<{
  darkModeIsEnabled: boolean;
}>`
  && {
    padding: 3px;
    margin: 6px;
    font-size: 1.2rem;
    ${({ darkModeIsEnabled }) =>
      darkModeIsEnabled &&
      `
    border-color: white;
    color: white;
    `}
  }
`;

export const ChangeDonationButton = styled(Button)`
  && {
    font-size: 0.8rem;
  }
`;
