import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { DarkModeGreen, MainGreen } from "ColorVars";

interface Props {
  darkModeIsEnabled?: boolean;
  useRobotoFont?: boolean;
}

export const PageHeader = styled(Typography)<Props>`
  && {
    color: ${({ darkModeIsEnabled }) =>
      darkModeIsEnabled ? DarkModeGreen : MainGreen};
    font-family: ${({ useRobotoFont }) =>
      useRobotoFont ? '"Roboto"' : '"Lora"'};
    text-align: center;
    font-size: 2.3rem;
    padding-top: 30px;
    letter-spacing: 3px;
  }
`;
