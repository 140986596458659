import React from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import {
  StyledContainer,
  MarketingWrapper,
  MainMarketingHeader,
  StyledFab,
  SecondaryMarketingText,
  MarketingText,
} from "./elements";
import { Button } from "GlobalComponents";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  handleLoginButtonClick(): void;
}

export const MarketingModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  handleLoginButtonClick,
}) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)}>
    <StyledContainer maxWidth="xs" data-testid="marketing-modal">
      <MarketingWrapper>
        <StyledFab
          data-testid="marketing-modal-close-button"
          size="small"
          onClick={() => {
            setIsOpen(false);
            window.localStorage.setItem("showMarketing", "false");
          }}
        >
          <CloseIcon />
        </StyledFab>
        <MainMarketingHeader data-testid="marketing-modal-header" variant="h4">
          Members Only Features:
        </MainMarketingHeader>
        <SecondaryMarketingText>
          - Create new lyrics
          <br />- Edit lyrics
          <br />- Create setlists
        </SecondaryMarketingText>
        <MarketingText>
          You are not currently signed in! To access the above features, please
          login or create an account.
        </MarketingText>
        <Button
          style={{ fontSize: "14px", margin: "16px auto" }}
          kind="secondary"
          size="small"
          onClick={handleLoginButtonClick}
        >
          Login or Create Account
        </Button>
      </MarketingWrapper>
    </StyledContainer>
  </Modal>
);
