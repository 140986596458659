import React from "react";
import Container from "@material-ui/core/Container";
import {
  DefaultPageWrapper,
  ContactForm,
  Navbar,
  SiteMap,
} from "GlobalComponents";
import { UseDarkMode } from "Hooks";

export const ContactPage: React.FC = () => {
  const { darkModeIsEnabled } = UseDarkMode();

  return (
    <>
      <Navbar />
      <DefaultPageWrapper
        style={{ paddingBottom: "16px" }}
        darkMode={darkModeIsEnabled}
      >
        <Container maxWidth="md">
          <ContactForm />
        </Container>
        <SiteMap />
      </DefaultPageWrapper>
    </>
  );
};
