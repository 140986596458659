import { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const UseResponsiveCheck = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const mobileSize = !useMediaQuery("(min-width:500px)");
  const tabletSize = !useMediaQuery("(min-width:800px)");
  const desktopSize = !useMediaQuery("(min-width:1024px)");

  useEffect(() => {
    setIsMobile(mobileSize);
    setIsTablet(tabletSize);
    setIsDesktop(desktopSize);
  }, [mobileSize, tabletSize, desktopSize]);

  return { isMobile, isTablet, isDesktop };
};
