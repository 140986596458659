import React from "react";
import {
  AdaptiveButton,
  AdaptiveLinkButton,
  StyledAnchorTag,
  SiteMapWrapper,
} from "GlobalComponents/elements";
import { UseResponsiveCheck } from "Hooks";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Link } from "GlobalComponents";

interface Props {
  lockToBottom?: boolean;
}

export const SiteMap: React.FC<Props> = ({ lockToBottom }) => {
  const { isMobile } = UseResponsiveCheck();

  const darkModeState = true;

  return (
    <>
      {!isMobile && (
        <SiteMapWrapper lockToBottom={lockToBottom} style={{ minHeight: 100 }}>
          <Link data-testid="sitemap-home-link" to="/">
            <AdaptiveButton darkMode={darkModeState}>Home</AdaptiveButton>
          </Link>
          <Link data-testid="sitemap-library-link" to="/library">
            <AdaptiveButton darkMode={darkModeState}>Library</AdaptiveButton>
          </Link>
          <Link data-testid="sitemap-about-link" to="/about">
            <AdaptiveButton darkMode={darkModeState}>About</AdaptiveButton>
          </Link>
          <Link data-testid="sitemap-my-lyrics-link" to="/my-lyrics">
            <AdaptiveButton darkMode={darkModeState}>My Lyrics</AdaptiveButton>
          </Link>
          <Link data-testid="sitemap-contact-link" to="/contact-us">
            <AdaptiveButton darkMode={darkModeState}>Contact Us</AdaptiveButton>
          </Link>
          <Link data-testid="sitemap-donate-link" to="/donate">
            <AdaptiveButton darkMode={darkModeState}>Donate</AdaptiveButton>
          </Link>
          <Link data-testid="sitemap-privacy-link" to="/privacy">
            <AdaptiveButton darkMode={darkModeState}>
              Privacy Policy
            </AdaptiveButton>
          </Link>
          <AdaptiveLinkButton darkMode={darkModeState}>
            <StyledAnchorTag
              data-testid="sitemap-facebook-link"
              href="https://www.facebook.com/lyrickeeper"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FacebookIcon />
            </StyledAnchorTag>
          </AdaptiveLinkButton>
          <AdaptiveLinkButton darkMode={darkModeState}>
            <a
              href="https://www.producthunt.com/posts/lyric-keeper?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lyric-keeper"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=278791&theme=dark"
                alt="Lyric Keeper - Store your lyrics and chords in an extremely simple way! | Product Hunt"
                style={{ width: "120px", height: "30px" }}
                width="250"
                height="54"
              />
            </a>
          </AdaptiveLinkButton>
        </SiteMapWrapper>
      )}
    </>
  );
};
