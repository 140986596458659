import { ReactourStep } from "reactour";
import React from "react";

export const GenerateHelpPageTourConfig: ({
  setRunTourMutation,
}: {
  setRunTourMutation: React.Dispatch<boolean>;
}) => ReactourStep[] = ({ setRunTourMutation }) => [
  {
    selector: '[data-tut="FAQ-section"]',
    content: "Here you can find short tutorials on some of our features.",
  },
  {
    selector: '[data-tut="contact-step"]',
    content: "Have a question or suggestion? Let us know!",
  },
  {
    selector: '[data-tut="home-button"]',
    content:
      "Thanks for checking out Lyric Keeper! Click here to return home and start creating!",
    action: () => setRunTourMutation(true),
  },
];
