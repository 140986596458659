import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  BottomHomepageWave,
  ClumsyLight,
  MainLogo,
  SittingReadingLight,
  TopHomepageWave,
  UnboxingLight,
} from "Icons";
import {
  LoggedOutHeader,
  LoggedOutDescriptionText,
  LoggedOutSectionHeader,
  LoggedOutTopLogoWrapper,
  TextWrapperRight,
  TextWrapperLeft,
  IconWrapperLeft,
  IconWrapperRight,
} from "./elements";
import { Container } from "@material-ui/core";
import {
  Button,
  DefaultPageWrapper,
  Link,
  modalKinds,
  SiteMap,
} from "GlobalComponents";
import { UseResponsiveCheck, UseWindowDimensions } from "Hooks";
import { getWaveViewBoxSettings } from "./homepageUtilities";

interface Props {
  setLoginModalIsOpen: React.Dispatch<boolean>;
  setModalKind: React.Dispatch<modalKinds>;
}

export const LoggedOutView: React.FC<Props> = ({
  setLoginModalIsOpen,
  setModalKind,
}) => {
  const { isMobile } = UseResponsiveCheck();
  const { width } = UseWindowDimensions();

  useEffect(() => {
    setModalKind("create-account");
  }, [setModalKind]);

  const handleSignUpButton = () => {
    setLoginModalIsOpen(true);
  };

  // WaveYCut WaveXCut WaveHeightCut

  const {
    topWaveYCut,
    topWaveXCut,
    topWaveHeightCut,
    bottomWaveXCut,
    bottomWaveYCut,
    bottomWaveWidth,
    bottomWaveHeight,
    isMdScreen,
    bottomWaveLowerMeasurement,
  } = getWaveViewBoxSettings(width);

  return (
    <DefaultPageWrapper
      style={{ paddingBottom: isMdScreen ? "9vh" : isMobile ? "0px" : "15vh" }}
      topPaddingFalse={true}
      darkMode={false}
    >
      <div style={{ height: "0", width: "100vw", position: "absolute" }}>
        <TopHomepageWave
          viewBox={`${topWaveYCut} ${topWaveXCut} ${topWaveHeightCut} 150`}
        />
      </div>
      <Container maxWidth="md">
        <Grid
          data-testid="top-wave-section"
          style={{ marginBottom: 90 }}
          container
        >
          <Grid item xs={12} sm={8} style={{ zIndex: 1 }}>
            <LoggedOutHeader>
              A virtual notebook for all your lyrics
            </LoggedOutHeader>
            <LoggedOutDescriptionText disablePadding={isMobile}>
              Manage your lyrics with our simple lyric builder tool and stay
              organized by sorting them into setlists
            </LoggedOutDescriptionText>
            <Button
              data-testid="top-wave-button-1"
              kind="primary"
              onClick={handleSignUpButton}
            >
              Sign Up
            </Button>
            <Link to="/about">
              <Button data-testid="top-wave-button-2" kind="secondary">
                Learn More
              </Button>
            </Link>
          </Grid>
          <Grid item xs={false} sm={4}>
            {!isMobile && (
              <LoggedOutTopLogoWrapper>
                <MainLogo
                  data-testid="top-wave-logo"
                  height="auto"
                  width="auto"
                />
              </LoggedOutTopLogoWrapper>
            )}
          </Grid>
        </Grid>
        <Grid
          data-testid="middle-marketing-section-1"
          style={{ marginBottom: isMobile ? 50 : 90 }}
          container
        >
          <Grid item xs={12} sm={6}>
            {/* Section #1 */}
            <IconWrapperRight isMobile={isMobile}>
              <SittingReadingLight height="auto" width="auto" />
            </IconWrapperRight>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextWrapperLeft isMobile={isMobile}>
              <LoggedOutSectionHeader>
                Got a gig? We've got your back.
              </LoggedOutSectionHeader>
              <LoggedOutDescriptionText disablePadding>
                Use our auto-scrolling tool to set your own playback speed and
                never worry about turning a page again.
              </LoggedOutDescriptionText>
            </TextWrapperLeft>
          </Grid>
        </Grid>
        <Grid
          data-testid="middle-marketing-section-2"
          style={{ marginBottom: isMobile ? 50 : 90 }}
          container
        >
          <Grid item xs={12} sm={6}>
            {/* Section #2 */}
            {isMobile && (
              <IconWrapperLeft isMobile={isMobile}>
                <UnboxingLight height="auto" width="auto" />
              </IconWrapperLeft>
            )}
            <TextWrapperRight isMobile={isMobile}>
              <LoggedOutSectionHeader>Get creative</LoggedOutSectionHeader>
              <LoggedOutDescriptionText disablePadding>
                With our easy to use lyric creation tool, you can focus on your
                ideas, without worrying about how they get on the page.
              </LoggedOutDescriptionText>
            </TextWrapperRight>
          </Grid>
          <Grid item xs={12} sm={6}>
            {!isMobile && (
              <IconWrapperLeft isMobile={isMobile}>
                <UnboxingLight height="auto" width="auto" />
              </IconWrapperLeft>
            )}
          </Grid>
        </Grid>
        <Grid data-testid="middle-marketing-section-3" container>
          <Grid item xs={12} sm={6}>
            {/* Section #3 */}
            <IconWrapperRight isMobile={isMobile}>
              <ClumsyLight height="auto" width="auto" />
            </IconWrapperRight>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextWrapperLeft isMobile={isMobile}>
              <LoggedOutSectionHeader>
                No more bulky binders
              </LoggedOutSectionHeader>
              <LoggedOutDescriptionText disablePadding>
                Lyric Keeper has all your setlists, without all the paper.
              </LoggedOutDescriptionText>
            </TextWrapperLeft>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          position: "relative",
          bottom: `${bottomWaveLowerMeasurement}px`,
        }}
      >
        <Button
          data-testid="bottom-signup-button"
          size="large"
          kind="primary"
          style={{
            bottom: isMobile ? "28px" : isMdScreen ? "9vw" : "5vw",
            position: "absolute",
            right: "50vw",
            left: isMobile ? "22vw" : "40vw",
          }}
          onClick={handleSignUpButton}
        >
          Sign Up
        </Button>
        <BottomHomepageWave
          data-testid="bottom-wave-section"
          style={{
            height: isMobile ? "50vw" : "auto",
            width: isMobile ? "100vw" : "auto",
          }}
          viewBox={`${bottomWaveYCut} ${bottomWaveXCut} ${bottomWaveWidth} ${bottomWaveHeight}`}
        />
      </div>
      <SiteMap />
    </DefaultPageWrapper>
  );
};
