import { useState, useEffect } from "react";
import { Query_Get_All_List_Lyrics } from "operations";
import { useQuery } from "react-apollo";
import { useFormik } from "formik";
import { findNonPrivateLyrics, TsTempType } from "utilities";

interface ReturnShape<DataReturnType> {
  lyricData: DataReturnType[];
  searchBarField: string;
  filterBy: "title" | "author";
  handleChange(arg1: TsTempType): TsTempType;
  loading: boolean;
  filter(searchTerm: string): void;
  refetch(): void;
}

interface Props {
  returnNonPrivateLyrics?: boolean;
  query: typeof Query_Get_All_List_Lyrics;
  client: TsTempType; // Apollo client
  queryVariables?: { [key: string]: TsTempType };
  fieldName: string; // Name of the field on the GQL query containing the data
}

export function SearchEngine<QueryType, DataReturnType>({
  returnNonPrivateLyrics = false,
  query,
  client,
  fieldName,
  queryVariables,
}: Props): ReturnShape<DataReturnType> {
  const [lyricDataSourceOfTruth, setLyricDataSourceOfTruth] = useState<
    DataReturnType[]
  >([]);
  const [lyricData, setLyricData] = useState<DataReturnType[]>([]);

  const {
    handleChange,
    values: { search, filterBy },
  } = useFormik({
    initialValues: { search: "", filterBy: "title" as "title" | "author" },
    onSubmit: () => undefined,
  });

  const { data, loading, refetch } = useQuery(query, {
    variables: queryVariables,
  });

  const filter = (searchTerm: string) => {
    setLyricData(
      lyricDataSourceOfTruth &&
        lyricDataSourceOfTruth.filter(item => {
          const currentItem = item as TsTempType;
          return currentItem[filterBy]
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        })
    );
  };

  const getAndUpdateAllLyrics = () => {
    // Handled offline data
    if (!data || loading) {
      try {
        const cachedData = client.readQuery({
          query,
          variables: queryVariables,
        });

        const formattedOfflineLyricData = returnNonPrivateLyrics
          ? findNonPrivateLyrics<QueryType>(cachedData[fieldName])
          : cachedData;

        setLyricData(formattedOfflineLyricData);
        setLyricDataSourceOfTruth(formattedOfflineLyricData);
      } catch (error) {
        console.warn(error);
      }
    } else if (data && !loading) {
      const formattedLyricData = returnNonPrivateLyrics
        ? findNonPrivateLyrics<QueryType>(data[fieldName])
        : data[fieldName];

      setLyricDataSourceOfTruth(formattedLyricData);
      setLyricData(formattedLyricData);
    }
  };

  useEffect(() => {
    getAndUpdateAllLyrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return {
    lyricData,
    searchBarField: search,
    handleChange,
    filterBy,
    loading,
    filter,
    refetch,
  };
}
