import Typography from "@material-ui/core/Typography";
import Divider_ from "@material-ui/core/Divider";
import { MainGreen, BrightGreen, GreyGlass } from "ColorVars";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

export const StyledPaper = styled(Paper)<{ darkMode: boolean }>`
  min-height: 250px;
  margin-top: 20px;
  padding: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${({ darkMode }) => darkMode && GreyGlass};
`;

export const StyledAvatar = styled(Avatar)`
  height: 100%;
  width: 63%;
  border-radius: 14px;
  text-align: center;
  margin: auto;
`;

export const NameHeader = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 2rem;
    text-align: center;
    color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
  }
`;

export const SubHeader = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 1.1rem;
    text-align: center;
    color: ${({ darkMode }) => (darkMode ? BrightGreen : MainGreen)};
  }
`;

export const Bio = styled(Typography)<{ darkMode?: boolean }>`
  && {
    color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    font-size: 1.2rem;
    margin-top: 10px;
    line-height: 26px;
    letter-spacing: 2px;
    text-align: center;
    font-family: sans-serif;
    font-weight: 300;
    white-space: pre-line;
  }
`;

export const Divider = styled(Divider_)`
  && {
    width: 54%;
    margin: auto;
    margin-top: 10px;
  }
`;

export const SocialButtonWrapper = styled.div`
  && {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
  }
`;
