import React from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Close from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import { ContactForm } from "./ContactForm";
import { UseDarkMode } from "Hooks";
import { FogGrey } from "ColorVars";

export const ModalContentWrapper = styled.div<{
  paddingLeft: boolean;
  isDarkMode: boolean;
}>`
  background-color: ${({ isDarkMode }) => (isDarkMode ? FogGrey : "white")};
  text-align: center;
  min-width: 65%;
  height: 80vh;
  position: absolute;
  margin: auto;
  border-radius: 6px;
  top: 10%;
  ${({ paddingLeft }) => paddingLeft && "left: 19%;"}
  overflow: scroll;
`;

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<boolean>;
}

export const ContactModal: React.FC<Props> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const paddingLeft = useMediaQuery("(min-width:600px)");
  const { darkModeIsEnabled } = UseDarkMode();

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      data-testid="contact-us-modal"
    >
      <ModalContentWrapper
        paddingLeft={paddingLeft}
        isDarkMode={darkModeIsEnabled}
      >
        <Container maxWidth="md">
          <>
            <ContactForm />
            <Fab
              data-testid="contact-us-modal-close-button"
              onClick={() => setModalIsOpen(false)}
              size="small"
              style={{ marginTop: "40px", marginBottom: "20px" }}
            >
              <Close />
            </Fab>
          </>
        </Container>
      </ModalContentWrapper>
    </Modal>
  );
};
