import styled from "styled-components";
import {
  MainGreen,
  SecondaryColor,
  DarkModePageBackground,
  DarkModeSecondaryLighterPurple,
  SuperLightGrey,
  FogGrey,
  DarkModeGreen,
} from "ColorVars";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import Snackbar from "@material-ui/core/Snackbar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Select from "@material-ui/core/Select";

export const PageWrapper = styled.div<{ darkMode: boolean }>`
  text-align: center;
  margin: auto;
  white-space: pre;
  padding-bottom: 60px;
  background-color: ${({ darkMode }) =>
    darkMode ? DarkModePageBackground : "white"};
  padding-top: 40px;
  min-height: 100vh;
`;

export const SongTitle = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 3vh;
    font-weight: 400;
    color: ${({ darkMode }) => (darkMode ? "white" : "black")};
    font-family: "";
    white-space: pre-wrap;
  }
`;

export const SongAuthor = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 2.5vh;
    font-weight: 400;
    color: ${({ darkMode }) => (darkMode ? DarkModeGreen : MainGreen)};
    font-family: "";
    white-space: pre-wrap;
  }
`;

export const SetlistMetaWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin: auto;
  margin-top: 35px;
  width: fit-content;
`;

export const SetlistTitle = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 2vh;
    font-weight: 400;
    color: ${({ darkMode }) =>
      darkMode ? DarkModeSecondaryLighterPurple : SecondaryColor};
    white-space: pre-wrap;
  }
`;

export const SetlistSubTitle = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 1.75vh;
    font-weight: 400;
    color: ${({ darkMode }) =>
      darkMode ? DarkModeSecondaryLighterPurple : SecondaryColor};
    white-space: pre-wrap;
    margin-top: 6px;
  }
`;

export const HeaderWrapper = styled.div<{ darkMode: boolean }>`
  padding-top: 65px;
  padding-bottom: 60px;
  border-bottom: 1px solid ${SuperLightGrey};
`;

export const SongChorus = styled.div<{ darkMode: boolean; isMobile: boolean }>`
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? "18em" : "fit-content")};
  margin: 20px ${({ isMobile }) => isMobile && "auto"};
  font-size: 2.2vh;
  letter-spacing: 0.1vw;
  color: ${({ darkMode }) =>
    darkMode ? DarkModeSecondaryLighterPurple : SecondaryColor};
  white-space: pre-wrap;
`;

export const SongVerse = styled.div<{ darkMode: boolean; isMobile: boolean }>`
  text-align: left;
  width: fit-content;
  margin: auto;
  margin-top: 5px;
  font-size: 2.1vh;
  letter-spacing: 0.1vw;
  white-space: pre-wrap;
  color: ${({ darkMode }) => (darkMode ? "white" : "black")};
  ${({ isMobile }) => isMobile && "margin-left: 25px;"}
  ${({ isMobile }) => isMobile && "margin-right: 6px;"}
`;

export const StyledErrorMessage = styled.h1`
  color: red;
  text-align: center;
  margin-top: 20%;
`;

export const Bar = styled.div<{ isMobile: boolean }>`
  background-color: ${MainGreen};
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 65px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  ${({ isMobile }) => isMobile && `padding-bottom: 20px;`}
`;

export const StyledFab = styled(Fab)`
  && {
    margin-top: 9px;
    height: 38px;
    width: 38px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const StyledSpeedControlPopover = styled(Popover)`
  && {
    .MuiPaper-root {
      padding: 16px;
      width: 290px;
      height: 30px;
      padding-top: 10px;
    }
  }
`;

export const SpeedControlFab = styled(Fab)`
  && {
    height: 2px;
    width: 36px;
    color: white;
    background-color: ${MainGreen};
  }
`;

export const SpeedControlText = styled(Typography)`
  && {
    line-height: 35px;
    margin-right: 10px;
    font-size: 1.2rem;
    margin-left: 10px;
  }
`;

export const SetlistPopover = styled(Popover)`
  && {
    .MuiPaper-root {
      padding: 10px;
      width: 200px;
      height: 230px;
      padding-top: 10px;
    }
  }
`;

export const ModalContentWrapper = styled.div<{
  isMobile: boolean;
  darkMode: boolean;
}>`
  background-color: ${({ darkMode }) =>
    darkMode ? DarkModePageBackground : "white"};
  text-align: center;
  min-width: 65%;
  height: 80vh;
  position: absolute;
  margin: auto;
  border-radius: 6px;
  top: 10%;
  overflow: scroll;
  ${({ isMobile }) => !isMobile && "margin-left: 18%"}
`;

export const StyledSnackbar = styled(Snackbar)`
  && {
    .MuiSnackbarContent-root {
      background-color: ${FogGrey};
      min-width: 10px;
      max-height: 50px;
      border-radius: 25px;
    }
    .MuiSnackbarContent-message {
      color: white;
      font-size: 1rem;
    }
  }
`;

export const StyledMoreMenuPopover = styled(Popover)`
  && {
    .MuiPaper-root {
      padding: 10px;
      width: 265px;
      height: fit-content;
      padding-top: 10px;
    }
  }
`;

export const MoreMenuItem = styled(ButtonBase)<{ border?: boolean }>`
  && {
    height: 33px;
    ${({ border }) => border && "border-top: 1px solid black;"}
    width: 100%;
    font-family: Roboto;
    font-size: 1.1rem;
    text-align: left;
    padding-top: ${({ border }) => (border ? "20px" : "10px")};
    margin-bottom: 10px;
  }
`;

export const MenuTextWrapper = styled.div`
  text-align: left;
  margin-right: auto;
  display: flex;
`;

export const StyledSelect = styled(Select)`
  && {
    bottom: 6px;
    right: -26px;
    background-color: ${SuperLightGrey};
    border-radius: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;
