import { Link } from "GlobalComponents";
import React from "react";
import { DesktopNavButton, DesktopNavItemsWrapper } from "./elements";

export const DesktopNavbarItems: React.FC = () => (
  <DesktopNavItemsWrapper>
    <Link style={{ marginRight: "10px" }} data-testid="navbar-home-link" to="/">
      <DesktopNavButton>Home</DesktopNavButton>
    </Link>
    <Link
      style={{ marginRight: "10px" }}
      data-testid="navbar-library-link"
      to="/library"
    >
      <DesktopNavButton>Library</DesktopNavButton>
    </Link>
    <Link
      style={{ marginRight: "10px" }}
      data-testid="navbar-about-link"
      to="/about"
    >
      <DesktopNavButton>About</DesktopNavButton>
    </Link>
    <Link
      style={{ marginRight: "10px" }}
      data-testid="navbar-help-link"
      to="/help"
    >
      <DesktopNavButton>Help</DesktopNavButton>
    </Link>
    <Link
      style={{ marginRight: "10px" }}
      data-testid="navbar-contact-link"
      to="/contact-us"
    >
      <DesktopNavButton>Contact us</DesktopNavButton>
    </Link>
  </DesktopNavItemsWrapper>
);
