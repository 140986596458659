import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UseDarkMode, UseResponsiveCheck } from "Hooks";
import { StyledSearchField, StyledSelect } from "GlobalComponents";
import MenuItem from "@material-ui/core/MenuItem";
import { TsTempType } from "utilities";
import Grid from "@material-ui/core/Grid";

interface AutocompleteProps {
  lyricInputData: Array<TsTempType>;
  filterBy: string;
  filter: TsTempType;
  handleChange: TsTempType;
}

export const AutocompleteSearch: React.FC<AutocompleteProps> = ({
  lyricInputData,
  filterBy,
  filter,
  handleChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const { darkModeIsEnabled } = UseDarkMode();
  const { isMobile } = UseResponsiveCheck();

  // Alphabetizes list options in searchbar autocomplete
  const options = lyricInputData.map((option: TsTempType) => {
    if (option.author) {
      const firstLetter = option.author[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    }
    return undefined;
  });

  const uniqueArtist: Array<string> = [];

  lyricInputData.forEach((option: TsTempType) => {
    if (!uniqueArtist.includes(option.author)) {
      uniqueArtist.push(option.author);
    }
    return uniqueArtist;
  });

  return (
    <Autocomplete
      data-testid="autocomplete-search"
      options={
        filterBy === "title"
          ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
          : uniqueArtist.sort()
      }
      getOptionLabel={
        filterBy === "title" ? option => option.title : option => option
      }
      inputValue={inputValue}
      groupBy={
        filterBy === "title" ? option => option.author : option => option[0]
      }
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
        filter(newInputValue);
      }}
      noOptionsText="No lyrics to display..."
      renderInput={params => (
        <Grid container>
          <Grid item xs={12} md={10}>
            <StyledSearchField
              darkMode={darkModeIsEnabled}
              {...params}
              label="Search"
              name="search"
              value={params}
              variant="outlined"
              style={{ borderColor: darkModeIsEnabled ? "white" : "black" }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StyledSelect
              isMobile={isMobile}
              darkMode={darkModeIsEnabled}
              value={filterBy}
              name="filterBy"
              onChange={handleChange}
              variant="outlined"
              style={{ color: darkModeIsEnabled ? "white" : "black" }}
              data-testid="search-filter-selector"
            >
              <MenuItem value="title">Title</MenuItem>
              <MenuItem value="author">Artist</MenuItem>
            </StyledSelect>
          </Grid>
        </Grid>
      )}
    />
  );
};
