import Modal from "@material-ui/core/Modal";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { TsTempType } from "utilities";
import { EmailAndPassword } from "GlobalComponents/LoginCreateAccountModal";
import {
  LoginText,
  StyledLoginField,
  LoginError,
  StyledLoginCircularProgress,
  StyledLoginContainer,
  LoginButton,
} from "LoginComponents";

interface Props {
  email: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  authIsLoading: boolean;
  signInUser: ({ email, password, setError }: EmailAndPassword) => void;
  currentUserIsLoading: boolean;
  setAuthLoginPassword: React.Dispatch<string>;
  signInWithProviderPt2: (setError: React.Dispatch<TsTempType>) => void;
}

export const LinkedAuthModal: React.FC<Props> = ({
  email,
  isOpen,
  setIsOpen,
  currentUserIsLoading,
  authIsLoading,
  setAuthLoginPassword,
  signInWithProviderPt2,
}) => {
  const [authError, setAuthError] = useState("");

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("You must enter a password")
      .min(8, "Password must be at least 8 characters long"),
  });

  const {
    handleChange,
    values: { password },
    errors,
    validateForm,
    handleBlur,
    touched,
    setTouched,
  } = useFormik({
    initialValues: { password: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const passwordError: TsTempType =
    errors.password && touched.password && errors.password;

  const handledAuthError =
    authError === "auth/wrong-password"
      ? "Incorrect Password"
      : authError === "auth/too-many-requests"
      ? "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
      : authError === "auth/account-exists-with-different-credential"
      ? "Account with email already exists. Please link accounts"
      : authError;

  const errorText: TsTempType = handledAuthError || passwordError;

  const buttonText: TsTempType =
    !currentUserIsLoading && !authIsLoading ? (
      "Login"
    ) : (
      <StyledLoginCircularProgress size={25} />
    );

  const handleSubmit = () => {
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.password) {
        setAuthLoginPassword(password);
        signInWithProviderPt2(setAuthError);
      } else {
        setTouched({ password: true });
      }
    })();
  };

  const emailText = `To link a new login method, please enter the password for the account: ${email}`;

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <StyledLoginContainer maxWidth="xs">
        <LoginText>{emailText}</LoginText>
        <form
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
        >
          <StyledLoginField
            data-testid="login-modal-password-field"
            type="password"
            onBlur={handleBlur}
            label="Password"
            name="password"
            value={password}
            variant="outlined"
            onChange={handleChange}
            style={{ marginTop: "2px" }}
          />
          <LoginError>{errorText}</LoginError>
          <LoginButton
            kind="primary"
            size="large"
            onClick={handleSubmit}
            variant="contained"
            data-testid="login-modal-login-button"
          >
            {buttonText}
          </LoginButton>
        </form>
      </StyledLoginContainer>
    </Modal>
  );
};
