import React, { useState } from "react";
import {
  TextFieldStyles,
  Error,
  SwitchHelpText,
  StyledSwitch,
  SwitchLabel,
  ModeToggleButton,
  UploadWarningText,
  UploadDialog,
} from "./elements";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Lyric } from "Types";
import Container from "@material-ui/core/Container";
import { UseDarkMode } from "Hooks";
import { AdaptiveTextField, Button } from "GlobalComponents/elements";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import PublishIcon from "@material-ui/icons/Publish";
import { LyricEditor } from "./LyricEditor/LyricEditor";
import MoreOptionsIcon from "@material-ui/icons/MoreVertOutlined";
import Close from "@material-ui/icons/CloseRounded";
import Fab from "@material-ui/core/Fab";

type fieldsUnion = "title" | "author" | "isPrivate" | "chorus" | "verses";

interface Props {
  lyricData?: Lyric;
  onClickFunction(item: Partial<Lyric>): void;
  editMode?: boolean;
  darkModeIsOn?: boolean;
}

interface FormFieldShape {
  label: string;
  value: string | boolean;
  name: fieldsUnion;
  width?: string;
  helpText?: string;
  multiline?: boolean;
  rows?: number;
  isSwitch?: boolean;
  required?: boolean;
}

interface FormFieldCollectionProps {
  formFields: FormFieldShape[];
  darkModeIsEnabled: boolean;
  handleBlur: ReturnType<typeof useFormik>["handleBlur"];
  handleChange: ReturnType<typeof useFormik>["handleChange"];
  errors: ReturnType<typeof useFormik>["errors"];
  touched: ReturnType<typeof useFormik>["touched"];
  isPrivate: boolean;
}

const validationSchema = Yup.object({
  title: Yup.string().required("You need to enter a title for this lyric"),
  author: Yup.string().required(
    "You need to enter the name of the artist who wrote this lyric"
  ),
  verses: Yup.string().required("You need to add at least one verse"),
});

const FormFieldCollection: React.FC<FormFieldCollectionProps> = ({
  formFields,
  handleBlur,
  handleChange,
  isPrivate,
  darkModeIsEnabled,
  errors,
  touched,
}: FormFieldCollectionProps) => (
  <>
    {formFields.map(
      ({
        label,
        value,
        multiline = false,
        rows = 1,
        helpText,
        name,
        width = "100%",
        isSwitch = false,
        required = true,
      }) => (
        <div key={label} style={{ display: "block" }}>
          {!isSwitch ? (
            <TextFieldStyles key={name}>
              <AdaptiveTextField
                darkMode={darkModeIsEnabled}
                required={required}
                name={name}
                multiline={multiline}
                rowsMax={100}
                rows={rows}
                style={{ width }}
                margin="dense"
                label={label}
                value={value}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={helpText}
                variant="outlined"
              />
              <Error>{errors[name] && touched[name] && errors[name]}</Error>
            </TextFieldStyles>
          ) : (
            <>
              <SwitchHelpText darkMode={darkModeIsEnabled}>
                {helpText}
              </SwitchHelpText>
              <StyledSwitch
                checked={isPrivate}
                onChange={handleChange}
                name={name}
                value={true}
              />
              <SwitchLabel darkMode={darkModeIsEnabled}>Private</SwitchLabel>
            </>
          )}
        </div>
      )
    )}
  </>
);

export const NewLyricForm: React.FC<Props> = ({
  onClickFunction,
  lyricData,
  editMode = false,
  darkModeIsOn = null,
}) => {
  const [manualDataEntryMode, setManualDataEntryMode] = useState(editMode);
  const [editorVersesData, setEditorVersesData] = useState("");
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);
  const darkModeHookData = UseDarkMode();

  const darkModeIsEnabled =
    darkModeIsOn !== null ? darkModeIsOn : darkModeHookData.darkModeIsEnabled;

  const adaptiveColor = darkModeIsEnabled ? "white" : "black";

  const {
    handleChange,
    values,
    errors,
    validateForm,
    handleBlur,
    touched,
    setTouched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: lyricData?.title || "",
      author: lyricData?.author || "",
      isPrivate: lyricData?.isPrivate || false,
      chorus: lyricData?.chorus || "",
      verses: lyricData?.verses || "",
    },
    validationSchema,
    onSubmit: () => undefined,
  });

  // --------- Handle file upload ---------

  let fileRead: FileReader;

  const handleFileSelected = (file: Blob) => {
    fileRead = new FileReader();
    fileRead.readAsArrayBuffer(file);
    fileRead.addEventListener("loadend", async e => {
      if (e.target?.result) {
        const text = await new Blob([e.target?.result]).text();
        setManualDataEntryMode(true);
        setFieldValue("verses", text);
      }
    });
  };

  const TxtUploadButton: React.FC = () => (
    <ModeToggleButton kind="hollow" darkModeIsEnabled={darkModeIsEnabled}>
      <PublishIcon style={{ fontSize: "19px", marginBottom: "-4px" }} />
      Upload a .TXT file:{" "}
      <input
        onChange={e => {
          if (e.target && e.target.files && e.target.files[0]) {
            handleFileSelected(e.target.files[0]);
          }
        }}
        max={1}
        accept=".txt"
        type="file"
        style={{ width: "176px" }}
      />
    </ModeToggleButton>
  );

  // ---------  Handle form fields ---------

  const { title, chorus, author, isPrivate } = values;

  const generalDataFormFields: FormFieldShape[] = [
    {
      label: "Title",
      value: title,
      name: "title",
      width: "60%",
      helpText: "Title of song",
    },
    {
      label: "Artist",
      value: author,
      name: "author",
      width: "60%",
      helpText: "Artist of song",
    },
    {
      label: "Private",
      value: isPrivate,
      name: "isPrivate",
      helpText: isPrivate
        ? "This lyric is private and will not be seen in the lyric library"
        : "This lyric is public and will be seen by anyone in the lyric library",
      isSwitch: true,
    },
  ];

  const manualEntryFormFields: FormFieldShape[] = [
    {
      label: "Chorus",
      value: chorus,
      name: "chorus",
      multiline: true,
      rows: 4,
      helpText: "Chorus of the song. This text is used in each chorus block",
      required: false,
    },
  ];

  return (
    <Container maxWidth="sm">
      {manualDataEntryMode && (
        <>
          <UploadDialog
            style={{ zIndex: 99999 }}
            open={uploadDialogIsOpen}
            onClose={() => setUploadDialogIsOpen(false)}
          >
            <>
              <UploadWarningText>
                NOTE: Uploading a file (.txt only, at this point) will overwrite
                this lyric's data.
              </UploadWarningText>
              <TxtUploadButton />
              <Fab
                onClick={() => setUploadDialogIsOpen(false)}
                size="small"
                style={{ margin: "auto", marginTop: "20px" }}
              >
                <Close />
              </Fab>
            </>
          </UploadDialog>
          <div
            style={{
              textAlign: "right",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              kind="hollow"
              onClick={() => setUploadDialogIsOpen(true)}
              style={{ color: adaptiveColor, borderColor: adaptiveColor }}
            >
              More Options <MoreOptionsIcon />
            </Button>
          </div>
        </>
      )}
      <FormFieldCollection
        errors={errors}
        touched={touched}
        isPrivate={isPrivate}
        formFields={generalDataFormFields}
        darkModeIsEnabled={darkModeIsEnabled}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      {!manualDataEntryMode && (
        <div style={{ display: "block", marginTop: "20px" }}>
          <ModeToggleButton
            kind="hollow"
            darkModeIsEnabled={darkModeIsEnabled}
            onClick={() => setManualDataEntryMode(true)}
          >
            Manually add your own lyrics{" "}
            <FontDownloadIcon
              style={{ fontSize: "19px", marginBottom: "-4px" }}
            />
          </ModeToggleButton>
          <div style={{ color: darkModeIsEnabled ? "white" : "black" }}>or</div>
          <TxtUploadButton />
        </div>
      )}
      {manualDataEntryMode && (
        <>
          <FormFieldCollection
            errors={errors}
            touched={touched}
            isPrivate={isPrivate}
            formFields={manualEntryFormFields}
            darkModeIsEnabled={darkModeIsEnabled}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <LyricEditor
            darkModeIsEnabled={darkModeIsEnabled}
            chorus={values.chorus}
            editMode={editMode}
            verses={values.verses}
            setEditorVersesData={setEditorVersesData}
          />
        </>
      )}
      <Button
        kind="primary"
        style={{
          marginTop: "25px",
          marginBottom: "40px",
        }}
        onClick={() => {
          (async () => {
            const formErrors = await validateForm();
            if (!formErrors.title && !formErrors.chorus && !formErrors.author) {
              onClickFunction({
                title,
                chorus,
                verses: editorVersesData,
                author,
                isPrivate,
              });
            } else {
              setTouched({
                title: true,
                chorus: true,
                author: true,
              });
            }
          })();
        }}
      >
        Save
      </Button>
    </Container>
  );
};
