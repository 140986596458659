import React from "react";
import { Get_Current_User_getCurrentUser_playlists } from "Types";
import {
  SetlistCardWrapper,
  SetlistCardCounter,
  SetlistCardTitle,
  SetlistCardDescriptiveText,
  SmallSetlistCardText,
} from "./elements";
import { UseDarkMode, UseResponsiveCheck } from "Hooks";
import { Grid } from "@material-ui/core";
import { Link } from "GlobalComponents";
import { truncate } from "utilities";

export const SetlistCard: React.FC<Get_Current_User_getCurrentUser_playlists> = ({
  id,
  playlistName,
  lyricList,
}) => {
  const { darkModeIsEnabled } = UseDarkMode();
  const { isMobile } = UseResponsiveCheck();

  return (
    <Link to={`/setlist/${id}`}>
      <SetlistCardWrapper darkMode={darkModeIsEnabled}>
        <Grid
          container
          style={{
            textAlign: "center",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Grid item xs={lyricList.length > 99 ? 6 : 7} md={8}>
            <SetlistCardDescriptiveText>Setlist</SetlistCardDescriptiveText>
            <SetlistCardTitle>
              {truncate({ string: playlistName, limit: isMobile ? 9 : 27 })}
            </SetlistCardTitle>
          </Grid>
          <Grid
            item
            xs={lyricList.length > 99 ? 6 : 5}
            md={4}
            style={{
              textAlign: isMobile ? "left" : "center",
            }}
          >
            <SetlistCardCounter>{lyricList.length}</SetlistCardCounter>
            <SmallSetlistCardText>Lyrics</SmallSetlistCardText>
          </Grid>
        </Grid>
      </SetlistCardWrapper>
    </Link>
  );
};
