import React from "react";
import { MainAreaWrapper, NoLyricsFoundText } from "./elements";
import { UseDarkMode, UseCurrentUser } from "Hooks";
import {
  Link,
  Navbar,
  SiteMap,
  LoadingScreen,
  PageHeader,
  LyricCountWrapper,
  SearchEngine,
  NoLyricsToDisplayText,
  DefaultPageWrapper,
} from "GlobalComponents";
import { AutocompleteSearch } from "GlobalComponents/AutocompleteSearch";
import { Query_Get_Multiple_List_Lyrics_By_Id } from "operations";
import { Get_Multiple_List_Lyrics_By_Id, List_Lyric } from "Types";
import { LyricCard } from "LyricCard";
import { TsTempType } from "utilities";

export const MyLyrics: React.FC<TsTempType> = ({
  client,
}: {
  client: TsTempType;
}) => {
  const { darkModeIsEnabled } = UseDarkMode();
  const currentUserDetails = UseCurrentUser();

  const { isLoggedIn, currentUser } = currentUserDetails;

  const {
    loading,
    refetch,
    lyricData,
    handleChange,
    filterBy,
    filter,
  } = SearchEngine<Get_Multiple_List_Lyrics_By_Id, List_Lyric>({
    client,
    query: Query_Get_Multiple_List_Lyrics_By_Id,
    queryVariables: currentUser
      ? {
          ids: currentUser?.lyrics?.map(item => ({
            lyricId: item ? item.lyricId : "",
          })),
        }
      : {},
    fieldName: "getMultipleLyricsById",
  });

  return (
    <>
      <Navbar {...currentUserDetails} />
      <DefaultPageWrapper darkMode={darkModeIsEnabled}>
        <PageHeader variant="h4">My Lyrics</PageHeader>
        <MainAreaWrapper maxWidth="sm">
          <>
            <AutocompleteSearch
              lyricInputData={lyricData}
              filterBy={filterBy}
              filter={filter}
              handleChange={handleChange}
            />

            <LyricCountWrapper
              style={{ marginTop: "30px" }}
              darkMode={darkModeIsEnabled}
            >{`Lyrics: ${lyricData?.length}`}</LyricCountWrapper>
            {lyricData && lyricData.length && isLoggedIn ? (
              lyricData.map(({ ...props }) => (
                <LyricCard
                  key={props.id}
                  showDeleteButton
                  currentUser={currentUser}
                  darkModeIsEnabled={darkModeIsEnabled}
                  getAndUpdateAllLyrics={() => refetch()}
                  {...props}
                />
              ))
            ) : loading ? (
              <LoadingScreen topSpacing darkMode={darkModeIsEnabled} />
            ) : currentUser?.lyrics.length && !lyricData.length ? (
              <NoLyricsToDisplayText darkMode={darkModeIsEnabled}>
                No Lyrics to display...
              </NoLyricsToDisplayText>
            ) : (
              <NoLyricsFoundText>
                It looks like you haven't created any lyrics yet. You can create
                new lyrics from the <Link to="/">Homescreen</Link>
              </NoLyricsFoundText>
            )}
          </>
        </MainAreaWrapper>
        <SiteMap />
      </DefaultPageWrapper>
    </>
  );
};
