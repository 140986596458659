export const getWaveViewBoxSettings = (width: number) => {
  const is2XlScreen = width > 1800;
  const isXlScreen = width < 1800 && width > 1500;
  const isLgScreen = width < 1500 && width > 1100;
  const isMdScreen = width < 1100 && width > 850;
  const isSmScreen = width < 850 && width > 400;
  const isXSmScreen = width < 400;

  // XSmScreen should be 60 5 150 500
  // SmScreen should be 60 80 160 150
  // XlScreen should be 10 95 300 500
  // 2XlScreen should be 10 88 370 110

  // 60 80 160 150

  // WaveYCut WaveXCut WaveWidth WaveHeight

  // if (isXSmScreen) console.log("isXSmScreen");
  // if (isSmScreen) console.log("isSmScreen");
  // if (isMdScreen) console.log("isMdScreen");
  // if (isLgScreen) console.log("isLgScreen");
  // if (isXlScreen) console.log("isXlScreen");
  // if (is2XlScreen) console.log("is2XlScreen");

  let topWaveYCut = 0;
  if (isXSmScreen) topWaveYCut = 60;
  if (isSmScreen) topWaveYCut = 60;
  if (isXlScreen) topWaveYCut = 10;
  if (is2XlScreen) topWaveYCut = 10;

  let topWaveXCut = 45;
  if (isXSmScreen) topWaveXCut = 5;
  if (isSmScreen) topWaveXCut = 80;
  if (isMdScreen) topWaveXCut = 0;
  if (isLgScreen) topWaveXCut = 25;
  if (isXlScreen) topWaveXCut = 95;
  if (is2XlScreen) topWaveXCut = 88;

  let topWaveHeightCut = 500;
  if (isXSmScreen) topWaveHeightCut = 150;
  if (isSmScreen) topWaveHeightCut = 160;
  if (isLgScreen) topWaveHeightCut = 500;
  if (isXlScreen) topWaveHeightCut = 300;
  if (is2XlScreen) topWaveHeightCut = 370;

  // XSmScreen should be 160 120 180 100
  // SmScreen should be 140 115 300 100
  // MdScreen should be 0 110 500 110
  // LgScreen should be 0 110 400 100
  // XlScreen should be 0 110 500 95
  // 2XlScreen should be 0 120 500 90

  let bottomWaveYCut = 0;
  if (isXSmScreen) bottomWaveYCut = 160;
  if (isSmScreen) bottomWaveYCut = 140;
  if (isMdScreen) bottomWaveYCut = 0;
  if (isLgScreen) bottomWaveYCut = 0;
  if (isXlScreen) bottomWaveYCut = 0;
  if (is2XlScreen) bottomWaveYCut = 0;

  let bottomWaveXCut = 75;
  if (isXSmScreen) bottomWaveXCut = 120;
  if (isSmScreen) bottomWaveXCut = 115;
  if (isMdScreen) bottomWaveXCut = 110;
  if (isLgScreen) bottomWaveXCut = 110;
  if (isXlScreen) bottomWaveXCut = 110;
  if (is2XlScreen) bottomWaveXCut = 120;

  let bottomWaveWidth = 500;
  if (isXSmScreen) bottomWaveWidth = 180;
  if (isSmScreen) bottomWaveWidth = 300;
  if (isMdScreen) bottomWaveWidth = 500;
  if (isLgScreen) bottomWaveWidth = 400;
  if (isXlScreen) bottomWaveWidth = 500;
  if (is2XlScreen) bottomWaveWidth = 500;

  let bottomWaveHeight = 150;
  if (isXSmScreen) bottomWaveHeight = 100;
  if (isSmScreen) bottomWaveHeight = 100;
  if (isMdScreen) bottomWaveHeight = 140;
  if (isLgScreen) bottomWaveHeight = 100;
  if (isXlScreen) bottomWaveHeight = 95;
  if (is2XlScreen) bottomWaveHeight = 90;

  let bottomWaveLowerMeasurement = "-40";
  if (isSmScreen) bottomWaveLowerMeasurement = "-4";
  if (isMdScreen) bottomWaveLowerMeasurement = "-58";
  if (isLgScreen) bottomWaveLowerMeasurement = "-1";

  return {
    topWaveYCut,
    topWaveXCut,
    topWaveHeightCut,
    bottomWaveYCut,
    bottomWaveXCut,
    bottomWaveWidth,
    bottomWaveHeight,
    isXSmScreen,
    isSmScreen,
    isMdScreen,
    bottomWaveLowerMeasurement,
  };
};
