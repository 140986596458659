import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  Query_Find_Playlist_With_Id,
  Query_Get_Multiple_List_Lyrics_By_Id,
  Mutation_Delete_Playlist,
} from "operations";
import { UseCurrentUser, UseDarkMode, UseIsOffline } from "Hooks";
import {
  Find_Playlist_With_Id_findPlaylistWithId,
  Find_Playlist_With_Id,
  Find_Playlist_With_IdVariables,
  Get_Multiple_List_Lyrics_By_Id,
  Get_Multiple_List_Lyrics_By_IdVariables,
  Lyric,
  Delete_Playlist,
  Delete_PlaylistVariables,
} from "Types";
import {
  LoadingScreen,
  Navbar,
  PageWrapper,
  PageHeader,
  LyricCountWrapper,
  Link,
  AreYouSureDialog,
} from "GlobalComponents";
import { LyricCard } from "LyricCard";
import Container from "@material-ui/core/Container";
import { NewLyricControlButton, NoSetlistsText } from "./elements";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import Delete from "@material-ui/icons/Delete";
import { EditView } from "./EditView";
import { useHistory } from "react-router-dom";
import { orderLyricsBasedOnIdList, TsTempType } from "utilities";

export const SetlistPage: React.FC<TsTempType> = ({ client }) => {
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [lyrics, setLyrics] = useState<Lyric[]>([]);
  const [editView, setEditView] = useState(false);
  const [
    setlistData,
    setSetlistData,
  ] = useState<Find_Playlist_With_Id_findPlaylistWithId>();
  const currentUserDetails = UseCurrentUser();
  const { isLoggedIn, currentUser } = currentUserDetails;
  const { darkModeIsEnabled } = UseDarkMode();
  const history = useHistory();
  const { isOffline } = UseIsOffline();

  const setlistId = window.location.pathname.slice(
    9,
    window.location.pathname.length
  );

  const [
    deleteSetlist,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<Delete_Playlist, Delete_PlaylistVariables>(
    Mutation_Delete_Playlist
  );

  const findSetlistWithId = useQuery<
    Find_Playlist_With_Id,
    Find_Playlist_With_IdVariables
  >(Query_Find_Playlist_With_Id, {
    skip: !isLoggedIn,
    variables: { uid: currentUser?.uid, playlistId: setlistId } as TsTempType,
  });

  const { data, loading, refetch: refetchPlaylistData } = findSetlistWithId;

  useEffect(() => {
    // Handle fetching cached data if offline
    if (!data && !loading) {
      try {
        const cachedData = client.readQuery({
          query: Query_Find_Playlist_With_Id,
          variables: { uid: currentUser?.uid, setlistId },
        });
        setSetlistData(cachedData.findSetlistWithId);
      } catch (error) {
        console.log(error);
      }
    }
    if (data) setSetlistData(data.findPlaylistWithId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, setlistData]);

  // ////// GET DATA FOR LYRICS \\\\\\

  const lyricListData = useQuery<
    Get_Multiple_List_Lyrics_By_Id,
    Get_Multiple_List_Lyrics_By_IdVariables
  >(Query_Get_Multiple_List_Lyrics_By_Id, {
    skip: !isLoggedIn || !setlistData?.lyricList.length,
    variables: {
      ids: setlistData?.lyricList.map(item => ({
        lyricId: item ? item.lyricId : "",
      })),
    },
  });

  useEffect(() => {
    // Handle fetching cached data if offline
    if (!lyricListData.data && !lyricListData.loading) {
      try {
        const cachedData = client.readQuery({
          query: Query_Get_Multiple_List_Lyrics_By_Id,
          skip: !isLoggedIn || !setlistData?.lyricList.length,
          variables: {
            ids: setlistData?.lyricList.map(item => ({
              lyricId: item ? item.lyricId : "",
            })),
          },
        });
        setLyrics(cachedData.data.getMultipleLyricsById as TsTempType);
      } catch (error) {
        console.log(error);
      }
    }
    if (lyricListData.data) {
      setLyrics(
        orderLyricsBasedOnIdList<Lyric>({
          fullLyricData: lyricListData.data.getMultipleLyricsById as TsTempType,
          lyricIdList: setlistData?.lyricList as TsTempType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lyricListData.data, lyricListData.loading, setlistData]);

  useEffect(() => {
    setEditView(false);
  }, [data]);

  useEffect(() => {
    const setlistString: string = JSON.stringify(setlistData);
    window.localStorage.setItem("setlist", setlistString);
  }, [setlistData]);

  if (isOffline)
    return (
      <>
        <Navbar {...currentUserDetails} />
        <PageWrapper isDarkMode={darkModeIsEnabled}>
          <NoSetlistsText
            style={{ width: "400px", marginRight: "auto", marginLeft: "auto" }}
          >
            It looks like you're offline!
            <div style={{ marginTop: "20px" }} />
            Unfortunately, setlists are not currently available while you're
            offline.
          </NoSetlistsText>
        </PageWrapper>
      </>
    );

  if (!isLoggedIn)
    return (
      <>
        <Navbar {...currentUserDetails} />
        <PageWrapper isDarkMode={darkModeIsEnabled}>
          <NoSetlistsText>
            You may only view this setlist if you are logged in and are the one
            who created it.
          </NoSetlistsText>
        </PageWrapper>
      </>
    );

  if (loading || lyricListData.loading || !setlistData)
    return <LoadingScreen darkMode={darkModeIsEnabled} />;

  const refetchAllData = () => {
    refetchPlaylistData();
  };

  return (
    <>
      <Navbar {...currentUserDetails} />
      {currentUser && (
        <AreYouSureDialog
          entryTitle={setlistData?.playlistName}
          onClickDelete={async () => {
            await deleteSetlist({
              variables: { uid: currentUser.uid, playlistId: setlistData.id },
            });
            if (!mutationLoading && !mutationError)
              history.push("/my-setlists");
          }}
          isOpen={deleteDialogIsOpen}
          setIsOpen={setDeleteDialogIsOpen}
          confirmationText="Are you sure you want to delete your setlist: "
        />
      )}
      <PageWrapper isDarkMode={darkModeIsEnabled}>
        {!editView && (
          <Link to="/my-setlists">
            <NewLyricControlButton
              kind="primary"
              style={{ marginBottom: "10px" }}
            >
              <ArrowBackIosIcon /> Back
            </NewLyricControlButton>
          </Link>
        )}
        {!editView && !isOffline && (
          <NewLyricControlButton
            kind="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => setEditView(true)}
          >
            <Edit /> Edit
          </NewLyricControlButton>
        )}
        {editView && (
          <NewLyricControlButton
            kind="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => {
              setEditView(false);
              refetchAllData();
            }}
          >
            <Cancel /> Cancel
          </NewLyricControlButton>
        )}
        {editView && (
          <NewLyricControlButton
            kind="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => setDeleteDialogIsOpen(true)}
          >
            <Delete /> Delete Setlist
          </NewLyricControlButton>
        )}
        {!editView ? (
          <Container maxWidth="sm">
            <>
              <PageHeader style={{ paddingBottom: "30px" }} variant="h4">
                {setlistData?.playlistName}
              </PageHeader>
              <LyricCountWrapper
                darkMode={darkModeIsEnabled}
              >{`Lyrics: ${lyrics.length}`}</LyricCountWrapper>
              {lyrics.map(({ ...props }, idx) => {
                return (
                  <LyricCard
                    key={props.id}
                    currentUser={currentUser}
                    darkModeIsEnabled={darkModeIsEnabled}
                    setlistId={setlistData.id}
                    setlistIdx={idx}
                    {...props}
                  />
                );
              })}
            </>
          </Container>
        ) : (
          <EditView
            setlistName={setlistData?.playlistName}
            lyricList={lyrics}
            setlistId={setlistData.id}
            refetchAllData={refetchAllData}
          />
        )}
      </PageWrapper>
    </>
  );
};
