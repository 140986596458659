import {
  Navbar,
  SiteMap,
  PageHeader,
  DefaultPageWrapper,
} from "GlobalComponents";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import {
  ChangeDonationButton,
  DescriptionText,
  StyledDonationButton,
  TotalDonationCount,
} from "./elements";
import EditIcon from "@material-ui/icons/Edit";
import { TsTempType } from "utilities";
import { UseCurrentUser, UseDarkMode } from "Hooks";
import { AdaptiveTextField } from "GlobalComponents/elements";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe(
  "pk_live_51HqlCkFduULAOQ9gwMYxZ5CMDko49gKZErbVSReCAEq4Hi654Zvq4TLhTDj5Jwixy2JY5Y1xKKZlFAGWhBDal73u00wP9wuoRc"
);

export const DonatePage: React.FC<{ client: TsTempType }> = ({ client }) => {
  const [totalDonation, setTotalDonation] = useState<null | {
    value: string;
    label: string;
  }>(null);
  const [email, setEmail] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const { darkModeIsEnabled } = UseDarkMode();

  const { isLoggedIn, currentUser } = UseCurrentUser();

  const donationOptions = [
    { value: "threeUsd", label: "$3" },
    { value: "fiveUsd", label: "$5" },
    { value: "tenUsd", label: "$10" },
    { value: "fifteenUsd", label: "$15" },
    { value: "twentyUsd", label: "$20" },
    { value: "twentyFiveUsd", label: "$25" },
    { value: "fiftyUsd", label: "$50" },
  ];

  useEffect(() => {
    if (isLoggedIn && currentUser) setEmail(currentUser?.email);
  }, [isLoggedIn, currentUser]);

  return (
    <>
      <Navbar />
      <DefaultPageWrapper darkMode={darkModeIsEnabled}>
        <PageHeader>Donate</PageHeader>
        <DescriptionText darkModeIsEnabled={darkModeIsEnabled}>
          Here at Lyric Keeper, we have an entire team devoted to ensuring you
          have the best musical experience possible!
        </DescriptionText>
        <DescriptionText darkModeIsEnabled={darkModeIsEnabled}>
          We're able to keep our software affordable because of contributions
          from our users. Even $3 goes a long way! If each user gave $3 once per
          year, we would have $7,488 to pay our hard-working developers!
        </DescriptionText>
        <TotalDonationCount darkModeIsEnabled={darkModeIsEnabled}>
          Donation amount: {totalDonation?.label || "$0"}
        </TotalDonationCount>
        {totalDonation ? (
          <>
            <div style={{ textAlign: "center" }}>
              <ChangeDonationButton
                onClick={() => setTotalDonation(null)}
                variant="contained"
              >
                Change donation amount{" "}
                <EditIcon style={{ fontSize: "initial", marginLeft: "5px" }} />
              </ChangeDonationButton>
            </div>
            <Elements stripe={promise}>
              <CheckoutForm
                userEmail={email}
                totalDonation={totalDonation.value}
              />
            </Elements>
          </>
        ) : (
          <div
            style={{ textAlign: "center", margin: "auto", maxWidth: "230px" }}
          >
            <AdaptiveTextField
              darkMode={darkModeIsEnabled}
              // onBlur={handleBlur}
              style={{ width: "100%", marginBottom: "20px" }}
              onChange={e => setEmail(e.target.value)}
              value={email}
              name="email"
              label="Email"
              variant="outlined"
            />
            {showEmailError && (
              <div
                style={{
                  fontSize: "1.2rem",
                  color: "red",
                  marginBottom: "15px",
                }}
              >
                Error! Email field cannot be blank.
              </div>
            )}
            {donationOptions.map(({ label, value }) => (
              <StyledDonationButton
                darkModeIsEnabled={darkModeIsEnabled}
                key={value}
                onClick={() => {
                  if (email.length) {
                    client.resetStore();
                    setTotalDonation({ value, label });
                  } else {
                    setShowEmailError(true);
                  }
                }}
                variant="outlined"
              >
                {label}
              </StyledDonationButton>
            ))}
          </div>
        )}
        <div style={{ height: totalDonation ? "6rem" : "4.4rem" }} />
        <SiteMap />
      </DefaultPageWrapper>
    </>
  );
};
