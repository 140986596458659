import React, { useState, useEffect } from "react";
import { Bar, StyledFab } from "./elements";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { UseScrollHandler } from "./UseScrollHandler";
import { useHistory, Link } from "react-router-dom";
import { Button } from "GlobalComponents";
import SpeedIcon from "@material-ui/icons/Speed";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import MoreIcon from "@material-ui/icons/MoreVert";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { UseCurrentUser, UseResponsiveCheck } from "Hooks";
import { AddToSetlistPopover } from "./AddToSetlistPopover";
import IconButton from "@material-ui/core/IconButton";
import { MoreMenuPopover } from "./MoreMenuPopover";
import { Find_Lyric_With_Short_Url_findLyricWithShortUrl } from "Types";
import { useLazyQuery } from "@apollo/react-hooks";
import { Query_Get_Multiple_List_Lyrics_By_Id } from "operations";
import { SpeedControlPopover } from "./SpeedControlPopover";

interface Props {
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<boolean>;
  lyricId: string;
  lyricData: Find_Lyric_With_Short_Url_findLyricWithShortUrl;
  canEdit: boolean;
  indexInSetlist: number | null;
  isSetlist: boolean;
  setlistId: string | null;
  validSetlist: boolean | undefined;
}

type AnchorElType = React.MouseEvent["currentTarget"] | null;

export const ControlsBar: React.FC<Props> = ({
  isEditMode,
  setIsEditMode,
  lyricId,
  lyricData,
  canEdit,
  indexInSetlist,
  isSetlist,
  setlistId,
  validSetlist,
}) => {
  const [speedMenuAnchorEl, setSpeedMenuAnchorEl] = useState<AnchorElType>(
    null
  );
  const [setlistMenuAnchorEl, setSetlistMenuAnchorEl] = useState<AnchorElType>(
    null
  );
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<AnchorElType>(null);
  const openSetlistMenu = (event: React.MouseEvent) => {
    setSetlistMenuAnchorEl(event.currentTarget);
  };
  const [getNext, next] = useLazyQuery(Query_Get_Multiple_List_Lyrics_By_Id);
  const [getPrev, prev] = useLazyQuery(Query_Get_Multiple_List_Lyrics_By_Id);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const speedMenuIsOpen = Boolean(speedMenuAnchorEl);
  const openSpeedMenu = (event: React.MouseEvent) => {
    setSpeedMenuAnchorEl(event.currentTarget);
  };
  const {
    increaseTime,
    start,
    isScrolling,
    stop,
    decreaseTime,
    timeoutDuration,
  } = UseScrollHandler();
  const history = useHistory();

  const handleStandardBackAction = async () => {
    stop();
    if (isSetlist) {
      history.push(`/setlist/${setlistId}`);
    } else {
      history.goBack();
    }
  };

  const { isMobile, isTablet } = UseResponsiveCheck();

  const { isLoggedIn } = UseCurrentUser();

  const getPrevShorturl = (setlistData: any) => {
    const prevArr = [];

    if (indexInSetlist && indexInSetlist > 0) {
      const prevIdx = indexInSetlist - 1;
      const prevLyricId = setlistData.lyricList[prevIdx].lyricId;
      prevArr.push({ lyricId: prevLyricId });
      getPrev({
        variables: {
          ids: prevArr,
        },
      });
    }
  };

  const getNextShorturl = (setlistData: any) => {
    const nextArr = [];
    if (
      setlistData &&
      typeof indexInSetlist === "number" &&
      indexInSetlist < setlistData.lyricList.length - 1
    ) {
      const nextIdx = indexInSetlist + 1;
      const nextLyricId = setlistData.lyricList[nextIdx].lyricId;
      nextArr.push({ lyricId: nextLyricId });
      getNext({
        variables: {
          ids: nextArr,
        },
      });
    }
  };

  useEffect(() => {
    if (prev.data && indexInSetlist) {
      const url = `/lyric/${
        prev.data.getMultipleLyricsById[0].shortUrl
      }?setlistId=${setlistId}&setlistIdx=${indexInSetlist - 1}`;
      setPrevUrl(url);
    }

    if (next.data && typeof indexInSetlist === "number") {
      const url = `/lyric/${
        next.data.getMultipleLyricsById[0].shortUrl
      }?setlistId=${setlistId}&setlistIdx=${indexInSetlist + 1}`;
      setNextUrl(url);
    }
  }, [prev, next, indexInSetlist, setlistId]);

  useEffect(() => {
    const setlistDataJSON = window.localStorage.getItem("setlist");

    if (isSetlist && validSetlist && setlistDataJSON) {
      const setlistData = JSON.parse(setlistDataJSON);
      getPrevShorturl(setlistData);
      getNextShorturl(setlistData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Bar isMobile={isMobile || isTablet}>
        {!isMobile && (
          <Button
            kind="light"
            size="small"
            style={{ marginTop: "9px", minWidth: "62px", fontSize: "1rem" }}
            onClick={() =>
              isEditMode ? setIsEditMode(false) : handleStandardBackAction()
            }
          >
            <ArrowBackIcon style={{ height: "18px", width: "20px" }} />
            {isEditMode ? "Cancel" : "Back"}
          </Button>
        )}
        {isMobile && (
          <ArrowBackIcon
            onClick={() =>
              isEditMode ? setIsEditMode(false) : handleStandardBackAction()
            }
            style={{
              height: "30px",
              width: "30px",
              marginTop: "13px",
              marginLeft: "12px",
              color: "white",
            }}
          />
        )}
        <div style={{ width: "20px" }} />
        {!isSetlist && (
          <div>
            <AddToSetlistPopover
              setSetlistMenuAnchorEl={setSetlistMenuAnchorEl}
              setlistMenuAnchorEl={setlistMenuAnchorEl}
              lyricId={lyricId}
            />
            <StyledFab disabled={!isLoggedIn} onClick={openSetlistMenu}>
              <PlaylistAddIcon />
            </StyledFab>
            <StyledFab onClick={isScrolling ? stop : start}>
              {isScrolling ? <PauseIcon /> : <PlayIcon />}
            </StyledFab>
            <SpeedControlPopover
              speedMenuAnchorEl={speedMenuAnchorEl}
              setSpeedMenuAnchorEl={setSpeedMenuAnchorEl}
              speedMenuIsOpen={speedMenuIsOpen}
              decreaseTime={decreaseTime}
              timeoutDuration={timeoutDuration}
              increaseTime={increaseTime}
            />
            <StyledFab onClick={openSpeedMenu}>
              <SpeedIcon />
            </StyledFab>
          </div>
        )}
        {isSetlist && (
          <div>
            <AddToSetlistPopover
              setSetlistMenuAnchorEl={setSetlistMenuAnchorEl}
              setlistMenuAnchorEl={setlistMenuAnchorEl}
              lyricId={lyricId}
            />
            <Link
              to={prevUrl}
              style={{
                pointerEvents: prevUrl ? "auto" : "none",
                opacity: prevUrl ? "1" : "0.5",
              }}
            >
              <StyledFab>
                <SkipPreviousIcon />
              </StyledFab>
            </Link>
            <StyledFab onClick={isScrolling ? stop : start}>
              {isScrolling ? <PauseIcon /> : <PlayIcon />}
            </StyledFab>
            <SpeedControlPopover
              decreaseTime={decreaseTime}
              timeoutDuration={timeoutDuration}
              increaseTime={increaseTime}
            />
            <Link
              to={nextUrl}
              style={{
                pointerEvents: nextUrl ? "auto" : "none",
                opacity: nextUrl ? "1" : "0.5",
              }}
            >
              <StyledFab>
                <SkipNextIcon />
              </StyledFab>
            </Link>
          </div>
        )}
        <div style={{ width: isMobile ? "30px" : "48px" }} />
        <MoreMenuPopover
          canEdit={canEdit}
          lyricData={lyricData}
          moreMenuAnchorEl={moreMenuAnchorEl}
          setMoreMenuAnchorEl={setMoreMenuAnchorEl}
          setIsEditMode={setIsEditMode}
          isEditMode={isEditMode}
          isSetlist={isSetlist}
          openSpeedMenu={openSpeedMenu}
          decreaseTime={decreaseTime}
          increaseTime={increaseTime}
          timeoutDuration={timeoutDuration}
        />
        <IconButton
          disabled={!isLoggedIn}
          style={{ padding: isMobile ? "2px" : "12px" }}
          onClick={e => setMoreMenuAnchorEl(e.currentTarget)}
        >
          <MoreIcon
            style={{
              color: isLoggedIn ? "white" : "#ffffff00",
              height: "38px",
              width: "38px",
            }}
          />
        </IconButton>
      </Bar>
    </>
  );
};
