import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  LoginButton,
  LoginError,
  ResetPasswordAnchorTag,
  StyledLoginField,
  ResetPasswordAnchorWrapper,
  LoginText,
  StyledLoginCircularProgress,
  LoginTitle,
  BackAndExitComponent,
  LinkedButtonsContainer,
} from "LoginComponents";
import {
  modalKinds,
  EmailAndPassword,
  ProviderType,
} from "GlobalComponents/LoginCreateAccountModal";
import { TsTempType } from "utilities";

interface Props {
  setModalIsOpen: React.Dispatch<boolean>;
  setModalType: React.Dispatch<modalKinds>;
  authIsLoading: boolean;
  signInUser: ({ email, password, setError }: EmailAndPassword) => void;
  currentUserIsLoading: boolean;
  modalType: modalKinds;
  signInWithProviderPt1: ({ provider, setError }: ProviderType) => void;
}

export const LoginModal: React.FC<Props> = ({
  setModalIsOpen,
  setModalType,
  authIsLoading,
  signInUser,
  currentUserIsLoading,
  modalType,
  signInWithProviderPt1,
}) => {
  const [authError, setAuthError] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("You must enter an email address"),
    password: Yup.string()
      .required("You must enter a password")
      .min(8, "Password must be at least 8 characters long"),
  });

  const {
    handleChange,
    values: { email, password },
    errors,
    validateForm,
    handleBlur,
    touched,
    setTouched,
  } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const emailError: TsTempType = errors.email && touched.email && errors.email;
  const passwordError: TsTempType =
    errors.password && touched.password && errors.password;

  const errorText: TsTempType =
    authError === "auth/user-not-found"
      ? "Email could not be found"
      : authError === "auth/wrong-password"
      ? "The password is invalid or the user does not have a password."
      : authError === "auth/too-many-requests"
      ? "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
      : passwordError;

  const buttonText: TsTempType =
    !currentUserIsLoading && !authIsLoading ? (
      "Login"
    ) : (
      <StyledLoginCircularProgress size={25} />
    );

  const handleSubmit = () => {
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.email && !formErrors.password) {
        signInUser({ email, password, setError: setAuthError });
      } else {
        setTouched({ email: true, password: true });
      }
    })();
  };

  return (
    <>
      <BackAndExitComponent
        setModalType={setModalType}
        modalType={modalType}
        setModalIsOpen={setModalIsOpen}
      />
      <LoginTitle />
      <LoginText>Welcome Back!</LoginText>
      <LinkedButtonsContainer
        signInWithProviderPt1={signInWithProviderPt1}
        setAuthError={setAuthError}
      />
      <form
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
      >
        <StyledLoginField
          data-testid="login-modal-email-field"
          type="email"
          onBlur={handleBlur}
          label="Email"
          name="email"
          value={email}
          variant="outlined"
          onChange={handleChange}
        />
        <LoginError>{emailError}</LoginError>
        <ResetPasswordAnchorWrapper>
          <ResetPasswordAnchorTag
            data-testid="login-modal-forgot-password-button"
            onClick={() => {
              setModalType("reset-password");
            }}
          >
            Forgot password?
          </ResetPasswordAnchorTag>
        </ResetPasswordAnchorWrapper>
        <StyledLoginField
          data-testid="login-modal-password-field"
          type="password"
          onBlur={handleBlur}
          label="Password"
          name="password"
          value={password}
          variant="outlined"
          onChange={handleChange}
          style={{ marginTop: "2px" }}
        />
        <LoginError>{errorText}</LoginError>
        <LoginButton
          kind="primary"
          size="large"
          onClick={handleSubmit}
          variant="contained"
          data-testid="login-modal-login-button"
        >
          {buttonText}
        </LoginButton>
      </form>
    </>
  );
};
