import {
  Button,
  CreateNewSetlistForm,
  LoadingIndicator,
} from "GlobalComponents";
import React, { useState } from "react";
import {
  ModalContentWrapper,
  SetlistPopover,
  StyledSnackbar,
} from "./elements";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { UseCurrentUser, UseDarkMode, UseResponsiveCheck } from "Hooks";
import AddToSetlistIcon from "@material-ui/icons/LibraryAdd";
import { truncate } from "utilities";
import { Get_Current_User_getCurrentUser_playlists } from "Types";
import AddedToSetlistIcon from "@material-ui/icons/LibraryAddCheck";
import { MainGreen, PrimaryBlue } from "ColorVars";
import { useMutation } from "react-apollo";
import { Mutation_Add_Lyric_To_Playlist } from "operations";
import {
  Add_Lyric_To_Playlist,
  Add_Lyric_To_PlaylistVariables,
} from "Types/__types__/Add_Lyric_To_Playlist";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  setlistMenuAnchorEl: React.MouseEvent["currentTarget"] | null;
  setSetlistMenuAnchorEl: React.Dispatch<
    React.MouseEvent["currentTarget"] | null
  >;
  lyricId: string;
}

export const AddToSetlistPopover: React.FC<Props> = ({
  setSetlistMenuAnchorEl,
  setlistMenuAnchorEl,
  lyricId,
}) => {
  const setlistMenuIsOpen = Boolean(setlistMenuAnchorEl);
  const { currentUser, refetch } = UseCurrentUser();
  const [newSetlistModalIsOpen, setNewSetlistModalIsOpen] = useState(false);
  const [setlistSnackbarIsOpen, setSetlistSnackbarIsOpen] = useState(false);
  const [setlistTitle, setSetlistTitle] = useState("");

  const { darkModeIsEnabled } = UseDarkMode();

  const handleSnackbarClose = () => setSetlistSnackbarIsOpen(false);

  const { isMobile } = UseResponsiveCheck();

  const [addLyricToPlaylistMutation, { loading }] = useMutation<
    Add_Lyric_To_Playlist,
    Add_Lyric_To_PlaylistVariables
  >(Mutation_Add_Lyric_To_Playlist, {
    onError: error => console.log(error),
    onCompleted: () => refetch(),
  });

  const checkSetlistIncludesId = (
    setlist: Get_Current_User_getCurrentUser_playlists
  ) => {
    let setlistIncludesId = false;
    setlist.lyricList.forEach(lyric => {
      if (lyric && lyric.lyricId === lyricId) setlistIncludesId = true;
    });
    return setlistIncludesId;
  };

  if (!currentUser) return <></>;

  return (
    <>
      <StyledSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={setlistSnackbarIsOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={`${setlistTitle} created successfully!`}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Modal
        open={newSetlistModalIsOpen}
        onClose={() => setNewSetlistModalIsOpen(false)}
      >
        <ModalContentWrapper darkMode={darkModeIsEnabled} isMobile={isMobile}>
          <CreateNewSetlistForm
            onCompleted={(textField?: string) => {
              setNewSetlistModalIsOpen(false);
              setSetlistSnackbarIsOpen(true);
              textField && setSetlistTitle(textField);
              refetch();
            }}
          />
        </ModalContentWrapper>
      </Modal>
      <SetlistPopover
        open={setlistMenuIsOpen}
        anchorEl={setlistMenuAnchorEl}
        onClose={() => setSetlistMenuAnchorEl(null)}
      >
        <Button
          onClick={() => setNewSetlistModalIsOpen(true)}
          kind="secondary"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "auto",
            fontSize: "0.787rem",
            height: "32px",
          }}
        >
          Create new setlist <AddIcon />
        </Button>
        <Typography style={{ marginTop: "10px" }}>Add to setlist...</Typography>
        <hr />
        {!currentUser.setlists?.length && (
          <Typography style={{ marginTop: "20px", textAlign: "center" }}>
            You don't have any setlists... Create one, above.
          </Typography>
        )}
        {currentUser.setlists?.map(
          setlist =>
            setlist && (
              <div
                onClick={async e => {
                  if (!checkSetlistIncludesId(setlist)) {
                    e.preventDefault();
                    await addLyricToPlaylistMutation({
                      variables: {
                        uid: currentUser?.uid,
                        playlistId: setlist.id,
                        newLyric: lyricId,
                      },
                    });
                    refetch();
                  }
                }}
                style={{
                  height: "fit-content",
                  display: "flex",
                  marginBottom: "14px",
                  marginTop: "14px",
                  cursor: "pointer",
                }}
              >
                {loading && (
                  <LoadingIndicator
                    style={{ height: "20px", width: "20px", marginLeft: "0px" }}
                  />
                )}
                {!loading && (
                  <>
                    {checkSetlistIncludesId(setlist) ? (
                      <AddedToSetlistIcon
                        style={{
                          display: "inline",
                          marginRight: "10px",
                          color: MainGreen,
                        }}
                      />
                    ) : (
                      <AddToSetlistIcon
                        style={{
                          display: "inline",
                          marginRight: "10px",
                          color: PrimaryBlue,
                        }}
                      />
                    )}
                  </>
                )}
                <Typography style={{ display: "inline" }}>
                  {truncate({ string: setlist.playlistName, limit: 30 })}
                </Typography>
              </div>
            )
        )}
      </SetlistPopover>
    </>
  );
};
