import React, { useState } from "react";
import { StyledSectionButton } from "../HelpPage/elements";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Share from "@material-ui/icons/Share";
import IconButton from "@material-ui/core/IconButton";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FAQs from "./QuestionArray";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";

interface LinkNumberShape {
  linkNumber: number;
}

export const FaqDropdownMenus: React.FC = () => {
  const [faqState, setFaqState] = useState(FAQs);
  const [snackBarIsOpen, setSnackBarIsOpen] = useState(false);
  const handleClose = () => setSnackBarIsOpen(false);

  const handleChange = (index: number) => {
    setFaqState(oldFaqState => {
      const newFaqState = [...oldFaqState];

      if (oldFaqState[index].ddNum === false) {
        newFaqState[index].ddNum = true;
      } else {
        newFaqState[index].ddNum = false;
      }
      return newFaqState;
    });
  };

  const copyStringToClipboard = (string: string) => {
    navigator.clipboard.writeText(string).then(() => setSnackBarIsOpen(true));
  };

  const generateLink = ({ linkNumber }: LinkNumberShape) =>
    `lyrickeeper.com/help/?q=${linkNumber}`;

  const ShareIcon: React.FC<LinkNumberShape> = ({ linkNumber }) => (
    <IconButton
      onClick={() => copyStringToClipboard(generateLink({ linkNumber }))}
    >
      <Share />
    </IconButton>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarIsOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Link copied to clipboard"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {faqState.map(value => (
        <>
          <ExpansionPanel
            data-testid={"help-page-faq-" + value.index}
            expanded={value.ddNum}
            onChange={() => handleChange(value.index)}
          >
            <ExpansionPanelSummary expandIcon={<ArrowDropDownIcon />}>
              <StyledSectionButton variant="text">
                {value.question}
              </StyledSectionButton>
              <ShareIcon linkNumber={value.index + 1} />
            </ExpansionPanelSummary>
            {value.answer}
          </ExpansionPanel>
        </>
      ))}
    </>
  );
};
