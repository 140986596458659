import { parse } from "qs";
import { TsTempType } from "utilities";

export const parseSetlistQueryParams: TsTempType = () => {
  const queryParams = parse(window.location.search);

  const setlistId = queryParams["?setlistId"]
    ? queryParams["?setlistId"]
    : null;
  const indexInSetlist = queryParams["setlistIdx"]
    ? Number(queryParams.setlistIdx)
    : null;

  if (!setlistId || indexInSetlist === null) {
    window.localStorage.removeItem("setlist");
  }

  const params = { indexInSetlist: indexInSetlist, setlistId: setlistId };

  return params;
};
