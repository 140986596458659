import React, { useState, useEffect } from "react";
import {
  Lyric,
  Edit_Playlist,
  Edit_PlaylistVariables,
  Get_Multiple_List_Lyrics_By_Id,
  Get_Multiple_List_Lyrics_By_IdVariables,
} from "Types";
import { StyledTextField, LoadingScreen } from "GlobalComponents";
import { UseDarkMode, UseCurrentUser } from "Hooks";
import { DraggableLyricCard } from "./DraggableLyricCard";
import Container from "@material-ui/core/Container";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NewLyricControlButton, NewSetlistDescriptiveText } from "./elements";
import Save from "@material-ui/icons/Save";
import { useMutation, useQuery } from "react-apollo";
import {
  Mutation_Edit_Playlist,
  Query_Get_Multiple_List_Lyrics_By_Id,
} from "operations";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NewLyricsSelectorModal } from "./NewLyricsSelectorModal";
import { TsTempType } from "utilities";

interface Props {
  lyricList: Lyric[];
  setlistName: string;
  setlistId: string;
  refetchAllData(cb?: TsTempType): void;
}

export const EditView: React.FC<Props> = ({
  lyricList,
  setlistName,
  setlistId,
  refetchAllData,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [lyricsIndex, setLyricsIndex] = useState<{ [key: string]: Lyric }>({});
  const [lyricIdList, setLyricIdList] = useState<string[]>(
    lyricList.map(({ id }) => id)
  );
  const [textFieldText, setTextFieldText] = useState(setlistName);
  const { darkModeIsEnabled } = UseDarkMode();
  const { currentUser } = UseCurrentUser();

  const [editPlaylist] = useMutation<Edit_Playlist, Edit_PlaylistVariables>(
    Mutation_Edit_Playlist
  );

  const { data, loading, refetch: refetchLyricsData } = useQuery<
    Get_Multiple_List_Lyrics_By_Id,
    Get_Multiple_List_Lyrics_By_IdVariables
  >(Query_Get_Multiple_List_Lyrics_By_Id, {
    variables: {
      ids: lyricIdList.map(id => ({
        lyricId: id,
      })),
    },
  });

  useEffect(() => {
    if (!loading && Object.keys(lyricsIndex).length !== lyricIdList.length) {
      if (!data && Object.keys(lyricsIndex).length !== lyricIdList.length) {
        refetchLyricsData();
      } else if (data && data.getMultipleLyricsById) {
        const index: TsTempType = {};
        data?.getMultipleLyricsById.forEach(({ ...lyricData }) => {
          index[lyricData.id] = { ...lyricData };
        });
        setLyricsIndex(index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, lyricIdList]);

  // Handle persistance of drag 'n drop data
  const onDragEnd = (result: TsTempType) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;
    // If the user dropped the lyric back in its original place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newLyricIdList = [...lyricIdList];
    newLyricIdList.splice(source.index, 1);
    newLyricIdList.splice(destination.index, 0, draggableId);
    setLyricIdList(newLyricIdList);
  };

  const updateSetlistAndRefetchData = async () => {
    currentUser &&
      (await editPlaylist({
        variables: {
          uid: currentUser?.uid,
          playlistName: textFieldText,
          lyricList: lyricIdList.map(id => ({ lyricId: id })),
          playlistId: setlistId,
        },
      }));
    refetchAllData();
  };

  const deleteIdFromList = ({ id }: { id: string }) => {
    setLyricIdList(d => d.filter(lyricId => lyricId !== id));
  };

  if (!Object.keys(lyricsIndex).length)
    return <LoadingScreen darkMode={darkModeIsEnabled} />;

  return (
    <>
      <NewLyricsSelectorModal
        lyricIdList={lyricIdList}
        setLyricIdList={setLyricIdList}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
      {currentUser && lyricIdList && (
        <NewLyricControlButton
          kind="primary"
          style={{ marginBottom: "10px" }}
          onClick={async () => {
            await updateSetlistAndRefetchData();
            updateSetlistAndRefetchData();
          }}
        >
          <Save /> Save
        </NewLyricControlButton>
      )}
      <NewLyricControlButton
        kind="primary"
        style={{ marginBottom: "10px" }}
        onClick={() => setModalIsOpen(true)}
      >
        <AddBoxIcon /> Add Lyrics to List
      </NewLyricControlButton>
      <div>
        <StyledTextField
          style={{ marginTop: "30px", width: "300px", marginBottom: "30px" }}
          label="Enter the setlist name here"
          name="setlist-name"
          variant="outlined"
          darkMode={darkModeIsEnabled}
          value={textFieldText}
          onChange={({ target: { value } }) => setTextFieldText(value)}
        />
      </div>
      <Container maxWidth="sm">
        <NewSetlistDescriptiveText
          style={{ marginTop: "0px", marginBottom: "30px" }}
        >
          You can reorder the list below by simply dragging each item into the
          slot you'd like!
        </NewSetlistDescriptiveText>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="edit-section">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {lyricIdList.map((id, index) => (
                  <>
                    {lyricsIndex[id] && (
                      <div key={id}>
                        <Draggable draggableId={id} index={index}>
                          {draggableProvided => (
                            <div
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              ref={draggableProvided.innerRef}
                            >
                              <DraggableLyricCard
                                draggable
                                showDeleteIcon
                                onClickDelete={deleteIdFromList}
                                selectable={false}
                                key={lyricsIndex[id].id}
                                {...lyricsIndex[id]}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )}
                  </>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </>
  );
};
