import styled from "styled-components";
import {
  MainGreen,
  SecondaryGreen,
  LighterPurple,
  WarningRed,
} from "ColorVars";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "GlobalComponents";

export const TextFieldStyles = styled.div`
  display: block;
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  min-height: 30px;
  background-color: ${MainGreen};
`;

export const HeadingTitle = styled(Typography)`
  text-align: center;
  color: white;
  font-size: 28px;
  padding: 2px;
  letter-spacing: 3px;
`;

export const StyledFab = styled(Fab)`
  height: 40px;
  width: 42px;
  right: 0.12vw;
  top: 2px;
  position: absolute;
`;

export const StyledTextField = styled(TextField)<{ darkMode: boolean }>`
  & {
    .MuiInputBase-input {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
    .MuiInputLabel-root {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
  }
`;

export const SwitchHelpText = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 1rem;
    color: ${LighterPurple};
    margin-top: 10px;
    white-space: pre-wrap;
  }
`;

export const StyledSwitch = styled(Switch)`
  &&& {
    .MuiSwitch-colorSecondary {
      color: grey;
    }
    .MuiSwitch-colorSecondary.Mui-checked {
      color: ${SecondaryGreen};
    }

    .MuiSwitch-track {
      background-color: grey;
    }
  }
`;

export const SwitchLabel = styled(Typography)<{ darkMode: boolean }>`
  && {
    font-size: 1rem;
    display: inline;
    color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
  }
`;

export const Error = styled(Typography)`
  && {
    color: red;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const ModeToggleButton = styled(Button)<{ darkModeIsEnabled: boolean }>`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
    ${({ darkModeIsEnabled }) =>
      darkModeIsEnabled
        ? `
        color: white;
        border-color: white;
      `
        : `
        color: black;
        border-color: black 
      `}
  }
`;

export const UploadWarningText = styled(Typography)`
  && {
    font-size: 15px;
    white-space: pre-wrap;
    color: ${WarningRed};
  }
`;

export const UploadDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      padding: 45px;
    }
  }
`;

export const AddNewSectionButton = styled(Button)<{ adaptiveColor: string }>`
  &&& {
    color: ${({ adaptiveColor }) => adaptiveColor};
    border-color: ${({ adaptiveColor }) => adaptiveColor};
    text-align: center;
    margin: auto;
    display: flex;
    margin-bottom: 10px;
  }
`;
