import { useState, useEffect } from "react";

interface ReturnShape {
  start(): void;
  stop(): void;
  increaseTime(): void;
  decreaseTime(): void;
  timeoutDuration: number;
  isScrolling: boolean;
}

export const UseScrollHandler = (): ReturnShape => {
  const [timeoutId, setTimeoutId] = useState(0);
  const [timeoutDuration, setTimeoutDuration] = useState(1000);
  const [isScrolling, setIsScrolling] = useState(false);

  const scroll = () => {
    window.scrollBy(0, 10);
    start();
  };

  const start = () => {
    const id = window.setTimeout(scroll, timeoutDuration);
    setIsScrolling(true);
    setTimeoutId(id);
  };

  const stop = () => {
    window.clearInterval(timeoutId);
    setIsScrolling(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearAndRestart = () => {
    if (isScrolling) {
      stop();
      start();
    }
  };

  const increaseTime = async () => {
    setTimeoutDuration(t => t + 500);
  };

  const decreaseTime = () => {
    setTimeoutDuration(t => (t > 500 ? t - 500 : t));
  };

  useEffect(() => {
    if (isScrolling) {
      clearAndRestart();
    }
    // eslint-disable-next-line
  }, [timeoutDuration]);

  return {
    start,
    stop,
    increaseTime,
    decreaseTime,
    timeoutDuration,
    isScrolling,
  };
};
