import React from "react";
import {
  StyledSpeedControlPopover,
  SpeedControlText,
  SpeedControlFab,
} from "./elements";
import MinusIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { TsTempType } from "utilities";

interface Props {
  speedMenuAnchorEl?: TsTempType;
  setSpeedMenuAnchorEl?: TsTempType;
  speedMenuIsOpen?: boolean;
  decreaseTime: TsTempType;
  timeoutDuration: number;
  increaseTime: TsTempType;
}

export const SpeedControlPopover: React.FC<Props> = ({
  speedMenuAnchorEl,
  setSpeedMenuAnchorEl,
  speedMenuIsOpen,
  decreaseTime,
  timeoutDuration,
  increaseTime,
}) => {
  return (
    <StyledSpeedControlPopover
      anchorEl={speedMenuAnchorEl}
      onClose={() => setSpeedMenuAnchorEl(null)}
      open={speedMenuIsOpen || false}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div style={{ backgroundColor: "white", display: "flex" }}>
        <SpeedControlText>Scroll Speed: </SpeedControlText>
        <SpeedControlFab onClick={() => decreaseTime()}>
          <MinusIcon />
        </SpeedControlFab>
        <SpeedControlText>{timeoutDuration}</SpeedControlText>
        <SpeedControlFab onClick={() => increaseTime()}>
          <AddIcon />
        </SpeedControlFab>
      </div>
    </StyledSpeedControlPopover>
  );
};
