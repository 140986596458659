import React from "react";
import { BackArrow, ExitButton } from "LoginComponents";
import { modalKinds } from "GlobalComponents";

interface Props {
  modalType: modalKinds;
  setModalType?: React.Dispatch<modalKinds>;
  setModalIsOpen: React.Dispatch<boolean>;
}

export const BackAndExitComponent: React.FC<Props> = ({
  modalType,
  setModalType,
  setModalIsOpen,
}) => {
  const backButtonModalType =
    modalType === "reset-password" ? "login" : "create-account";

  return (
    <>
      {modalType !== "create-account" ? (
        <BackArrow
          setModalType={setModalType}
          modalType={backButtonModalType}
        />
      ) : null}
      <ExitButton setModalIsOpen={setModalIsOpen} />
    </>
  );
};
