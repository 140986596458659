import React, { useState, useEffect } from "react";
import { PageWrapper, StyledErrorMessage } from "./elements";
import { LyricView } from "./LyricView";
import { EditView } from "./EditView";
import { Find_Lyric_With_Short_Url, Lyric } from "Types";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  Query_Find_Lyric_With_Short_Url,
  Query_Find_Playlist_With_Id,
} from "operations";
import { LoadingScreen } from "GlobalComponents";
import NoSleep from "nosleep.js";
import { UseCurrentUser, UseDarkMode } from "Hooks";
import { useHistory } from "react-router-dom";
import { TsTempType, userOwnsLyric } from "utilities";
import { ControlsBar } from "./ControlsBar";
import { parseSetlistQueryParams } from "./utilities";

const noSleep = new NoSleep();

export const LyricPage: React.FC<TsTempType> = ({ client }) => {
  const [lyricData, setLyricData] = useState<Lyric | null>();
  const [edit, setIsEditMode] = useState(false);
  const [getSetlistData, setlistData] = useLazyQuery(
    Query_Find_Playlist_With_Id
  );
  const history = useHistory();
  const { darkModeIsEnabled } = UseDarkMode();

  const { indexInSetlist, setlistId } = parseSetlistQueryParams();
  const [isSetlist, setIsSetlist] = useState(
    indexInSetlist !== null && setlistId !== null
  );
  const [validSetlist, setValidSetlist] = useState<boolean | undefined>();

  const { currentUser } = UseCurrentUser();

  const canEdit =
    lyricData && currentUser && currentUser.lyrics
      ? userOwnsLyric({
          lyricId: lyricData.id,
          currentUserLyricsList: currentUser.lyrics,
        })
      : false;

  const shortUrl = window.location.pathname.slice(
    7,
    window.location.pathname.length
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateSetlist: () => void = async () => {
    if (
      lyricData &&
      setlistData.called &&
      !setlistData.loading &&
      indexInSetlist >= 0
    ) {
      const setlistNotFound = !setlistData.data;
      const lyricNotFoundAtSetlistIndex = !setlistData.data.findPlaylistWithId
        .lyricList[indexInSetlist];
      const incorrectLyricFoundAtSetlistIndex =
        !lyricNotFoundAtSetlistIndex &&
        setlistData.data.findPlaylistWithId.lyricList[indexInSetlist]
          .lyricId !== lyricData.id;

      if (
        setlistNotFound ||
        lyricNotFoundAtSetlistIndex ||
        incorrectLyricFoundAtSetlistIndex
      ) {
        window.localStorage.removeItem("setlist");
        setValidSetlist(false);
      } else {
        setValidSetlist(true);
      }
    }
  };

  const { data, loading, error, refetch } = useQuery<Find_Lyric_With_Short_Url>(
    Query_Find_Lyric_With_Short_Url,
    {
      variables: { shortUrl },
    }
  );

  document.addEventListener(
    "touchstart",
    function enableNoSleep() {
      document.removeEventListener("click", enableNoSleep, false);
      noSleep.enable();
    },
    false
  );

  useEffect(() => {
    // Handle fetching cached data if offline
    if (!data && !loading) {
      try {
        const cachedData = client.readQuery({
          query: Query_Find_Lyric_With_Short_Url,
          variables: { shortUrl } as TsTempType,
        });
        setLyricData(cachedData.findLyricWithShortUrl[0] as TsTempType);
      } catch (err) {
        console.log(err);
      }
    }
    if (data && data.findLyricWithShortUrl)
      setLyricData(data.findLyricWithShortUrl[0]);
  }, [client, data, loading, shortUrl]);

  useEffect(() => {
    if (isSetlist) {
      validateSetlist();
    }
  }, [isSetlist, lyricData, setlistData, validateSetlist]);

  useEffect(() => {
    if (setlistData.data && setlistData.called) {
      window.localStorage.setItem(
        "setlist",
        JSON.stringify(setlistData.data.findPlaylistWithId)
      );
      setIsSetlist(true);
    } else if (setlistData.called && !setlistData.loading) {
      window.localStorage.removeItem("setlist");
      setIsSetlist(false);
    }
  }, [setlistData]);

  useEffect(() => {
    if (currentUser && isSetlist) {
      getSetlistData({
        variables: {
          playlistId: setlistId,
          uid: currentUser.uid,
        },
      });
    }
  }, [currentUser, getSetlistData, isSetlist, setlistId]);

  const setlistLoading =
    (isSetlist && !setlistData.data) ||
    (isSetlist && validSetlist === undefined);

  if (loading || setlistLoading)
    return <LoadingScreen darkMode={darkModeIsEnabled} />;

  if (
    lyricData === undefined ||
    !lyricData ||
    !data ||
    !data.findLyricWithShortUrl ||
    (isSetlist && !validSetlist)
  )
    return (
      <StyledErrorMessage>
        Sorry! But we were unable to find this Lyric!
        <br />
        <div style={{ color: "purple" }} onClick={() => history.goBack()}>
          Click here to go back
        </div>
      </StyledErrorMessage>
    );

  if (error) console.log(error);

  return (
    <PageWrapper
      style={{ paddingTop: "1px", paddingBottom: "2px" }}
      darkMode={darkModeIsEnabled}
    >
      <ControlsBar
        canEdit={canEdit}
        lyricData={data?.findLyricWithShortUrl[0]}
        lyricId={lyricData.id}
        setIsEditMode={setIsEditMode}
        isEditMode={edit}
        isSetlist={isSetlist}
        validSetlist={validSetlist}
        indexInSetlist={indexInSetlist}
        setlistId={setlistId}
      />
      {!edit ? (
        <LyricView
          canEdit={canEdit}
          setIsEditMode={setIsEditMode}
          darkModeIsEnabled={darkModeIsEnabled}
          setlistData={setlistData}
          indexInSetlist={indexInSetlist}
          isSetlist={isSetlist}
          {...lyricData}
        />
      ) : (
        <EditView
          setIsEditMode={setIsEditMode}
          lyricData={lyricData}
          refetch={refetch}
        />
      )}
    </PageWrapper>
  );
};
