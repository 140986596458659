interface AboutData {
  name: string;
  photo: { src: string };
  position: string;
  bio: string;
}

export const teamData: AboutData[] = [
  {
    name: "Bruce Johnson",
    photo: {
      src: "https://lyric-keeper.s3.amazonaws.com/bruce-headshot-2.jpeg",
    },
    position: "Founder | Lead Engineer",
    bio: `Bruce has been working as a software engineer for almost 3 years and is a graduate of the Hack Reactor Software Egineering Immersive program. 
      
      Outside of programming, Bruce enjoys playing music at local resturants in addition to biking the beautiful mountains of rural Pennsylvania`,
  },
  {
    name: "Andrea Sekito",
    photo: {
      src: "https://lyric-keeper.s3.amazonaws.com/andrea-headshot.jpg",
    },
    position: "Software Engineer",
    bio: `Andrea has been a software engineer for about a year now who is also a graduate of the Hack Reactor Software Engineering Immersive Program.
      
      She is a lover of all things food, an athlete currently training for a 5K and weight lifting competitions, and huge on traveling!`,
  },
  {
    name: "Karin Hsu",
    photo: { src: "https://lyric-keeper.s3.amazonaws.com/karin-headshot.jpeg" },
    position: "Software Engineer",
    bio:
      "Karin is a law associate turned software engineer based out of San Francisco and Taiwan",
  },
  {
    name: "Jamey Boyett",
    photo: { src: "https://lyric-keeper.s3.amazonaws.com/jamey.jpg" },
    position: "Software Engineer",
    bio:
      "Jamey is a former exercise scientist turned software engineer after he decided to pursue a life-long interest in solving problems, algorithms, and code through the Hack Reactor Software Engineering Immersive Program.",
  },
  {
    name: "Connor Mahan",
    photo: { src: "https://lyric-keeper.s3.amazonaws.com/connor-headshot.jpg" },
    position: "Sofware Engineer",
    bio:
      "Having grown up in the mountains of Montana and graduating from Codecademy's Full-Stack Developer Bootcamp, Connor is equal parts ski bum and software engineer. When he's not working on code, you can find him in the mountains. From the command line to the tree line, his passion for solving problems and finding joy in overcoming challenges remains a central focus.",
  },
  {
    name: "Chris Yue",
    photo: {
      src: "https://lyric-keeper.s3.amazonaws.com/chris-yue-headshot.jpg",
    },
    position: "UX Writer",
    bio: `Chris is an aspiring UX writer with a passion for teaching and problem-solving.
    
    Outside of UX writing, Chris has an avid interest in video games and competitive gaming. He is also an enthusiastic photographer with a keen eye for editing both copy and photos.`,
  },
  {
    name: "Omar El Tarzi",
    photo: { src: "https://lyric-keeper.s3.amazonaws.com/omar-headshot.jpg" },
    position: "Software Engineer",
    bio: `Omar has been working as a software engineer since he graduated from the Hack Reactor Software Engineering Immersive Program in Los Angeles.
    He is a DJ in his spare time and streams games on Twitch over the weekends. Omar considers himself both a foodie and a sneaker head.`,
  },
  {
    name: "Kyle Johnson",
    photo: { src: "https://lyric-keeper.s3.amazonaws.com/kyle-headshot.png" },
    position: "Software Engineer",
    bio: `Kyle is a musician, teacher, and soon-to-be graduate of the Hack Reactor Software Engineering Immersive program. 
    His undergraduate degree is in Music Education and enjoys spending his free time playing piano and mountain biking.`,
  },
];
