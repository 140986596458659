import React, { useEffect, useState } from "react";
import Tour, { ReactourStep } from "reactour";
import { MainGreen } from "ColorVars";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import styled from "styled-components";
import "./tourStyles.css";
import { UseCurrentUser } from "Hooks";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxContainer, CheckboxType } from "./elements";
import {
  Mutation_Update_Has_Taken_Auto_Tour,
  Query_Get_Current_User,
} from "operations";
import { useMutation } from "react-apollo";
import {
  Update_Has_Taken_Auto_Tour,
  Update_Has_Taken_Auto_TourVariables,
} from "Types";

const StyledTour = styled(Tour)`
  &&& {
    font-family: "Raleway", sans-serif;
    font-size: 1.3rem;
    letter-spacing: 1.3px;
    width: 76%;
  }
`;

interface Props {
  tourSteps: ReactourStep[];
}

export const GuidedTour: React.FC<Props> = ({ tourSteps }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const { currentUser } = UseCurrentUser();
  const url = window.location.pathname;

  const disableBody = (target: Element | HTMLElement) =>
    disableBodyScroll(target);
  const enableBody = (target: Element | HTMLElement) =>
    enableBodyScroll(target);

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const [setHasTakenAutoTour] = useMutation<
    Update_Has_Taken_Auto_Tour,
    Update_Has_Taken_Auto_TourVariables
  >(Mutation_Update_Has_Taken_Auto_Tour);

  const getTakenTourNumber = () => {
    if (url === "/") {
      return 1;
    } else if (url === "/help") {
      return 2;
    } else {
      return 0;
    }
  };

  const handleTourChange = () => {
    setHasTakenAutoTour({
      variables: {
        uid: currentUser?.uid ? currentUser.uid : "No Id",
        hasTakenAutoTour: getTakenTourNumber(),
      },
      refetchQueries: [
        { query: Query_Get_Current_User, variables: { uid: currentUser?.uid } },
      ],
    });
  };

  useEffect(() => {
    if (currentUser) {
      if (
        (currentUser.hasTakenAutoTour === 0 && url === "/") ||
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (currentUser!.hasTakenAutoTour! <= 1 && url === "/help")
      )
        setIsTourOpen(true);
    }
  }, [currentUser, url]);

  return (
    <StyledTour
      steps={tourSteps}
      isOpen={isTourOpen}
      maskClassName="mask"
      maskSpace={15}
      className="helper"
      scrollOffset={-200}
      rounded={10}
      accentColor={MainGreen}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      onRequestClose={closeTour}
      showNumber={false}
    >
      <CheckboxContainer data-testid="guided-tour-checkbox-container">
        <FormControlLabel
          control={<Checkbox onChange={() => handleTourChange()} />}
          data-testid="guided-tour-checkbox"
          label={<CheckboxType>Don't show this tour again</CheckboxType>}
        />
      </CheckboxContainer>
    </StyledTour>
  );
};
