import React from "react";
import {} from "LoginComponents";
import { ProviderType } from "GlobalComponents/LoginCreateAccountModal";
import { TsTempType } from "utilities";
import {
  FacebookLogo,
  GoogleLogo,
  HRLoginContainer,
  LinkedAuthButton,
  LinkedAuthText,
  StyledLoginHR,
} from "./elements";

interface Props {
  signInWithProviderPt1: ({ provider, setError }: ProviderType) => void;
  setAuthError: React.Dispatch<TsTempType>;
}

export const LinkedButtonsContainer: React.FC<Props> = ({
  signInWithProviderPt1,
  setAuthError,
}) => {
  return (
    <>
      <LinkedAuthButton
        kind="facebook"
        size="large"
        onClick={() => {
          signInWithProviderPt1({
            provider: "facebook",
            setError: setAuthError,
          });
        }}
        variant="contained"
        data-testid="facebook-modal-login-button"
      >
        <FacebookLogo />
        <LinkedAuthText>Login with Facebook</LinkedAuthText>
      </LinkedAuthButton>
      <LinkedAuthButton
        kind="google"
        size="large"
        onClick={() => {
          signInWithProviderPt1({
            provider: "google",
            setError: setAuthError,
          });
        }}
        variant="contained"
        data-testid="google-modal-login-button"
      >
        <GoogleLogo />
        <LinkedAuthText>Login with Google</LinkedAuthText>
      </LinkedAuthButton>
      <HRLoginContainer>
        <StyledLoginHR /> OR <StyledLoginHR />
      </HRLoginContainer>
    </>
  );
};
