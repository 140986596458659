import React from "react";
import {
  StyledDetailsSection,
  Bold,
  StyledIframe,
  Italic,
} from "../HelpPage/elements";
import { Image, Link } from "GlobalComponents";
import Delete from "@material-ui/icons/Delete";

const Spacer = () => (
  <>
    <br />
    <br />
  </>
);

const FAQs = [
  {
    index: 0,
    ddNum: false,
    question: "How do I install the Lyric Keeper app?",
    answer: (
      <StyledDetailsSection>
        My goal is for you to be able to enjoy everything Lyric Keeper has to
        offer for free! <Spacer />
        Because of this, I specifically built the app on a web based platform
        which is not processed by the Apple or Android app stores.
        <Spacer />
        To make this app available offline, you'll need to{" "}
        <Bold>open Safari</Bold> on your Apple device (detailed instructions for
        Android are not yet available). Then,{" "}
        <Bold>press the "share" button</Bold> found in the upper right-hand
        corner of the screen on an iPad (iPhone users can find it at the bottom
        of their screen). Lastly,{" "}
        <Bold>
          scroll down in the share menu and select "Add to homescreen" then
          "Add".
        </Bold>
        <Spacer />
        <StyledIframe
          src="https://giphy.com/embed/dxUTm3WzwSxP3x6C0z"
          width="360"
          height="480"
          frameBorder="0"
          allowFullScreen
        />
        <br />
        And that's it! The Lyric Keeper app should now be on your homescreen!
      </StyledDetailsSection>
    ),
  },
  {
    index: 1,
    ddNum: false,
    question: "Do I need an account to use Lyric Keeper?",
    answer: (
      <StyledDetailsSection>
        To keep Lyric Keeper free and safe for its' users, I put the following
        set of rules in place:
        <Spacer />
        <Bold>
          1. Anyone who uses Lyric Keeper may view any lyrics created by other
          users of the app (except for lyrics marked as private)
        </Bold>
        <Spacer />
        <Bold>2. Only confirmed users may create lyrics</Bold>
        <Spacer />
        <Bold>3. Lyrics may only be deleted by the user who created them</Bold>
        <Spacer />
        <Bold>4. Lyrics may only be edited by the user who created them</Bold>
      </StyledDetailsSection>
    ),
  },
  {
    index: 2,
    ddNum: false,
    question: "How do I create a lyric?",
    answer: (
      <StyledDetailsSection>
        <Bold>Please note: you must be logged in to create a lyric.</Bold>
        <Spacer />
        First, <Bold>go to your dashboard</Bold> by clicking the home button at
        the top of your screen. Next, click the “New Lyric” button found on the
        dashboard.”
        <Spacer />
        <div
          style={{
            textAlign: "center",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Image
            alt="New Lyric button"
            src="https://lyric-keeper.s3.amazonaws.com/new-lyric-dashboard-button.png"
          />
        </div>
        <br />
        Once the New Lyric modal has opened, you can start filling out the
        provided fields. <Bold>Note:</Bold> Lyric Keeper was built to make your
        life easier as a musician! With Lyric Keeper, you can easily avoid
        copying and re-pasting your chorus over and over again. Simply paste
        your chorus into the <Bold>Chorus</Bold> field, then type{" "}
        <Bold>(chorus)</Bold> anywhere in the <Bold>Verses</Bold> section you'd
        like the chorus to go.
        <Spacer />
        <Image
          width="100%"
          alt="New Lyric form"
          src="https://lyric-keeper.s3.amazonaws.com/new-lyric-modal.png"
        />
        <br />
      </StyledDetailsSection>
    ),
  },
  {
    index: 3,
    ddNum: false,
    question: "How do I edit or delete a lyric?",
    answer: (
      <StyledDetailsSection>
        <Bold>Note: You may only edit/delete lyrics that you have created</Bold>
        <Spacer />
        <Bold>EDITING LYRICS:</Bold>
        <Spacer />
        To edit a lyric, simply open the lyric by clicking on its' name. You can
        find a list of all the lyrics you've created by
        <Bold>
          navigating to{" "}
          <Link to="/my-lyrics">
            <Italic>/my-lyrics</Italic>
          </Link>
        </Bold>{" "}
        or by{" "}
        <Bold>
          clicking the user icon found in the top-right corner of your screen,
          then clicking "My Lyrics"
        </Bold>
        . Once you've opened the lyric,{" "}
        <Bold>click the "Edit Lyric" button</Bold> found on the left-hand side
        of the screen.
        <Spacer />
        <div
          style={{
            textAlign: "center",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Image
            alt="Edit Lyric button"
            src="https://lyric-keeper.s3.amazonaws.com/edit-button.png"
          />
        </div>
        <Bold>DELETING LYRICS:</Bold>
        <Spacer />
        To delete a lyric, first <Bold>navigate to "/my-lyrics"</Bold> or{" "}
        <Bold>
          click the user icon found in the top-right corner of your screen, then
          click "My Lyrics".
        </Bold>
        Next, simply click the trash can icon (<Delete />) on the lyric you wish
        to delete
      </StyledDetailsSection>
    ),
  },
  {
    index: 4,
    ddNum: false,
    question: "What are setlists & how can i use them?",
    answer: (
      <StyledDetailsSection>
        <Bold>What are setlists?</Bold>
        <Spacer />
        Setlists allow you to group together lyrics. Their primary purpose is to
        provide you with an easy way of keeping track of your lyrics. For
        example, you may decide you want to play a set of slow songs at your
        next gig. Without creating a setlist, you would need to either memorize
        your set list or write it down somewhere. Both options have
        disadvantages which I believe are alleviated by setlists.
        <Spacer />
        <Bold>HOW CAN I USE THEM?</Bold>
        <Spacer />
        <Bold>Creating setlists: </Bold>
        <Spacer />
        To create a setlist, first either:
        <Spacer />
        <Bold>1.</Bold> Click on the user icon in the top right-hand corner of
        the screen. Click "My Setlists". Next, click the "New setlist" button.
        <Spacer />
        <Bold>––––– or –––––</Bold>
        <Spacer />
        <Bold>2.</Bold> Visit{" "}
        <Link to="/my-setlists">
          <Italic>/my-setlists</Italic>
        </Link>
        . And click the "New setlist" button.
        <Spacer />
        From the new setlist screen you can begin customizing your list. Add a
        name and select a few lyrics to get started. Lastly, click the save
        button.
        <Spacer />
        And you're done!
        <Spacer />
        <Bold>Editing setlists: </Bold>
        <Spacer />
        From the "My Setlists" screen, open the setlist you'd like to edit. The
        directions from this point should be fairly self explanatory. You can:
        <Spacer />
        <Bold>•</Bold> Drag lyrics to rearange them
        <Spacer />
        <Bold>•</Bold> Change the name of the setlist by using the input at the
        top of the screen
        <Spacer />
        <Bold>•</Bold> Delete lyrics from the setlist by clicking the delete
        button on the lyric you'd like to delete.
        <Spacer />
        <Bold>Deleting setlists:</Bold>
        <Spacer />
        From the "My Setlists" screen, open the setlist you'd like to delete.
        Next, click the "Edit" button. Finally, click the "Delete Setlist"
        button and click "Yes", when prompted.
      </StyledDetailsSection>
    ),
  },
];

export default FAQs;
