// Greens
export const MainGreen = "#179756";
export const SecondaryGreen = "#379717";
export const SemiLightGreen = "#028a0285";
export const BrightGreen = "#62c262";
export const DarkModeGreen = "#1b9c1b";
export const TransparentGreenLight = "#cff7cf9e";

// Blues
export const PrimaryBlue = "#175797";
export const LighterBlue = "#207ce6";
export const FacebookBlue = "#385C8E";

// Brownish
export const SecondaryColor = "#971758";
export const LighterPurple = "#a85981";
export const DarkModeLighterPurple = "#ff6db7";
export const DarkModeSecondaryLighterPurple = "#ec0d7e";

// Greys
export const SecondaryLightGrey = "#d3d3d363";
export const DarkModeSecondaryLightGrey = "#fffdfdd1";
export const FogGrey = "#565656";
export const GreyGlass = "#56565652";
export const SuperLightGrey = "#e3e3e3";
export const GoogleGrey = "#666";

// Background
export const DarkModePageBackground = "#1a1a1a";

// Reds
export const SignOutRed = "#de4a4a";
export const WarningRed = "#e74040";
