import React, { useEffect, useState } from "react";
import { TextBlock } from "./TextBlock";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { checkArrayEquality, TsTempType } from "utilities";
import { AddNewSectionButton } from "../elements";

interface Props {
  verses: string;
  chorus: string;
  editMode: boolean;
  darkModeIsEnabled: boolean;
  // Used to share data from the editor
  setEditorVersesData: React.Dispatch<string>;
}

export const LyricEditor: React.FC<Props> = ({
  verses,
  editMode,
  chorus,
  setEditorVersesData,
  darkModeIsEnabled,
}) => {
  let spacedArr: string[] = [];

  const adaptiveColor = darkModeIsEnabled ? "white" : "black";

  if (verses.length) {
    // Handles splitting up the verses based on chorus and double line breaks
    verses.split(`\n\n`).forEach(item => {
      spacedArr = [
        ...spacedArr,
        // Using replace to avoid duplicates
        ...item.replace("(chorus)", "chorString/(chorus)").split("chorString/"),
      ];
    });
  }

  const [localGuiState, setLocalGuiState] = useState<string[]>(spacedArr || []);

  const cleanBlankVals = (arr: string[]): string[] => {
    const returnArr: string[] = [];
    arr.forEach(item => item.length && returnArr.push(item));
    return returnArr;
  };

  useEffect(() => {
    setEditorVersesData(cleanBlankVals(localGuiState).join(`\n\n`));
  }, [localGuiState, setEditorVersesData]);

  useEffect(() => {
    if (!checkArrayEquality(localGuiState, spacedArr))
      setLocalGuiState(spacedArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verses]);

  // Handle persistance of drag 'n drop data
  const onDragEnd = (result: TsTempType) => {
    const { destination, source } = result;

    if (!destination) return;
    // If the user dropped the lyric back in its original place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newLyricVerseList = [...localGuiState];
    const draggedBlockData = (newLyricVerseList.slice(
      source.index,
      source.index + 1
    ) as unknown) as string;
    newLyricVerseList.splice(source.index, 1);
    newLyricVerseList.splice(destination.index, 0, draggedBlockData.toString());
    setLocalGuiState(newLyricVerseList);
  };

  const addNewSection = (newStr: string) => {
    setLocalGuiState(state => [...state, newStr]);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const deleteBlock = (index: number) => {
    setLocalGuiState(state => {
      let finalArr = [...state];
      if (finalArr.length > 1) {
        finalArr.splice(index, 1);
      } else {
        finalArr = [];
      }
      return finalArr;
    });
  };

  const editBlockText = (str: string, index: number) => {
    setLocalGuiState(state => {
      const newState = [...state];
      newState[index] = str;
      return newState;
    });
  };

  return (
    <div
      style={{
        marginBottom: "30px",
        marginTop: "40px",
      }}
    >
      <div style={{ display: "flex", flexFlow: "wrap" }}>
        <AddNewSectionButton
          adaptiveColor={adaptiveColor}
          kind="hollow"
          size="small"
          onClick={() => {
            addNewSection(
              "Add your verse here then press done when you're finished"
            );
          }}
        >
          New Verse Block <MusicNoteIcon style={{ marginLeft: "5px" }} />
        </AddNewSectionButton>
        <AddNewSectionButton
          adaptiveColor={adaptiveColor}
          kind="hollow"
          onClick={() => {
            addNewSection("(chorus)");
          }}
        >
          New Chorus Block <LibraryMusicIcon style={{ marginLeft: "6px" }} />
        </AddNewSectionButton>
      </div>
      <div style={{ marginTop: "30px" }}>
        {localGuiState.length >= 1 && (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="edit-section">
                {provided => (
                  <>
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {localGuiState.map(
                        (str: string, index) =>
                          str.length >= 1 && (
                            <Draggable
                              draggableId={`${index + 1}`}
                              index={index}
                              key={index}
                            >
                              {draggableProvided => (
                                <div
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                >
                                  {!str.includes("(chorus)") ? (
                                    <TextBlock
                                      editBool={editMode}
                                      deleteBlock={deleteBlock}
                                      blockIndex={index}
                                      blockString={str}
                                      editBlockText={editBlockText}
                                    />
                                  ) : (
                                    <TextBlock
                                      editBool={editMode}
                                      deleteBlock={deleteBlock}
                                      blockIndex={index}
                                      blockString={
                                        chorus.length
                                          ? chorus
                                          : "Add a chorus in the chorus field above to change this text"
                                      }
                                      isChorus
                                      editBlockText={editBlockText}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          )
                      )}
                    </div>
                    {provided.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </div>
    </div>
  );
};
