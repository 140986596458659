import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { StyledDialogTitle, DialogLyricTitle, Button } from "./elements";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  onClickDelete(): void;
  entryTitle: string;
  confirmationText?: string;
}

export const AreYouSureDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onClickDelete,
  entryTitle,
  confirmationText,
}) => (
  <Dialog open={isOpen}>
    <StyledDialogTitle>
      {confirmationText
        ? confirmationText
        : "Are you sure you want to delete: "}
      <DialogLyricTitle>{entryTitle}</DialogLyricTitle>
    </StyledDialogTitle>
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <Button onClick={() => setIsOpen(false)} kind="secondary">
        Cancel
      </Button>
      <Button
        onClick={() => {
          onClickDelete();
          setIsOpen(false);
        }}
        kind="warning"
      >
        Delete
      </Button>
    </div>
  </Dialog>
);
