import React, { useEffect, useState } from "react";
import {
  LoginCreateAccountModal,
  MarketingModal,
  Navbar,
  DefaultPageWrapper,
  SiteMap,
  modalKinds,
} from "GlobalComponents";
import { UseCurrentUser, UseDarkMode } from "Hooks";
import { default as LoggedInView } from "./LoggedInView";
import { LoggedOutView } from "./LoggedOutView";
import { GuidedTour, HomePageTourConfig } from "GlobalComponents";

export const Homepage: React.FC = () => {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [modalKind, setModalKind] = useState<modalKinds>("create-account");
  const [marketingModalIsOpen, setMarketingModalIsOpen] = useState(true);

  const currentUserDetails = UseCurrentUser();

  const {
    isLoggedIn,
    setUser,
    currentUserIsLoading,
    logout,
  } = currentUserDetails;

  const { darkModeIsEnabled, setDarkMode } = UseDarkMode();

  useEffect(() => {
    if (
      isLoggedIn ||
      window.localStorage.getItem("showMarketing") === "false"
    ) {
      setLoginModalIsOpen(false);
      setMarketingModalIsOpen(false);
    } else {
      setMarketingModalIsOpen(true);
    }
  }, [isLoggedIn]);

  const handleMarketingLoginButtonClick = () => {
    setMarketingModalIsOpen(false);
    setLoginModalIsOpen(true);
  };

  return (
    <>
      <Navbar {...currentUserDetails} />
      <LoginCreateAccountModal
        currentUserIsLoading={currentUserIsLoading}
        isOpen={loginModalIsOpen}
        setIsOpen={setLoginModalIsOpen}
        setUser={setUser}
        modalKind={modalKind}
        logout={logout}
      />
      <MarketingModal
        handleLoginButtonClick={handleMarketingLoginButtonClick}
        isOpen={marketingModalIsOpen}
        setIsOpen={setMarketingModalIsOpen}
      />
      {isLoggedIn ? (
        <DefaultPageWrapper
          style={{ paddingBottom: "15vh" }}
          topPaddingFalse={!isLoggedIn}
          darkMode={darkModeIsEnabled}
        >
          <GuidedTour tourSteps={HomePageTourConfig} />
          <LoggedInView
            setDarkMode={setDarkMode}
            darkModeIsEnabled={darkModeIsEnabled}
          />
          <SiteMap />
        </DefaultPageWrapper>
      ) : (
        <LoggedOutView
          setModalKind={setModalKind}
          setLoginModalIsOpen={setLoginModalIsOpen}
        />
      )}
    </>
  );
};
