import React from "react";
import {
  SongTitle,
  SongAuthor,
  SongVerse,
  SongChorus,
  HeaderWrapper,
  SetlistTitle,
  SetlistSubTitle,
  SetlistMetaWrapper,
} from "./elements";
import { Lyric_Without_Short_Url } from "Types";
import { TsTempType } from "utilities";
import { UseCurrentUser, UseResponsiveCheck } from "Hooks";
import { Button } from "GlobalComponents";
import Edit from "@material-ui/icons/Edit";

export const LyricView: React.FC<
  Lyric_Without_Short_Url & {
    darkModeIsEnabled: boolean;
    setIsEditMode: React.Dispatch<boolean>;
    canEdit: boolean;
    setlistData?: TsTempType;
    indexInSetlist: number | null;
    isSetlist: boolean;
  }
> = ({
  title,
  author,
  verses,
  chorus,
  darkModeIsEnabled,
  setIsEditMode,
  canEdit,
  setlistData,
  indexInSetlist,
  isSetlist,
}) => {
  const { isMobile } = UseResponsiveCheck();

  const { isLoggedIn } = UseCurrentUser();

  return (
    <>
      <HeaderWrapper darkMode={darkModeIsEnabled}>
        <SongTitle darkMode={darkModeIsEnabled}>{title}</SongTitle>
        <SongAuthor darkMode={darkModeIsEnabled}>{author}</SongAuthor>
        {isLoggedIn && canEdit && (
          <Button
            onClick={() => setIsEditMode(true)}
            style={{
              minWidth: "90px",
              fontSize: "1rem",
              height: "34px",
              marginTop: "15px",
            }}
            kind="primary"
            size="small"
          >
            <Edit
              style={{ marginRight: "6px", height: "20px", width: "20px" }}
            />
            Edit
          </Button>
        )}
        {isSetlist && indexInSetlist !== null && (
          <SetlistMetaWrapper>
            <SetlistTitle
              darkMode={darkModeIsEnabled}
            >{`Setlist: ${setlistData.data.findPlaylistWithId.playlistName}`}</SetlistTitle>
            <SetlistSubTitle darkMode={darkModeIsEnabled}>{`(${
              indexInSetlist + 1
            }/${
              setlistData.data.findPlaylistWithId.lyricList.length
            })`}</SetlistSubTitle>
          </SetlistMetaWrapper>
        )}
        {!isLoggedIn && <div style={{ marginTop: "32px" }} />}
      </HeaderWrapper>
      <div style={{ marginBottom: "40px" }} />
      {verses && (
        <>
          {verses
            .split("(chorus)")
            .map(
              (item: TsTempType, index: number, arr: string | TsTempType[]) => {
                if (index < arr.length - 1) {
                  return (
                    <div key={index}>
                      <SongVerse
                        isMobile={isMobile}
                        darkMode={darkModeIsEnabled}
                      >
                        {item}
                      </SongVerse>
                      <div
                        style={{
                          marginLeft: isMobile ? "10vw" : "33vw",
                          maxWidth: "48%",
                        }}
                      >
                        <SongChorus
                          isMobile={isMobile}
                          darkMode={darkModeIsEnabled}
                        >
                          {chorus}
                        </SongChorus>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <SongVerse
                      isMobile={isMobile}
                      darkMode={darkModeIsEnabled}
                      key={index}
                    >
                      {item}
                    </SongVerse>
                  );
                }
              }
            )}
          <div style={{ marginBottom: "120px" }} />
        </>
      )}
    </>
  );
};
