import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { DarkModePageBackground } from "ColorVars";

export const StyledMenuItem = styled(MenuItem)`
  &&& {
    text-align: center;
    font-size: 1.3rem;
  }
`;

export const DesktopNavItemsWrapper = styled.div`
  text-align: right;
  margin-left: auto;
`;

export const DesktopNavButton = styled(Button)`
  &&& {
    font-size: 1rem;
    text-transform: none;
    color: white;
    text-align: right;
    font-weight: 400;
    letter-spacing: 1.8px;
  }
`;

export const StyledMenu = styled(Menu)`
  && {
    .MuiMenu-paper {
      padding: 10px;
      padding-top: 5px;
    }
  }
`;

export const NavMainText = styled(Typography)`
  display: inline;
  position: absolute;
  top: 15px;
`;

export const NavInnerModalWrapper = styled.div<{ isDarkMode: boolean }>`
  background-color: ${({ isDarkMode }) =>
    isDarkMode ? DarkModePageBackground : "white"};
  height: 93%;
  position: relative;
  top: 55px;
  padding-top: 2px;
`;

export const MobileNavButton = styled(Button)<{ isDarkMode: boolean }>`
  && {
    display: block;
    font-size: 1.2rem;
    text-align: center;
    margin: auto;
    text-transform: none;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    margin-top: 18px;
    margin-bottom: 18px;
  }
`;
