import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useQuery } from "react-apollo";
import { Get_Payment_Intent, Get_Payment_IntentVariables } from "Types";
import { Query_Get_Payment_Intent } from "operations";
import { TsTempType } from "utilities";
import {
  CardElementWrapper,
  ErrorMessage,
  ResultMessage,
  StyledCheckoutForm,
  StyledSubmitButton,
} from "./elements";
import Spinner from "@material-ui/core/CircularProgress";

export const CheckoutForm: React.FC<{
  totalDonation: string;
  userEmail: string;
}> = ({ totalDonation, userEmail }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const { data, loading } = useQuery<
    Get_Payment_Intent,
    Get_Payment_IntentVariables
  >(Query_Get_Payment_Intent, {
    variables: {
      items: [{ id: totalDonation }],
      paymentType: "DONATION",
      userEmail,
    },
  });

  useEffect(() => {
    if (data) setClientSecret(data?.getPaymentIntent.clientSecret);
  }, [data]);

  if (loading) return <></>;

  const handleChange = async (event: {
    empty: React.SetStateAction<boolean>;
    error?: { message: React.SetStateAction<TsTempType> };
  }) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: { preventDefault: () => void }) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe?.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements?.getElement(CardElement) as TsTempType,
      },
    });
    if (payload?.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <StyledCheckoutForm id="payment-form" onSubmit={handleSubmit}>
      <CardElementWrapper>
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
      </CardElementWrapper>
      <StyledSubmitButton
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <Spinner size="1.4rem" style={{ color: "black" }} />
          ) : (
            "Pay"
          )}
        </span>
      </StyledSubmitButton>
      {/* Show any error that happens when processing the payment */}
      {error && <ErrorMessage role="alert">{error}</ErrorMessage>}
      {/* Show a success message upon completion */}
      <ResultMessage style={{ display: !succeeded ? "none" : "" }}>
        Payment succeeded! Thank you so much for your donation!
      </ResultMessage>
    </StyledCheckoutForm>
  );
};
