import React, { useEffect, useState } from "react";
import { Homepage } from "Homepage";
import { LyricPage } from "LyricPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import localForage from "localforage";
import { MyLyrics } from "MyLyrics";
import { HelpPage } from "HelpPage";
import { MySetlists, NewSetlistScreen, SetlistPage } from "MySetlists";
import { LibraryPage } from "LibraryPage";
import { ContactPage } from "ContactPage";
import { AboutPage } from "AboutPage";
import { FourOhFourPage } from "FourOhFourPage";
import { TsTempType } from "utilities";
import { DonatePage } from "DonationPage";
import { PrivacyPolicyPage } from "PrivacyPolicyPage";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  const [loaded, setLoaded] = useState(false);
  const [client, setClient] = useState<TsTempType>(null);
  const cache = new InMemoryCache();

  const serverUri =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8181/"
      : "https://lyric-keeper-server.herokuapp.com/";

  useEffect(() => {
    const setPersistantCache = async () => {
      await persistCache({
        cache,
        storage: localForage as TsTempType,
      });
    };

    setPersistantCache();
    setClient(
      new ApolloClient({
        uri: serverUri,
        cache,
      })
    );
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loaded && client)
    return (
      <ApolloProvider client={client}>
        <Router forceRefresh>
          <Switch>
            <Route path="/" exact component={() => <Homepage />} />
            <Route
              path="/lyric/:lyricUrl"
              component={() => <LyricPage client={client} />}
            />
            <Route
              path="/my-lyrics"
              component={() => <MyLyrics client={client} />}
            />
            <Route path="/help" component={() => <HelpPage />} />
            <Route
              path="/library"
              component={() => <LibraryPage client={client} />}
            />
            <Route path="/my-setlists" component={() => <MySetlists />} />
            <Route path="/new-setlist" component={() => <NewSetlistScreen />} />
            <Route
              path="/setlist/:setlistId"
              component={() => <SetlistPage client={client} />}
            />
            <Route path="/contact-us" component={() => <ContactPage />} />
            <Route path="/about" component={() => <AboutPage />} />
            <Route
              path="/donate"
              component={() => <DonatePage client={client} />}
            />
            <Route path="/privacy" component={() => <PrivacyPolicyPage />} />
            <Route render={() => <FourOhFourPage />} />
          </Switch>
        </Router>
      </ApolloProvider>
    );

  return <></>;
}

export default App;
