import React, { useState } from "react";
import {
  CardWrapper,
  CardTitle,
  CardAuthor,
  DeleteButtonWrapper,
  CardTextWrapper,
} from "./elements";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { LoadingIndicator, AreYouSureDialog } from "GlobalComponents";
import { useMutation } from "react-apollo";
import {
  Delete_Lyric_Matching_IdVariables,
  Delete_Lyric_Matching_Id,
  Delete_Lyric_From_User_ListVariables,
  Delete_Lyric_From_User_List,
  List_Lyric,
} from "Types";
import {
  Mutation_Delete_Lyric_Matching_Id,
  Mutation_Delete_Lyric_From_User_List,
} from "operations";
import { truncate } from "utilities";
import { UseResponsiveCheck, UseCurrentUserReturnShape } from "Hooks";

type Props = List_Lyric & {
  getAndUpdateAllLyrics?(): void;
  darkModeIsEnabled: boolean;
  currentUser: UseCurrentUserReturnShape["currentUser"];
  showDeleteButton?: boolean;
  setlistId?: string;
  setlistIdx?: number;
};

export const LyricCard: React.FC<Props> = ({
  title,
  author,
  shortUrl,
  id,
  getAndUpdateAllLyrics,
  darkModeIsEnabled,
  currentUser,
  showDeleteButton = false,
  setlistId,
  setlistIdx,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [deleteLyric, { loading: mutationLoading }] = useMutation<
    Delete_Lyric_Matching_Id,
    Delete_Lyric_Matching_IdVariables
  >(Mutation_Delete_Lyric_Matching_Id, {
    onCompleted: () => getAndUpdateAllLyrics && getAndUpdateAllLyrics(),
  });

  const [deleteLyricFromUserList] = useMutation<
    Delete_Lyric_From_User_List,
    Delete_Lyric_From_User_ListVariables
  >(Mutation_Delete_Lyric_From_User_List);

  const { isMobile } = UseResponsiveCheck();

  const limit = isMobile ? 18 : 26;

  if (mutationLoading) return <LoadingIndicator />;

  const canDelete = currentUser && showDeleteButton ? true : false;

  return (
    <>
      {canDelete && currentUser && (
        <AreYouSureDialog
          entryTitle={title}
          onClickDelete={() => {
            deleteLyricFromUserList({
              variables: { uid: currentUser.uid, lyricId: id },
            });
            deleteLyric({ variables: { id } });
          }}
          isOpen={dialogIsOpen}
          setIsOpen={setDialogIsOpen}
        />
      )}
      <CardWrapper
        to={
          setlistId
            ? `/lyric/${shortUrl}?setlistId=${setlistId}&setlistIdx=${setlistIdx}`
            : `/lyric/${shortUrl}`
        }
        canDelete={canDelete}
        darkMode={darkModeIsEnabled}
      >
        <CardTextWrapper canDelete={canDelete}>
          <CardTitle>{truncate({ string: title, limit })}</CardTitle>
          {author && (
            <CardAuthor>{truncate({ string: author, limit })}</CardAuthor>
          )}
        </CardTextWrapper>
        {canDelete && (
          <DeleteButtonWrapper canDelete={canDelete}>
            <IconButton
              onClick={e => {
                e.preventDefault();
                setDialogIsOpen(true);
              }}
              style={{
                display: "inline",
                padding: "0px",
                top: "4px",
              }}
            >
              <Delete style={{ width: "1.2em", height: "1.2em" }} />
              {mutationLoading && <LoadingIndicator />}
            </IconButton>
          </DeleteButtonWrapper>
        )}
      </CardWrapper>
    </>
  );
};
