import React from "react";
import { LoginHeader, LoginTitleWrapper, LoginMainLogo } from "LoginComponents";

export const LoginTitle: React.FC = () => {
  return (
    <LoginTitleWrapper>
      <LoginMainLogo />
      <LoginHeader style={{ display: "inline-block" }}>
        Lyric Keeper
      </LoginHeader>
    </LoginTitleWrapper>
  );
};
