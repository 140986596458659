import React, { useState, useEffect } from "react";
import {
  NewSetlistDescriptiveText,
  MainAreaWrapper,
  NewLyricControlButton,
  StyledModal,
} from "./elements";
import {
  Get_All_Lyrics_Title_And_Author,
  Get_All_Lyrics_Title_And_Author_allLyrics,
} from "Types";
import { UseIsOffline, UseDarkMode, UseResponsiveCheck } from "Hooks";
import { useQuery } from "react-apollo";
import { Query_Get_All_Lyrics_Title_And_Author } from "operations";
import { findNonPrivateLyrics, TsTempType } from "utilities";
import { LyricCountWrapper, ModalContentWrapper } from "GlobalComponents";
import { DraggableLyricCard } from "./DraggableLyricCard";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<boolean>;
  lyricIdList: string[];
  setLyricIdList: React.Dispatch<string[]>;
}

export const NewLyricsSelectorModal: React.FC<Props> = ({
  modalIsOpen,
  setModalIsOpen,
  lyricIdList,
  setLyricIdList,
}) => {
  const [selectedLyrics, setSelectedLyrics] = useState<TsTempType[]>([]);
  const [clearAll, setClearAll] = useState(false);
  const [allLyrics, setAllLyrics] = useState<
    Get_All_Lyrics_Title_And_Author["allLyrics"]
  >([]);
  const { isOffline } = UseIsOffline();
  const { darkModeIsEnabled } = UseDarkMode();
  const { isMobile } = UseResponsiveCheck();

  const { data, loading } = useQuery<Get_All_Lyrics_Title_And_Author>(
    Query_Get_All_Lyrics_Title_And_Author,
    { skip: isOffline }
  );
  useEffect(() => {
    if (data && data.allLyrics && !loading) {
      const nonPrivateData = findNonPrivateLyrics<Get_All_Lyrics_Title_And_Author_allLyrics>(
        data.allLyrics
      );

      if (nonPrivateData) {
        setAllLyrics(
          nonPrivateData.filter(({ id }) => !lyricIdList.includes(id))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const addNewLyricsToList = () => {
    setLyricIdList([...lyricIdList, ...Object.keys(selectedLyrics)]);
    setModalIsOpen(false);
  };

  return (
    <StyledModal onClose={() => setModalIsOpen(false)} open={modalIsOpen}>
      <ModalContentWrapper isMobile={isMobile} darkMode={darkModeIsEnabled}>
        <NewSetlistDescriptiveText>
          Select the lyric(s) you'd like to add to your setlist
          <br />
          <div style={{ fontSize: "0.9rem", marginTop: "10px" }}>
            Currently, private lyrics cannot be added to a setlist. I'm working
            on this, though!
          </div>
        </NewSetlistDescriptiveText>
        <NewLyricControlButton
          kind="primary"
          onClick={() => {
            setSelectedLyrics([]);
            setClearAll(!clearAll);
          }}
        >
          Clear selection <ClearAllIcon />
        </NewLyricControlButton>
        <NewLyricControlButton
          kind="primary"
          onClick={() => {
            addNewLyricsToList();
          }}
        >
          Done <CheckCircleIcon />
        </NewLyricControlButton>
        <MainAreaWrapper maxWidth="sm">
          <LyricCountWrapper darkMode={darkModeIsEnabled}>{`Lyrics selected: ${
            Object.keys(selectedLyrics).length
          }`}</LyricCountWrapper>
          <>
            {allLyrics?.map(({ title, author, id }) => (
              <DraggableLyricCard
                key={id}
                clearAll={clearAll}
                setSelectedLyrics={setSelectedLyrics}
                id={id}
                title={title}
                author={author}
              />
            ))}
          </>
        </MainAreaWrapper>
      </ModalContentWrapper>
    </StyledModal>
  );
};
