import React from "react";
import { DefaultPageWrapper, Navbar, PageHeader } from "GlobalComponents";
import { UseDarkMode } from "Hooks";

export const FourOhFourPage: React.FC = () => {
  const { darkModeIsEnabled } = UseDarkMode();
  return (
    <>
      <Navbar />
      <DefaultPageWrapper
        style={{ paddingBottom: "16px" }}
        darkMode={darkModeIsEnabled}
      >
        <PageHeader style={{ fontSize: "8rem" }}>404</PageHeader>
        <PageHeader>Oops! It looks like this page doesn't exist!</PageHeader>
      </DefaultPageWrapper>
    </>
  );
};
