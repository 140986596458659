import React from "react";
import { Navbar, PageWrapper, CreateNewSetlistForm } from "GlobalComponents";
import { UseDarkMode, UseCurrentUser } from "Hooks";
import { NoSetlistsText } from "./elements";

export const NewSetlistScreen: React.FC = () => {
  const { darkModeIsEnabled } = UseDarkMode();

  const currentUserDetails = UseCurrentUser();

  const { isLoggedIn } = currentUserDetails;

  if (!isLoggedIn)
    return (
      <>
        <Navbar {...currentUserDetails} />
        <PageWrapper isDarkMode={darkModeIsEnabled}>
          <NoSetlistsText>
            You must be logged in to create a setlist
          </NoSetlistsText>
        </PageWrapper>
      </>
    );

  return (
    <>
      <Navbar {...currentUserDetails} />
      <PageWrapper isDarkMode={darkModeIsEnabled}>
        <CreateNewSetlistForm />
      </PageWrapper>
    </>
  );
};
