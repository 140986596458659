import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Lock, Close, ArrowBackIos, CheckCircle } from "@material-ui/icons";
import { FacebookIcon, GoogleIcon, MainLogo } from "Icons";
import { StyledContainer, Button, Error } from "GlobalComponents/elements";

export const StyledLoginContainer = styled(StyledContainer)`
  max-width: 375px;
  border-radius: 20px;
  outline: none;
  padding-bottom: 35px;
`;

export const StyledSmallLoginContainer = styled(StyledLoginContainer)`
  position: relative;
  bottom: 15px;
  min-height: 50px;
  padding-bottom: 15px;
`;

export const StyledAnchorWrapper = styled.div`
  display: inline-block;
`;

export const ResetPasswordAnchorWrapper = styled(StyledAnchorWrapper)`
  margin: 10px 0px 0px 150px;
`;

export const LoginHeader = styled(Typography)`
  && {
    color: #666;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 300;
    padding: 10px 0px;
  }
`;

export const BackAndExitButtonWrapper = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const BasicLoginText = styled(Typography)`
  && {
    display: block;
    color: #666;
    font-size: 1.3rem;
    padding: 10px;
    font-weight: 300;
  }
`;

export const LoginText = styled(BasicLoginText)`
  && {
    margin: auto;
    max-width: 75%;
  }
`;

export const BackArrowLoginText = styled(LoginText)`
  && {
    padding: 0px;
    max-width: 100%;
    display: inline-block;
    font-size: 1.3rem;
    color: #666;
  }
`;

export const ResetPasswordText = styled(BasicLoginText)`
  && {
    text-align: left;
    margin: 10px;
    font-size: 1.2rem;
    padding-left: 25px;
    max-width: 85%;
  }
`;

export const ResetPasswordTextLight = styled(ResetPasswordText)`
  color: #999;
`;

export const LoginMainLogo = styled(MainLogo)`
  height: 70px;
  width: 70px;
  display: inline-block;
`;

export const LoginTitleWrapper = styled.div`
  clear: both;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px 0px 0px;
`;

export const StyledLoginField = styled(TextField)`
  && {
    border-color: black;
    width: 270px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    text-align: center;
    .MuiInputBase-input {
      width: 260px;
    }
    .MuiInput-root.MuiInputBase-root {
      width: inherit;
    }
    .MuiOutlinedInput-root {
      border-radius: 15px;
    }
  }
`;

export const LoginError = styled(Error)`
  && {
    font-size: 15px;
    margin-left: 45px;
    font-weight: 300;
    text-align: left;
    vertical-align: top;
    margin-top: 0px;
  }
`;

export const StyledLoginCircularProgress = styled(CircularProgress)`
  color: white;
`;

export const StyledLoginAnchorTag = styled.a`
  text-decoration: none;
  color: #1BBADE;
  border: 0px 10px;
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;


  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active 
`;

export const ResetPasswordHeader = styled(LoginHeader)`
  clear: both;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResetPasswordAnchorTag = styled(StyledLoginAnchorTag)`
  font-style: italic;
  font-size: 0.9rem;
`;

export const StyledCloseIcon = styled(Close)`
  display: inline-block;
  font-size: 1.8rem;
  color: #666;
  margin-top: -2px;
  min-height: 35px;
`;

export const StyledBackArrowIcon = styled(ArrowBackIos)`
  display: inline-block;
  font-size: 1.3rem;
  color: #666;
`;

export const LoginCheckCircleIcon = styled(CheckCircle)`
  font-size: 120px;
  padding: 10px;
`;

export const LoginLockIcon = styled(Lock)`
  font-size: 120px;
  padding: 10px;
`;

export const LoginButton = styled(Button)`
  margin-top: 20px;
`;

export const LinkedAuthButton = styled(Button)`
  align-items: left;
  margin-bottom: 15px;
  justify-content: left;
  && {
    height: 45px;
    font-size: 14px;
    letter-spacing: 1px;
    width: 240px;
  }
`;

export const FacebookLogo = styled(FacebookIcon)`
  height: 20px;
  width: 20px;
  display: inline-block;
  background: white;
  margin-left: 20px;
`;

export const GoogleLogo = styled(GoogleIcon)`
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 20px;
`;

export const LinkedAuthText = styled(Typography)`
  && {
    padding: 0px 0px 0px 15px;
    font-size: 1rem;
    letter-spacing: 0px;
    font-weight: 400;
  }
`;

export const StyledLoginHR = styled.hr`
  display: inline-block;
  width: 35%;
`;

export const HRLoginContainer = styled.div`
  width: 100%;
  display: flex;
  alignitems: center;
`;
