import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Link,
  LyricCountWrapper,
  StyledTextField,
} from "GlobalComponents";
import { UseDarkMode, UseIsOffline, UseCurrentUser } from "Hooks";
import {
  NewLyricControlButton,
  NoSetlistsText,
  MainAreaWrapper,
  NewSetlistDescriptiveText,
  ErrorText,
} from "../MySetlists/elements";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useQuery, useMutation } from "react-apollo";
import {
  Query_Get_All_Lyrics_Title_And_Author,
  Mutation_Create_New_Playlist,
} from "operations";
import {
  Get_All_Lyrics_Title_And_Author,
  Get_All_Lyrics_Title_And_Author_allLyrics,
  Create_New_Playlist,
  Create_New_PlaylistVariables,
} from "Types";
import { findNonPrivateLyrics, TsTempType } from "utilities";
import { DraggableLyricCard } from "../MySetlists/DraggableLyricCard";
import SaveIcon from "@material-ui/icons/Save";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { useHistory } from "react-router-dom";
import { LoadingIndicator } from "./LoadingScreen";

export const CreateNewSetlistForm: React.FC<{
  onCompleted?: (textField?: string) => void;
}> = ({ onCompleted }) => {
  const [textFieldText, setTextFieldText] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedLyrics, setSelectedLyrics] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [allLyrics, setAllLyrics] = useState<
    Get_All_Lyrics_Title_And_Author["allLyrics"]
  >([]);
  const history = useHistory();
  const { darkModeIsEnabled } = UseDarkMode();

  const { isOffline } = UseIsOffline();

  const currentUserDetails = UseCurrentUser();

  const { isLoggedIn, currentUser } = currentUserDetails;

  const { data, loading } = useQuery<Get_All_Lyrics_Title_And_Author>(
    Query_Get_All_Lyrics_Title_And_Author,
    { skip: isOffline }
  );

  const [
    createNewSetlist,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<Create_New_Playlist, Create_New_PlaylistVariables>(
    Mutation_Create_New_Playlist
  );

  const submitNewSetlist = async () => {
    setErrors([]);
    const selectedLyricsLength = Object.keys(selectedLyrics).length;
    if (!textFieldText.length || !selectedLyricsLength) {
      if (!textFieldText.length)
        setErrors(["Uh oh! It looks like you forgot to name your setlist!"]);
      if (!selectedLyricsLength)
        setErrors(allErrors => [
          ...allErrors,
          "Oh no! You need to add at least ONE lyric to your setlist!",
        ]);
    } else {
      currentUser &&
        selectedLyrics &&
        (await createNewSetlist({
          variables: {
            uid: currentUser.uid,
            playlistName: textFieldText,
            lyricList: Object.keys(selectedLyrics)?.map(id => ({
              lyricId: id,
            })) as TsTempType,
          },
        }));
      onCompleted && onCompleted(textFieldText);
      !onCompleted &&
        !mutationLoading &&
        !mutationError &&
        history.push("/my-setlists");
    }
  };

  useEffect(() => {
    if (data && data.allLyrics && !loading) {
      const nonPrivateData = findNonPrivateLyrics<Get_All_Lyrics_Title_And_Author_allLyrics>(
        data.allLyrics
      );

      if (nonPrivateData) {
        setAllLyrics(nonPrivateData);
      }
    }
  }, [data, loading]);

  if (!isLoggedIn) return <></>;

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <PageHeader variant="h4">New Setlist</PageHeader>
      <StyledTextField
        style={{ marginTop: "30px", width: "300px" }}
        label="Enter the setlist name here"
        name="setlist-name"
        variant="outlined"
        darkMode={darkModeIsEnabled}
        value={textFieldText}
        onChange={({ target: { value } }) => setTextFieldText(value)}
      />
      <NewSetlistDescriptiveText>
        Select the lyrics you'd like to add to your new setlist.
      </NewSetlistDescriptiveText>
      <Link to="/my-setlists">
        <NewLyricControlButton kind="primary">
          <ArrowBackIosIcon /> Back
        </NewLyricControlButton>
      </Link>
      <NewLyricControlButton
        kind="primary"
        onClick={() => {
          setSelectedLyrics([]);
          setClearAll(!clearAll);
        }}
      >
        Clear selection <ClearAllIcon />
      </NewLyricControlButton>
      <NewLyricControlButton kind="primary" onClick={submitNewSetlist}>
        Save <SaveIcon style={{ height: "20px" }} />
      </NewLyricControlButton>
      {errors &&
        errors.map(e => <ErrorText key={e.slice(0, 4)}>{e}</ErrorText>)}
      {isOffline ? (
        <NoSetlistsText
          style={{
            maxWidth: "400px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          It looks like you're offline! You won't be able to create setlists
          until you're back online, again.
        </NoSetlistsText>
      ) : (
        <MainAreaWrapper maxWidth="sm">
          <LyricCountWrapper darkMode={darkModeIsEnabled}>{`Lyrics selected: ${
            Object.keys(selectedLyrics).length
          }`}</LyricCountWrapper>
          <>
            {allLyrics?.map(({ title, author, id }) => (
              <DraggableLyricCard
                key={id}
                clearAll={clearAll}
                setSelectedLyrics={setSelectedLyrics}
                id={id}
                title={title}
                author={author}
              />
            ))}
          </>
        </MainAreaWrapper>
      )}
    </>
  );
};
