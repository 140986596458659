import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import { FaqDropdownMenus } from "FAQ";
import {
  ContactModal,
  GenerateHelpPageTourConfig,
  GuidedTour,
  Navbar,
  PageHeader,
  SiteMap,
  DefaultPageWrapper,
  Button,
} from "GlobalComponents";
import { UseCurrentUser, UseDarkMode } from "Hooks";
import { Mutation_Update_Has_Taken_Auto_Tour } from "operations";
import { useMutation } from "react-apollo";
import {
  Update_Has_Taken_Auto_Tour,
  Update_Has_Taken_Auto_TourVariables,
} from "Types";
import { ContactSectionWrapper } from "./elements";

export const HelpPage: React.FC = () => {
  const [runTourMutation, setRunTourMutation] = useState(false);
  const { darkModeIsEnabled } = UseDarkMode();
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

  const { currentUser } = UseCurrentUser();

  const [setHasTakenAutoTour] = useMutation<
    Update_Has_Taken_Auto_Tour,
    Update_Has_Taken_Auto_TourVariables
  >(Mutation_Update_Has_Taken_Auto_Tour);

  useEffect(() => {
    if (currentUser) {
      setHasTakenAutoTour({
        variables: { uid: currentUser.uid, hasTakenAutoTour: 2 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runTourMutation, currentUser, setRunTourMutation]);

  return (
    <>
      <Navbar />
      <ContactModal
        modalIsOpen={contactModalIsOpen}
        setModalIsOpen={setContactModalIsOpen}
      />
      <DefaultPageWrapper darkMode={darkModeIsEnabled} data-testid="help-page">
        <Container maxWidth="md" style={{ paddingBottom: "30px" }}>
          <PageHeader style={{ marginBottom: "30px" }}>FAQ</PageHeader>
          <ContactSectionWrapper onClick={() => setContactModalIsOpen(true)}>
            <Button
              kind="primary"
              variant="contained"
              data-tut="contact-step" //Used for the guided tour - Do not delete!
            >
              Contact us!{" "}
              <HelpIcon style={{ fontSize: "24px", marginLeft: "5px" }} />
            </Button>
          </ContactSectionWrapper>
          <FaqDropdownMenus />
        </Container>
        <SiteMap />
      </DefaultPageWrapper>
      {currentUser && (
        <GuidedTour
          tourSteps={GenerateHelpPageTourConfig({
            setRunTourMutation,
          })}
        />
      )}
    </>
  );
};
