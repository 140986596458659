import React, { useState, useEffect } from "react";
import { UseDarkMode, UseCurrentUser } from "Hooks";
import {
  Navbar,
  PageHeader,
  Link,
  SiteMap,
  Button,
  DefaultPageWrapper,
} from "GlobalComponents";
import { NoSetlistsText } from "./elements";
import AddIcon from "@material-ui/icons/Add";
import { Get_Current_User_getCurrentUser } from "Types";
import { SetlistCard } from "./SetlistCard";
import Container from "@material-ui/core/Container";

export const MySetlists: React.FC = () => {
  const [setLists, setSetLists] = useState<
    Get_Current_User_getCurrentUser["playlists"]
  >([]);
  const { darkModeIsEnabled } = UseDarkMode();
  const currentUserDetails = UseCurrentUser();

  window.localStorage.removeItem("setlist");

  const { isLoggedIn, currentUser } = currentUserDetails;

  useEffect(() => {
    if (currentUser?.setlists) setSetLists(currentUser?.setlists);
  }, [currentUser]);

  return (
    <>
      <Navbar {...currentUserDetails} />
      <DefaultPageWrapper topPaddingFalse darkMode={darkModeIsEnabled}>
        <PageHeader variant="h4">My Setlists</PageHeader>
        <Link to="/new-setlist">
          <Button
            style={{ marginTop: "35px", marginBottom: "20px" }}
            kind="primary"
          >
            New Setlist <AddIcon />
          </Button>
        </Link>
        {isLoggedIn ? (
          <>
            {setLists && setLists.length ? (
              <Container maxWidth="sm">
                {setLists.map(({ ...props }) => (
                  <SetlistCard key={props.id} {...props} />
                ))}
              </Container>
            ) : (
              <NoSetlistsText>
                It looks like you haven't created any setlists yet.
              </NoSetlistsText>
            )}
          </>
        ) : (
          <NoSetlistsText>
            It looks like you're logged out! You must be logged in to create
            setlists
          </NoSetlistsText>
        )}
        <SiteMap />
      </DefaultPageWrapper>
    </>
  );
};
