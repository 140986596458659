import React, { useEffect, useState } from "react";
import {
  MenuTextWrapper,
  MoreMenuItem,
  StyledMoreMenuPopover,
  StyledSelect,
  SpeedControlText,
  SpeedControlFab,
} from "./elements";
import EditIcon from "@material-ui/icons/Edit";
import SpeedIcon from "@material-ui/icons/Speed";
import { ExportIcon } from "Icons";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "GlobalComponents";
import { Find_Lyric_With_Short_Url_findLyricWithShortUrl } from "Types";
import { jsPDF } from "jspdf";
import MinusIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { TsTempType } from "utilities";

interface Props {
  moreMenuAnchorEl: React.MouseEvent["currentTarget"] | null;
  setMoreMenuAnchorEl: React.Dispatch<React.MouseEvent["currentTarget"] | null>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<boolean>;
  lyricData: Find_Lyric_With_Short_Url_findLyricWithShortUrl;
  canEdit: boolean;
  isSetlist: boolean;
  openSpeedMenu: (event: React.MouseEvent) => void;
  decreaseTime: TsTempType;
  increaseTime: TsTempType;
  timeoutDuration: number;
}

export const MoreMenuPopover: React.FC<Props> = ({
  moreMenuAnchorEl,
  setMoreMenuAnchorEl,
  isEditMode,
  setIsEditMode,
  lyricData,
  canEdit,
  isSetlist,
  openSpeedMenu,
  decreaseTime,
  increaseTime,
  timeoutDuration,
}) => {
  const [fileTypeSelect, setFileTypeSelect] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const moreMenuIsOpen = Boolean(moreMenuAnchorEl);

  const makeTextFile = () => {
    const data = new Blob(
      [
        `${lyricData.title}\n${lyricData.author}\n${lyricData.verses
          .split("(chorus)")
          .join(`\n\n${lyricData.chorus}\n\n`)}`,
      ],
      { type: "text/plain" }
    );

    // Cleanup memory leaks
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    // update the download link state
    setDownloadLink(window.URL.createObjectURL(data));
  };

  const handlePdfCreation = () => {
    const document = new jsPDF();

    document.setFontSize(12);

    const generateNewPDFDoc = () => {
      let yCoordinate = 20;
      const pageHeight = document.internal.pageSize.height;

      document.text(`${lyricData.title}\n${lyricData.author}`, 10, 10);

      const buildDocumentData = (stringData: string) => {
        stringData.split("\n").forEach(verse => {
          yCoordinate += 6;

          // Handle adding another page when current page is full
          if (yCoordinate + 10 >= pageHeight) {
            document.addPage();
            yCoordinate = 15;
          }

          if (verse !== "(chorus)") {
            document.text(verse, 10, yCoordinate);
            return;
          } else {
            return buildDocumentData(lyricData.chorus);
          }
        });
      };

      buildDocumentData(lyricData.verses);
    };

    const savePDFDoc = () => document.save(`${lyricData.title}.pdf`);

    return { generateNewPDFDoc, savePDFDoc };
  };

  const { savePDFDoc, generateNewPDFDoc } = handlePdfCreation();

  useEffect(() => {
    if (fileTypeSelect === ".txt") makeTextFile();
    if (fileTypeSelect === ".pdf") generateNewPDFDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileTypeSelect, lyricData]);

  const ButtonWrapper: React.FC = ({ children }) =>
    fileTypeSelect === ".txt" ? (
      <a
        // this attribute sets the filename
        download={`${lyricData.title}${fileTypeSelect}`}
        // link to the download URL
        href={downloadLink}
        style={{ textDecoration: "none" }}
      >
        {children}
      </a>
    ) : (
      <>{children}</>
    );

  return (
    <StyledMoreMenuPopover
      anchorEl={moreMenuAnchorEl}
      onClose={() => setMoreMenuAnchorEl(null)}
      open={moreMenuIsOpen}
    >
      {!isEditMode && canEdit && (
        <MoreMenuItem
          onClick={() => {
            setIsEditMode(true);
            setMoreMenuAnchorEl(null);
          }}
        >
          <MenuTextWrapper>
            <EditIcon
              style={{
                position: "relative",
                bottom: "2px",
                marginRight: "8px",
                marginLeft: "2px",
              }}
            />
            Edit
          </MenuTextWrapper>
        </MoreMenuItem>
      )}
      {isSetlist && (
        <MoreMenuItem disableRipple>
          <MenuTextWrapper
            onClick={openSpeedMenu}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <SpeedIcon />
            <span style={{ marginRight: "20px" }}>{"Speed:"}</span>
            <SpeedControlFab onClick={() => decreaseTime()}>
              <MinusIcon />
            </SpeedControlFab>
            <SpeedControlText>{timeoutDuration}</SpeedControlText>
            <SpeedControlFab onClick={() => increaseTime()}>
              <AddIcon />
            </SpeedControlFab>
          </MenuTextWrapper>
        </MoreMenuItem>
      )}
      <MoreMenuItem disableRipple border={!isEditMode}>
        <MenuTextWrapper style={{ paddingTop: "10px" }}>
          {fileTypeSelect === "" && !downloadLink ? (
            <>
              <ExportIcon
                style={{
                  marginRight: "8px",
                  marginLeft: "2px",
                  position: "relative",
                  bottom: "4px",
                }}
              />
              {"Export As..."}
            </>
          ) : (
            <ButtonWrapper>
              <Button
                onClick={() => (fileTypeSelect === ".pdf" ? savePDFDoc() : "")}
                style={{ height: "35px", bottom: "6px" }}
                kind="primary"
              >
                <ExportIcon
                  style={{
                    marginRight: "8px",
                    marginLeft: "2px",
                    position: "relative",
                    bottom: "0px",
                    fill: "white",
                  }}
                />
                Export
              </Button>
            </ButtonWrapper>
          )}
          <StyledSelect
            disableUnderline
            value={fileTypeSelect}
            onChange={event => {
              setFileTypeSelect(event.target.value as string);
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              File Type
            </MenuItem>
            <MenuItem value=".txt">.txt</MenuItem>
            <MenuItem value=".pdf">.pdf</MenuItem>
          </StyledSelect>
        </MenuTextWrapper>
      </MoreMenuItem>
    </StyledMoreMenuPopover>
  );
};
