import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "GlobalComponents";
import { FogGrey } from "ColorVars";
import {
  UseCurrentUserReturnShape,
  UseCurrentUser,
  UseResponsiveCheck,
} from "Hooks";
import { NavMainText } from "./elements";
import { Link, LoginCreateAccountModal } from "GlobalComponents";
import { MainLogo } from "Icons";
import { StyledMenuIconButton } from "../elements";
import { DesktopNavbarItems } from "./DesktopNavbarItems";
import { UserMenu } from "./UserMenu";
import { MobileNavbarMenu } from "./MobileNavbarMenu";
import posthog from "posthog-js";
import { useLazyQuery } from "react-apollo";
import {
  Find_Lyric_Id_With_Short_Url,
  Find_Lyric_Id_With_Short_UrlVariables,
} from "Types";
import { Query_Find_Lyric_Id_With_Short_Url } from "operations";

export const Navbar: React.FC<Partial<UseCurrentUserReturnShape>> = props => {
  // Initialize PostHog
  if (!window.location.href.includes("localhost")) {
    posthog.init("phc_hqFfAki0ICb2w89jiY4i1WOaPqBMZ82beL02CCXptzg", {
      api_host: "https://app.posthog.com",
    });
  }

  // A hacky way of waking up the Heroku server as soon as a user visits the homepage
  // @TODO: Find a better way of keeping the server from sleeping
  const [serverAwakenQuery] = useLazyQuery<
    Find_Lyric_Id_With_Short_Url,
    Find_Lyric_Id_With_Short_UrlVariables
  >(Query_Find_Lyric_Id_With_Short_Url);

  useEffect(() => {
    const lastServerAwakenCall = window.localStorage.getItem(
      "lastServerAwakenCall"
    );
    const timeSinceLastAwakeCall = lastServerAwakenCall
      ? Date.now() - JSON.parse(lastServerAwakenCall)
      : 0;
    const twentyFiveMinutes = 1500000;

    if (
      timeSinceLastAwakeCall === 0 ||
      timeSinceLastAwakeCall > twentyFiveMinutes
    ) {
      serverAwakenQuery({ variables: { shortUrl: "" } });
      window.localStorage.setItem(
        "lastServerAwakenCall",
        JSON.stringify(Date.now())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

  const currentUserHookData = UseCurrentUser();

  const { isMobile } = UseResponsiveCheck();

  const getCurrentUser = () => {
    if (props.logout && props.setUser) {
      const {
        currentUser,
        currentUserIsLoading,
        isLoggedIn,
        logout,
        setUser,
      } = props;
      return {
        currentUser: currentUser || null,
        currentUserIsLoading: currentUserIsLoading || false,
        isLoggedIn: isLoggedIn || false,
        logout,
        setUser,
      };
    } else {
      return { ...currentUserHookData };
    }
  };

  const {
    currentUserIsLoading,
    setUser,
    isLoggedIn,
    currentUser,
    logout,
  } = getCurrentUser();

  const mainTextTopVal = isLoggedIn ? "16px" : "11px";

  return (
    <>
      <LoginCreateAccountModal
        currentUserIsLoading={currentUserIsLoading}
        isOpen={loginModalIsOpen}
        setIsOpen={setLoginModalIsOpen}
        setUser={setUser}
        logout={logout}
      />
      <AppBar position="static">
        <Toolbar
          style={{
            backgroundColor: FogGrey,
            paddingRight: isMobile ? "4px" : "",
            paddingLeft: isMobile ? "6px" : "",
          }}
        >
          {isMobile && <MobileNavbarMenu />}
          <Link
            data-testid="navbar-home-link-logo"
            to="/"
            style={{ color: "white" }}
          >
            <StyledMenuIconButton>
              <MainLogo style={{ height: "30px", width: "30px" }} />
            </StyledMenuIconButton>
            <NavMainText
              data-tut="home-button" //Used for the guided tour - Do not delete!
              style={{
                left: isMobile ? "90px" : "",
                top: isMobile ? mainTextTopVal : "",
                fontWeight: 400,
              }}
              variant="h6"
              data-testid="navbar-title-link"
            >
              Lyric Keeper
            </NavMainText>
          </Link>
          {!isMobile && <DesktopNavbarItems />}
          {isLoggedIn && currentUser ? (
            <UserMenu logout={logout} currentUser={currentUser} />
          ) : (
            <Button
              kind="primary"
              size="small"
              style={{
                textAlign: "right",
                marginLeft: isMobile ? "auto" : "0px",
                fontSize: "0.9rem",
                right: "-8px",
                height: "35px",
              }}
              data-testid="navbar-login-create-account-button"
              onClick={() => setLoginModalIsOpen(true)}
            >
              Login / Sign Up
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
