import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SecondaryGreen } from "ColorVars";
import Typography from "@material-ui/core/Typography";
import { AdaptiveTextField } from "./elements";
import { UseDarkMode } from "Hooks";
import { Button } from "GlobalComponents";

const Error = styled.div`
  font-size: 15px;
  color: red;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: sans-serif;
  letter-spacing: 1.2px;
`;

const Header = styled(Typography)`
  && {
    color: ${SecondaryGreen};
    font-size: 1.8rem;
    margin-bottom: 40px;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 100;
  }
`;

const SuccessMessage = styled.div`
  && {
    font-size: 1.2rem;
    margin-top: 20px;
    color: green;
    font-family: sans-serif;
    letter-spacing: 1.2px;
  }
`;

export const ContactForm: React.FC = () => {
  const [status, setStatus] = useState({ code: 200, message: "" });
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("You must enter an email address"),
    full_name: Yup.string().required("You must enter your name"),
    questions: Yup.string().required("You must enter a question or suggestion"),
  });

  const {
    handleChange,
    handleBlur,
    values: { full_name, email, questions },
    errors,
    touched,
    setTouched,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      questions: "",
    },
    validationSchema,
    onSubmit: () => undefined,
  });

  const submitQuestion = () => {
    axios
      .post("https://formspree.io/myynrayb", {
        full_name,
        email,
        questions,
      })
      .then(r => {
        if (r.status.toString().includes("200"))
          setStatus({
            code: 200,
            message:
              "Your message was sent! You should hear back from us within 1-2 business days.",
          });
        setLoading(false);
      })
      .catch(error => {
        if (`${error}`.includes("400"))
          setStatus({
            code: 404,
            message:
              "There was an error! Please ensure all the fields are filled above. If all the fields have been filled in, please wait a minute before trying again.",
          });
        setLoading(false);
        console.log(error);
      });
  };

  const { darkModeIsEnabled } = UseDarkMode();

  return (
    <>
      <Header>Ask us a question</Header>
      <AdaptiveTextField
        data-testid="contact-us-modal-fullname-field"
        darkMode={darkModeIsEnabled}
        onBlur={handleBlur}
        style={{ width: "inherit" }}
        onChange={handleChange}
        value={full_name}
        name="full_name"
        label="Full Name"
        variant="outlined"
      />
      {errors.full_name && touched.full_name && (
        <Error>{errors.full_name}</Error>
      )}
      <AdaptiveTextField
        data-testid="contact-us-modal-email-field"
        darkMode={darkModeIsEnabled}
        onBlur={handleBlur}
        style={{ width: "inherit", marginTop: "20px" }}
        name="email"
        id="email"
        onChange={handleChange}
        value={email}
        label="Email Address"
        variant="outlined"
      />
      {errors.email && touched.email && <Error>{errors.email}</Error>}
      <AdaptiveTextField
        data-testid="contact-us-modal-comment-field"
        darkMode={darkModeIsEnabled}
        onBlur={handleBlur}
        style={{ width: "inherit", marginTop: "20px" }}
        name="questions"
        onChange={handleChange}
        value={questions}
        label="Questions or suggestions"
        variant="outlined"
        multiline
        rows={4}
      />
      {errors.questions && touched.questions && (
        <Error>{errors.questions}</Error>
      )}
      <Button
        style={{ marginTop: "40px" }}
        kind="primary"
        onClick={() => {
          setTouched({ email: true, full_name: true, questions: true });
          if (!errors.email && !errors.full_name && !errors.questions) {
            setLoading(true);
            submitQuestion();
          }
        }}
        data-testid="contact-us-modal-submit-button"
      >
        {!loading ? (
          "Submit"
        ) : (
          <CircularProgress style={{ color: "white" }} size={25} />
        )}
      </Button>
      {status.code === 404 ? (
        <Error>{status.message}</Error>
      ) : (
        <SuccessMessage>{status.message}</SuccessMessage>
      )}
    </>
  );
};
