import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { SecondaryColor } from "ColorVars";
import { Button } from "GlobalComponents";

const TextBlockContainer = styled(Paper)`
  && {
    background-color: white;
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 0px 8px 1px #0000008f;
    margin-top: 40px;
    cursor: pointer;
    padding-bottom: 26px;
  }
`;

const BlockText = styled(Typography)<{ kind?: "verse" | "chorus" }>`
  && {
    white-space: pre-wrap;
    margin: auto;
    text-align: left;
    width: fit-content;
    max-width: 90%;
    ${({ kind = "verse" }) => {
      if (kind === "verse") return `color: black`;
      if (kind === "chorus") return `color: ${SecondaryColor}`;
    }}
  }
`;

interface TextBlockProps {
  deleteBlock(arg1: number): void;
  blockString: string;
  blockIndex: number;
  editBool: boolean;
  isChorus?: boolean;
  editBlockText(arg1: string, index: number): void;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  deleteBlock,
  blockString,
  blockIndex,
  editBool,
  isChorus = false,
  editBlockText,
}) => {
  const [editMode, setEditMode] = useState(!editBool && !isChorus);
  const [text, setText] = useState(blockString);

  useEffect(() => {
    setText(blockString);
  }, [blockString]);

  const saveChanges = () => {
    setEditMode(false);
    editBlockText(text, blockIndex);
  };

  return (
    <TextBlockContainer>
      <div style={{ display: "block", marginBottom: "20px" }}>
        <Fab size="small" onClick={() => deleteBlock(blockIndex)}>
          <DeleteIcon />
        </Fab>
        {!editMode && !isChorus && (
          <Fab
            style={{ marginLeft: "20px" }}
            size="small"
            onClick={() => setEditMode(true)}
          >
            <EditIcon />
          </Fab>
        )}
      </div>
      {editMode && text.length > 400 && (
        <Button
          size="small"
          kind="light"
          style={{ margin: "9px auto auto", display: "flex" }}
          onClick={() => saveChanges()}
        >
          Done
        </Button>
      )}
      {editMode ? (
        <TextField
          required
          multiline
          rowsMax={100}
          rows={1}
          style={{ width: "100%" }}
          margin="dense"
          // label={label}
          value={text}
          onChange={({ target: { value } }) => setText(value)}
          // placeholder={helpText}
          variant="outlined"
        />
      ) : (
        <BlockText kind={isChorus ? "chorus" : "verse"}>{text}</BlockText>
      )}
      {editMode && (
        <Button
          size="small"
          kind="light"
          style={{ margin: "9px auto auto", display: "flex" }}
          onClick={() => saveChanges()}
        >
          Done
        </Button>
      )}
    </TextBlockContainer>
  );
};
