import React from "react";
import { LyricCard } from "LyricCard";
import { Get_All_List_Lyrics, Get_All_List_Lyrics_allLyrics } from "Types";
import {
  LoadingScreen,
  LyricCountWrapper,
  DefaultPageWrapper,
  Navbar,
  PageHeader,
  SearchEngine,
  NoLyricsToDisplayText,
  SearchAreaWrapper,
  AutocompleteSearch,
  SiteMap,
} from "GlobalComponents";
import { UseDarkMode, UseCurrentUser } from "Hooks";
import { Query_Get_All_List_Lyrics } from "operations";
import { SecondaryColor, DarkModeLighterPurple } from "ColorVars";
import { TsTempType } from "utilities";

export const LibraryPage: React.FC<TsTempType> = ({ client }) => {
  const { darkModeIsEnabled } = UseDarkMode();

  const { currentUser } = UseCurrentUser();

  const { loading, filter, filterBy, handleChange, lyricData } = SearchEngine<
    Get_All_List_Lyrics,
    Get_All_List_Lyrics_allLyrics
  >({
    client,
    query: Query_Get_All_List_Lyrics,
    returnNonPrivateLyrics: true,
    fieldName: "allLyrics",
  });

  return (
    <>
      <Navbar />
      <DefaultPageWrapper
        darkMode={darkModeIsEnabled}
        data-testid="library-page"
      >
        <PageHeader>Library</PageHeader>
        <PageHeader
          style={{
            fontSize: "1.2rem",
            color: darkModeIsEnabled ? DarkModeLighterPurple : SecondaryColor,
            maxWidth: "300px",
            textAlign: "center",
            margin: "0px auto 60px",
          }}
        >
          You can help build this library by creating lyrics that are not
          private. Thank you so much for your contributions!
        </PageHeader>
        <SearchAreaWrapper maxWidth="sm">
          {loading ? (
            <LoadingScreen topSpacing darkMode={darkModeIsEnabled} />
          ) : (
            <>
              <AutocompleteSearch
                lyricInputData={lyricData}
                filterBy={filterBy}
                filter={filter}
                handleChange={handleChange}
              />

              <LyricCountWrapper
                data-testid="library-lyric-count"
                style={{ marginTop: "30px" }}
                darkMode={darkModeIsEnabled}
              >{`Lyrics: ${lyricData?.length}`}</LyricCountWrapper>
              {lyricData && lyricData?.length ? (
                lyricData?.map(({ ...props }) => (
                  <LyricCard
                    key={props.id}
                    currentUser={currentUser}
                    darkModeIsEnabled={darkModeIsEnabled}
                    {...props}
                  />
                ))
              ) : (
                <NoLyricsToDisplayText darkMode={darkModeIsEnabled}>
                  No Lyrics to display...
                </NoLyricsToDisplayText>
              )}
            </>
          )}
        </SearchAreaWrapper>
        <SiteMap />
      </DefaultPageWrapper>
    </>
  );
};
