import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  LoginButton,
  LoginError,
  ResetPasswordHeader,
  ResetPasswordText,
  ResetPasswordTextLight,
  StyledLoginField,
  StyledLoginCircularProgress,
  LoginLockIcon,
  LoginCheckCircleIcon,
  BackAndExitComponent,
} from "LoginComponents";
import { modalKinds, Email } from "GlobalComponents/LoginCreateAccountModal";
import { TsTempType } from "utilities";

interface Props {
  setModalIsOpen: React.Dispatch<boolean>;
  setModalType: React.Dispatch<modalKinds>;
  authIsLoading: boolean;
  resetPassword: ({ email, setError }: Email) => void;
  modalType: modalKinds;
}

export const ResetPasswordModal: React.FC<Props> = ({
  setModalIsOpen,
  setModalType,
  authIsLoading,
  resetPassword,
  modalType,
}) => {
  const [authError, setAuthError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("You must enter an email address"),
  });

  const {
    handleChange,
    values: { email },
    errors,
    validateForm,
    handleBlur,
    touched,
    setTouched,
  } = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const emailError: TsTempType = errors.email && touched.email && errors.email;

  const errorText: TsTempType =
    authError === "auth/user-not-found"
      ? "Email could not be found"
      : authError === "auth/invalid-email"
      ? "The email address is badly formatted."
      : emailError;

  const titleText: string = !isSubmitted ? "Forgot Password?" : "Email Sent!";

  const firstText: string = !isSubmitted
    ? "Enter the email address associated with your account."
    : "Your password reset link has been sent to:";

  const secondText: string = !isSubmitted
    ? "We will email you a link to reset your password."
    : "Please allow up to 5 minutes for the email to arrive. Also check your spam folder.";

  const buttonText: TsTempType = authIsLoading ? (
    <StyledLoginCircularProgress size={25} />
  ) : !isSubmitted ? (
    "Send"
  ) : (
    "Resend"
  );

  const handleSubmit = () => {
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.email) {
        resetPassword({
          email,
          setError: setAuthError,
          callback: setIsSubmitted,
        });
      } else {
        setTouched({ email: true });
      }
    })();
  };

  return (
    <>
      <BackAndExitComponent
        setModalType={setModalType}
        modalType={modalType}
        setModalIsOpen={setModalIsOpen}
      />
      <ResetPasswordHeader>{titleText}</ResetPasswordHeader>
      {isSubmitted ? <LoginCheckCircleIcon /> : <LoginLockIcon />}
      <ResetPasswordText>{firstText}</ResetPasswordText>
      {isSubmitted ? (
        <ResetPasswordText style={{ fontWeight: "bold" }}>
          {email}
        </ResetPasswordText>
      ) : null}
      <ResetPasswordTextLight>{secondText}</ResetPasswordTextLight>
      <form
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
      >
        {isSubmitted ? (
          <ResetPasswordTextLight>Didn't receive it?</ResetPasswordTextLight>
        ) : (
          <>
            <StyledLoginField
              data-testid="login-modal-email-field"
              type="email"
              onBlur={handleBlur}
              label="Email"
              name="email"
              value={email}
              variant="outlined"
              onChange={handleChange}
            />
            <LoginError>{errorText}</LoginError>
          </>
        )}
        <LoginButton
          kind="primary"
          size="large"
          onClick={handleSubmit}
          variant="contained"
          data-testid="login-modal-login-button"
        >
          {buttonText}
        </LoginButton>
      </form>
    </>
  );
};
