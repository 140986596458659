import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  LoginButton,
  LoginError,
  StyledLoginField,
  LoginText,
  StyledLoginCircularProgress,
  BackAndExitComponent,
  LoginTitle,
  LinkedButtonsContainer,
} from "LoginComponents";
import {
  ProviderType,
  EmailAndPassword,
} from "GlobalComponents/LoginCreateAccountModal";
import { TsTempType } from "utilities";
import { modalKinds } from "GlobalComponents";

interface Props {
  setModalIsOpen: React.Dispatch<boolean>;
  authIsLoading: boolean;
  createNewUser: ({ email, password, setError }: EmailAndPassword) => void;
  modalType: modalKinds;
  setModalType: React.Dispatch<modalKinds>;
  signInWithProviderPt1: ({ provider, setError }: ProviderType) => void;
}

export const CreateAccountModal: React.FC<Props> = ({
  setModalIsOpen,
  setModalType,
  authIsLoading,
  createNewUser,
  modalType,
  signInWithProviderPt1,
}) => {
  const [authError, setAuthError] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("You must enter an email address"),
    password: Yup.string()
      .required("You must enter a password")
      .min(8, "Password must be at least 8 characters long"),
    passwordConfirmation: Yup.string()
      .required("You must confirm your password")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const {
    handleChange,
    values: { email, password, passwordConfirmation },
    errors,
    validateForm,
    handleBlur,
    touched,
    setTouched,
  } = useFormik({
    initialValues: { email: "", password: "", passwordConfirmation: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const emailError: TsTempType = errors.email && touched.email && errors.email;
  const passwordError: TsTempType =
    errors.password && touched.password && errors.password;
  const passwordConfirmationError: TsTempType =
    errors.passwordConfirmation &&
    touched.passwordConfirmation &&
    errors.passwordConfirmation;

  const authErrorText: TsTempType =
    authError === "auth/account-exists-with-different-credential"
      ? "Account with email already exists. Please link accounts"
      : authError;

  const errorText: TsTempType = authErrorText || passwordConfirmationError;

  const buttonText: TsTempType = !authIsLoading ? (
    "Sign Up"
  ) : (
    <StyledLoginCircularProgress size={25} />
  );

  const handleSubmit = () => {
    (async () => {
      const formErrors = await validateForm();
      if (
        !formErrors.email &&
        !formErrors.password &&
        !formErrors.passwordConfirmation
      ) {
        createNewUser({ email, password, setError: setAuthError });
      } else {
        setTouched({
          email: true,
          password: true,
          passwordConfirmation: true,
        });
      }
    })();
  };

  return (
    <>
      <BackAndExitComponent
        setModalType={setModalType}
        modalType={modalType}
        setModalIsOpen={setModalIsOpen}
      />
      <LoginTitle />
      <LoginText>Sign up to create and organize your lyrics</LoginText>
      <LinkedButtonsContainer
        signInWithProviderPt1={signInWithProviderPt1}
        setAuthError={setAuthError}
      />
      <form
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
      >
        <StyledLoginField
          data-testid="create-account-modal-email-field"
          type="email"
          onBlur={handleBlur}
          label="Email"
          name="email"
          value={email}
          variant="outlined"
          onChange={handleChange}
        />
        <LoginError>{emailError}</LoginError>
        <StyledLoginField
          data-testid="create-account-modal-password-field"
          type="password"
          onBlur={handleBlur}
          label="Password"
          name="password"
          value={password}
          variant="outlined"
          onChange={handleChange}
        />
        <LoginError>{passwordError}</LoginError>
        <StyledLoginField
          data-testid="create-account-modal-password-confirmation-field"
          type="password"
          onBlur={handleBlur}
          label="Confirm Password"
          name="passwordConfirmation"
          value={passwordConfirmation}
          variant="outlined"
          onChange={handleChange}
        />
        <LoginError>{errorText}</LoginError>
        <LoginButton
          kind="primary"
          size="large"
          onClick={handleSubmit}
          variant="contained"
          data-testid="create-account-modal-submit-button"
        >
          {buttonText}
        </LoginButton>
      </form>
    </>
  );
};
