import React, { useState } from "react";
import { StyledMenuIconButton } from "GlobalComponents/elements";
import MenuIcon from "@material-ui/icons/Menu";
import Modal from "@material-ui/core/Modal";
import { MobileNavButton, NavInnerModalWrapper } from "./elements";
import HomeIcon from "@material-ui/icons/Home";
import AboutIcon from "@material-ui/icons/PeopleOutlineOutlined";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import ContactIcon from "@material-ui/icons/ForumOutlined";
import { Link } from "GlobalComponents";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import { MainGreen } from "ColorVars";
import LibraryIcon from "@material-ui/icons/LibraryMusicOutlined";

export const MobileNavbarMenu: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isDarkMode = true;

  const navButtons = [
    {
      title: "Home",
      link: "/",
      icon: () => (
        <HomeIcon style={{ position: "absolute", left: "-24px", top: "9px" }} />
      ),
    },
    {
      title: "Library",
      link: "/library",
      icon: () => (
        <LibraryIcon
          style={{ position: "absolute", left: "-24px", top: "9px" }}
        />
      ),
    },
    {
      title: "About",
      link: "/about",
      icon: () => (
        <AboutIcon
          style={{ position: "absolute", left: "-24px", top: "9px" }}
        />
      ),
    },
    {
      title: "Help",
      link: "/help",
      icon: () => (
        <HelpIcon style={{ position: "absolute", left: "-24px", top: "9px" }} />
      ),
    },
    {
      title: "Contact",
      link: "/contact-us",
      icon: () => (
        <ContactIcon
          style={{ position: "absolute", left: "-24px", top: "9px" }}
        />
      ),
    },
  ];

  return (
    <>
      <Modal
        disableAutoFocus
        hideBackdrop
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <NavInnerModalWrapper isDarkMode={isDarkMode}>
          {navButtons.map(({ title, link, icon }) => (
            <>
              <Link to={link}>
                <MobileNavButton isDarkMode={isDarkMode}>
                  {icon()}
                  {title}
                </MobileNavButton>
              </Link>
              <div
                style={{
                  borderBottom: "solid white 2px",
                  width: "82%",
                  margin: "auto",
                  textAlign: "center",
                }}
              />
            </>
          ))}
          <Fab
            size="small"
            style={{
              backgroundColor: MainGreen,
              marginLeft: "44%",
              marginTop: "60px",
            }}
            onClick={() => setModalIsOpen(false)}
          >
            <CloseIcon style={{ color: "white", fontSize: "2rem" }} />
          </Fab>
        </NavInnerModalWrapper>
      </Modal>
      <StyledMenuIconButton
        onClick={() => setModalIsOpen(true)}
        edge="start"
        color="inherit"
        aria-label="menu"
        data-testid="navbar-menu-button"
      >
        <MenuIcon />
      </StyledMenuIconButton>
    </>
  );
};
