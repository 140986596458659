import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const CheckboxContainer = styled.div`
  &&& {
    position: fixed;
    left: 0;
    padding-left: 20px;
    top: 90%;
    width: 95%;
    background-color: #fff;
    border-radius: 12px;
  }
`;

export const CheckboxType = styled(Typography)`
  && {
    font-family: "Raleway", sans-serif;
  }
`;
