import React from "react";
import { BackAndExitButtonWrapper, StyledCloseIcon } from "LoginComponents";

interface Props {
  setModalIsOpen: React.Dispatch<boolean>;
}

export const ExitButton: React.FC<Props> = ({ setModalIsOpen }) => {
  return (
    <BackAndExitButtonWrapper style={{ float: "right" }}>
      <StyledCloseIcon
        onClick={() => {
          setModalIsOpen(false);
        }}
      />
    </BackAndExitButtonWrapper>
  );
};
