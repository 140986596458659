import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import firebase, { firebaseApp } from "firebaseApp";
import { useMutation } from "react-apollo";
import { Mutation_Create_New_User } from "operations";
import {
  Create_New_User,
  Create_New_UserVariables,
} from "Types/__types__/Create_New_User";
import { TsTempType } from "utilities";
import {
  LoginModal,
  CreateAccountModal,
  ResetPasswordModal,
  SmallLoginModal,
  StyledLoginContainer,
  StyledSmallLoginContainer,
} from "LoginComponents";
import { LinkedAuthModal } from "LoginComponents/LinkedAuthModal";

export type modalKinds = "login" | "create-account" | "reset-password";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  setUser: React.Dispatch<TsTempType>;
  currentUserIsLoading: boolean;
  modalKind?: modalKinds;
  logout: () => void;
}

export interface ProviderType {
  provider: string;
  setError: React.Dispatch<TsTempType>;
}

export interface EmailAndPassword {
  email: string;
  password: string;
  setError: React.Dispatch<TsTempType>;
}

export interface Email {
  email: string;
  setError: React.Dispatch<TsTempType>;
  callback: React.Dispatch<boolean>;
}

interface FirebaseError extends Error {
  a: unknown;
  code: string;
  message: string;
}

interface FirebaseUser {
  [key: string]: any;
}

// Workaround to set the default state of the credentials
const emptyCredentials = {
  idToken: "",
  accessToken: "",
  secret: "",
  providerId: "",
  signInMethod: "",
  toJSON: () => {
    return {};
  },
};

export const LoginCreateAccountModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  setUser,
  currentUserIsLoading,
  modalKind = "create-account",
  logout,
}) => {
  const [modalType, setModalType] = useState<modalKinds>(modalKind);

  const [authIsLoading, setAuthIsLoading] = useState(false);

  const [authLinkingModalIsOpen, setAuthLinkingModalIsOpen] = useState(false);
  const [authLoginPassword, setAuthLoginPassword] = useState("");
  const [authLoginEmail, setAuthLoginEmail] = useState("");
  const [
    pendingCredential,
    setPendingCredential,
  ] = useState<firebase.auth.OAuthCredential>(emptyCredentials);
  const [userCreationFailed, setUserCreationFailed] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>({});

  const isLoginType = modalType === "login";
  const isCreateAccountType = modalType === "create-account";

  const [createNewUserMutation] = useMutation<
    Create_New_User,
    Create_New_UserVariables
  >(Mutation_Create_New_User, {
    errorPolicy: "all",
    onError: () => {
      setUserCreationFailed(true);
    },
  });

  const errorHandler = (
    error: FirebaseError,
    setError: React.Dispatch<TsTempType>
  ) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
    setError(errorCode);
    setAuthIsLoading(false);
  };

  // \\\\\\ SIGN IN WITH THIRD PARTY AUTH //////

  const signInWithProviderPt1 = async ({
    provider,
    setError,
  }: ProviderType) => {
    const loginProvider =
      provider === "facebook"
        ? new firebase.auth.FacebookAuthProvider()
        : provider === "google"
        ? new firebase.auth.GoogleAuthProvider()
        : null;

    if (!loginProvider) return;

    loginProvider.addScope("email");

    let providerSignIn;
    try {
      //try to sign in with provider
      providerSignIn = await firebaseApp.auth().signInWithPopup(loginProvider);
      if (providerSignIn && providerSignIn.user) {
        setAuthIsLoading(false);
        setUser({
          uid: providerSignIn.user.uid,
          email: providerSignIn.user.email,
        });
        setIsOpen(false);
      }
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        setAuthLoginEmail(error.email);
        setPendingCredential(error.credential);
        let emailSignIn;
        let oldProviderLogIn;
        try {
          emailSignIn = await firebaseApp
            .auth()
            .fetchSignInMethodsForEmail(error.email);
          if (emailSignIn[0] === "password") {
            setAuthLinkingModalIsOpen(true);
          } else {
            //DIFFERENT PROVIDER HANDLE (right now, only relevant if they signed up with google and try to link facebook)
            try {
              const oldLoginProvider =
                provider === "google"
                  ? new firebase.auth.FacebookAuthProvider()
                  : new firebase.auth.GoogleAuthProvider();
              oldProviderLogIn = await firebaseApp
                .auth()
                .signInWithPopup(oldLoginProvider);
              oldProviderLogIn.user?.linkWithCredential(error.credential);
            } catch (e) {
              errorHandler(e, setError);
            }
          }
        } catch (err) {
          errorHandler(err, setError);
        }
      } else {
        errorHandler(error, setError);
      }
    }
  };

  //This function is for after the password has been entered to finish the Auth Linking
  const signInWithProviderPt2 = async (
    setError: React.Dispatch<TsTempType>
  ) => {
    let emailPasswordSignIn;
    try {
      //Try to sing in with email + password followed by linking the credentials
      emailPasswordSignIn = await firebaseApp
        .auth()
        .signInWithEmailAndPassword(authLoginEmail, authLoginPassword);
      emailPasswordSignIn.user?.linkWithCredential(pendingCredential);
      setAuthIsLoading(false);
      setUser({
        uid: emailPasswordSignIn.user?.uid,
        email: emailPasswordSignIn.user?.email,
      });
      setIsOpen(false);
      //reset states
      setPendingCredential(emptyCredentials);
      setAuthLoginEmail("");
      setAuthLoginPassword("");
    } catch (error) {
      errorHandler(error, setError);
    }
  };

  // \\\\\\ CREATE ACCOUNT LOGIC //////

  const createNewUser = ({ email, password, setError }: EmailAndPassword) => {
    setAuthIsLoading(true);
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (r: TsTempType) => {
        setAuthIsLoading(false);
        if (r && r.user) {
          setUser({ uid: r.user.uid, email: r.user.email });
          setFirebaseUser(r.user);
          await createNewUserMutation({ variables: { uid: r.user.uid } });
        }
      })
      .catch(async error => {
        errorHandler(error, setError);
      })
      .then(() => {
        setIsOpen(false);
      });
  };

  // \\\\\\ LOGIN LOGIC //////

  const signInUser = ({ email, password, setError }: EmailAndPassword) => {
    setAuthIsLoading(true);
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        errorHandler(error, setError);
      })
      .then((r: TsTempType) => {
        if (r && r.user) {
          setAuthIsLoading(false);
          setUser({ uid: r.user.uid, email: r.user.email });
          setIsOpen(false);
        }
      });
  };

  // \\\\\\ FORGOT PASSWORD LOGIC //////

  const resetPassword = ({ email, setError, callback }: Email) => {
    setAuthIsLoading(true);
    firebaseApp
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setError("Password Reset Email has been sent");
        setAuthIsLoading(false);
        callback(true);
      })
      .catch(error => {
        errorHandler(error, setError);
      });
  };

  useEffect(() => {
    if (userCreationFailed) {
      firebaseUser.delete();
      setFirebaseUser({});
      logout();
    }
    // eslint-disable-next-line
  }, [userCreationFailed]);

  return (
    <Modal
      id="modal-container"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      style={{ overflowY: "auto" }}
    >
      <>
        <StyledLoginContainer maxWidth="xs">
          {isLoginType ? ( //if on Login
            <LoginModal
              setModalIsOpen={setIsOpen}
              setModalType={setModalType}
              authIsLoading={authIsLoading}
              signInUser={signInUser}
              currentUserIsLoading={currentUserIsLoading}
              modalType={modalType}
              signInWithProviderPt1={signInWithProviderPt1}
            />
          ) : isCreateAccountType ? ( //if on create account
            <CreateAccountModal
              setModalIsOpen={setIsOpen}
              authIsLoading={authIsLoading}
              createNewUser={createNewUser}
              setModalType={setModalType}
              modalType={modalType}
              signInWithProviderPt1={signInWithProviderPt1}
            />
          ) : (
            <ResetPasswordModal //else is reset password
              setModalIsOpen={setIsOpen}
              setModalType={setModalType}
              authIsLoading={authIsLoading}
              resetPassword={resetPassword}
              modalType={modalType}
            />
          )}
        </StyledLoginContainer>
        {isCreateAccountType ? (
          <StyledSmallLoginContainer maxWidth="xs">
            <SmallLoginModal setModalType={setModalType} />
          </StyledSmallLoginContainer>
        ) : null}
        <LinkedAuthModal
          email={authLoginEmail}
          isOpen={authLinkingModalIsOpen}
          setIsOpen={setAuthLinkingModalIsOpen}
          authIsLoading={authIsLoading}
          signInUser={signInUser}
          currentUserIsLoading={currentUserIsLoading}
          setAuthLoginPassword={setAuthLoginPassword}
          signInWithProviderPt2={signInWithProviderPt2}
        />
      </>
    </Modal>
  );
};
