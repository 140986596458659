import React from "react";
import {
  BackAndExitButtonWrapper,
  BackArrowLoginText,
  StyledBackArrowIcon,
} from "LoginComponents";
import { modalKinds } from "GlobalComponents";

interface Props {
  modalType: modalKinds;
  setModalType?: React.Dispatch<modalKinds>;
}

export const BackArrow: React.FC<Props> = ({ modalType, setModalType }) => {
  return (
    <BackAndExitButtonWrapper
      style={{ float: "left" }}
      onClick={() => {
        if (setModalType) setModalType(modalType);
      }}
    >
      <StyledBackArrowIcon />
      <BackArrowLoginText>Back</BackArrowLoginText>
    </BackAndExitButtonWrapper>
  );
};
