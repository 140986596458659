import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  MainGreen,
  SecondaryGreen,
  SecondaryColor,
  LighterPurple,
  DarkModeLighterPurple,
  TransparentGreenLight,
  FogGrey,
  FacebookBlue,
  SuperLightGrey,
  GoogleGrey,
  WarningRed,
} from "ColorVars";
import _Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import { DarkModePageBackground } from "ColorVars";
import Skeleton from "@material-ui/lab/Skeleton";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import { CircularProgress } from "@material-ui/core";

export const StyledContainer = styled(Container)`
  background-color: white;
  min-height: 315px;
  padding: 15px;
  margin-top: 20px;
  padding-top: 13px;
  text-align: center;
  && {
    &&.MuiContainer-root {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`;

export const SiteMapWrapper = styled.div<{ lockToBottom?: boolean }>`
  background-color: ${DarkModePageBackground};
  padding: 15px;
  padding-top: 13px;
  text-align: center;
  width: 98vw;
  position: ${({ lockToBottom }) => (lockToBottom ? "inherit" : "absolute")};
  bottom: 0px;
  && {
    &&.MuiContainer-root {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-right: auto;
  margin-top: 20%;
  margin-left: -10px;
`;

export const StyledTextField = styled(TextField)`
  && {
    display: block;
    width: 320px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    .MuiInput-root.MuiInputBase-root {
      width: inherit;
    }
  }
`;

export const AdaptiveTextField = styled(TextField)<{ darkMode: boolean }>`
  & {
    .MuiInputBase-input {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
    .MuiInputLabel-root {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
    .MuiOutlinedInput-notchedOutline {
      ${({ darkMode }) => darkMode && `border-color: white;`}
    }
  }
`;

export const Error = styled(Typography)`
  && {
    color: red;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 10px;
  }
`;

export const StyledButton = styled(_Button)`
  && {
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
  }
`;

export const ModalStyleSwitcher = styled(Typography)`
  && {
    width: fit-content;
    margin: auto;
    font-size: 1rem;
    color: ${SecondaryGreen};
    margin-top: 20px;
    cursor: pointer;
  }
`;

export const MarketingWrapper = styled.div`
  background-color: ${TransparentGreenLight};
  min-height: 288px;
`;

export const MainMarketingHeader = styled(Typography)`
  && {
    font-size: 1.5rem;
    color: black;
    letter-spacing: 2px;
    font-weight: 100;
  }
`;

export const StyledFab = styled(Fab)`
  right: -52%;
  top: -21px;
  && {
    width: 35px;
    height: 35px;
  }
`;

export const SecondaryMarketingText = styled(Typography)`
  && {
    font-size: 1.2rem;
    color: ${SecondaryColor};
    letter-spacing: 3px;
    margin-top: 35px;
  }
`;

export const MarketingText = styled(Typography)`
  && {
    font-size: 0.9rem;
    letter-spacing: 2px;
    margin-top: 35px;
    color: ${LighterPurple};
  }
`;

export const LoadingScreenWrapper = styled.div<{ darkMode: boolean }>`
  height: 100vh;
  width: 100%;
  background-color: ${({ darkMode }) =>
    darkMode ? DarkModePageBackground : "white"};
`;

export const StyledImg = styled.img<{ isHidden: boolean }>`
  ${({ isHidden }) => isHidden && "display: hidden;"}
`;

export const StyledPlaceholder = styled(Skeleton)<{
  darkMode: boolean;
  topSpacing?: boolean;
}>`
  && {
    text-align: center;
    font-size: 2rem;
    position: absolute;
    color: ${MainGreen};
    font-family: sans-serif;
    background-color: ${({ darkMode }) =>
      darkMode ? DarkModePageBackground : "white"};
    margin: auto;
    top: ${({ topSpacing }) => (topSpacing ? "-4%" : "-24%")};
    bottom: 0;
    right: 0;
    left: 0;
    height: fit-content;
    width: 330px;
    white-space: pre-line;
  }
`;

export const LyricCountWrapper = styled.div<{ darkMode: boolean }>`
  font-size: 1rem;
  text-align: left;
  letter-spacing: 2px;
  color: ${({ darkMode }) =>
    darkMode ? DarkModeLighterPurple : LighterPurple};
`;

export const StyledDialogTitle = styled(DialogTitle)`
  color: ${MainGreen};
`;

export const DialogLyricTitle = styled(Typography)`
  display: inline;
  font-weight: 500;
  font-size: inherit;
  color: ${SecondaryColor};
`;

export const DialogButton = styled(_Button)`
  && {
    display: inline;
    margin: 10px;
    margin-bottom: 20px; /* DO NOT REMOVE! IT IS NOT USELESS! VSCODE IS LYING!! */
    border-color: red;
    border-width: 1.5px;
  }
`;

export const AdaptiveButton = styled(_Button)<{ darkMode: boolean }>`
  && {
    text-transform: none;
    font-family: sans-serif;
    font-size: 15;
    font-weight: 100;
    display: inline;
    margin: 10px;
    margin-bottom: 20px; /* DO NOT REMOVE! IT IS NOT USELESS! VSCODE IS LYING!! */
    border-color: red;
    border-width: 1.5px;
    color: ${({ darkMode }) => (darkMode ? "white" : "black")};
  }
`;

export const ModalContentWrapper = styled.div<{
  darkMode: boolean;
  isMobile?: boolean;
}>`
  text-align: center;
  background-color: ${({ darkMode }) =>
    darkMode ? DarkModePageBackground : "white"};
  min-width: 65%;
  position: absolute;
  margin: auto;
  border-radius: 6px;
  top: 8%;
  left: 20%;
  height: 88vh;
  ${({ isMobile }) =>
    isMobile &&
    `
  left: 0%;
  top: 6%;
  `}
  overflow: scroll;
`;

export const DefaultPageWrapper = styled.div<{
  darkMode: boolean;
  topPaddingFalse?: boolean;
  bottomPaddingFalse?: boolean;
}>`
  text-align: center;
  position: relative;
  padding-bottom: 20vh;
  background-color: ${({ darkMode }) => (darkMode ? `#1a1a1a` : `white`)};
  padding-top: 30px;
  ${({ topPaddingFalse }) => topPaddingFalse && "padding-top: 0px;"}
  ${({ bottomPaddingFalse }) => bottomPaddingFalse && "padding-bottom: 0px;"}
  min-height: 88vh;
`;

interface StyledSelectProps {
  darkMode: boolean;
  isMobile: boolean;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  width: 5.5rem;
  display: inline-block;
  left: 2px;
  ${({ isMobile }) =>
    isMobile &&
    `margin-top: 16px;
     left: 0px
  `}
  color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
  & {
    .MuiSelect-icon {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
    .MuiOutlinedInput-notchedOutline {
      ${({ darkMode }) => darkMode && `border-color: white;`}
    }
  }
  && {
    .MuiSelect-outlined {
      text-align: left;
    }
  }
`;

export const StyledSearchField = styled(TextField)<{ darkMode: boolean }>`
  & {
    width: 99%;
    .MuiInputBase-input {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
    .MuiInputLabel-root {
      color: ${({ darkMode }) => (darkMode ? `white` : `black`)};
    }
    .MuiOutlinedInput-notchedOutline {
      ${({ darkMode }) => darkMode && `border-color: white;`}
    }
    .MuiIconButton-label {
      ${({ darkMode }) => darkMode && `color: white;`}
    }
  }
`;

export const NoLyricsToDisplayText = styled.div<{ darkMode: boolean }>`
  margin-top: 5vh;
  font-size: 3vh;
  display: block;
  letter-spacing: 0.2vw;
  color: ${({ darkMode }) => (darkMode ? "white" : "black")};
`;

export const SearchAreaWrapper = styled(Container)`
  margin: auto;
  padding-bottom: 70px;
`;

export const StyledMenuIconButton = styled(IconButton)`
  && {
    margin-left: -6px;
    &.MuiIconButton-edgeStart {
      margin-left: -2px;
      margin-right: -5px;
    }
  }
`;

export const AdaptiveLinkButton = styled(StyledMenuIconButton)<{
  darkMode: boolean;
}>`
  && {
    color: ${({ darkMode }) => (darkMode ? "white" : "black")};
    margin-bottom: 5px;
  }
`;

export const StyledAnchorTag = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const Button = styled(_Button)<{
  kind:
    | "primary"
    | "secondary"
    | "hollow"
    | "light"
    | "facebook"
    | "google"
    | "warning";
  size?: "large" | "small";
}>`
  && {
    font-weight: 400;
    border-radius: 15px;
    color: white;
    padding: 10px;
    text-transform: none;
    margin-right: 10px;
    margin-left: 10px;
    letter-spacing: 2px;
    height: 38px;
    min-width: 134px;
    font-size: 18px;
    ${({ kind }) => {
      if (kind === "primary") return `background-color: ${MainGreen}`;
      if (kind === "secondary") return `background-color: ${FogGrey}`;
      if (kind === "facebook") return `background-color: ${FacebookBlue}`;
      if (kind === "google")
        return `background-color: white; color: ${GoogleGrey};`;
      if (kind === "hollow")
        return `
          border: solid ${MainGreen} 2px;
          color: ${MainGreen};
      `;
      if (kind === "light")
        return `
        background-color: ${SuperLightGrey};
        color: black;
      `;
      if (kind === "warning")
        return `
          background-color: ${WarningRed};
        `;
    }};

    ${({ size }) => {
      if (size === "large") {
        return `
            height: 60px;
            width: 200px;
            font-size: 30px;
        `;
      }
      if (size === "small")
        return `
        font-weight: 10px;
      `;
    }}
  }
`;
